import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { request } from "../../../api";
import { usersActions } from "../../../store/users.slice";
import { dateFormation, getLocalstringNumber, getMoneyModalType, getNewUserAcceptlUrl, getStatus, getStatusColorClassName, isEmpty, toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";
import MoneyModal from "../../common/modal/MoneyModal";
import DeleteModal from "../../common/modal/DeleteModal";
import EditModal from "../../common/modal/EditModal";
import Pagenation from "../../common/pagenation/pagenation";
import Search from "../../common/search/Search";
import TableTitle from "../../common/TableTitle";
import MessageModal from "../../common/modal/MessageModal";
import ChangePasswordModal from "../../common/modal/ChangePasswordModal";
import { agentActions, userActions } from "../../../store";
import PointModal from "../../common/modal/PointModal";
import DateTd from "../../common/table/DateTd";
import Thead from "../../common/table/Thead";
import TableEmpty from "../../common/table/TableEmpty";
import BankInfo from "../../common/table/BankInfo";
import MoneyAccept from "../../common/modal/MoneyAccept";

const theads = [
  {
    name: "번호",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap', width: "55px" }
  },
  {
    name: "아이디",
    value: "loginid",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "상위업체 ",
    value: "parent_name",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "닉네임",
    value: "username",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "추천코드",
    value: "partner_code",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "은행명",
    value: "bankname",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "계좌번호",
    value: "accountnumber",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "예금주",
    value: "mastername",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "가입일자",
    value: "created_at",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "상태",
    value: "is_new",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "상태관리",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap', minWidth: "120px" }
  },
]

function NewUserListContent() {
  const dispatch = useDispatch();
  const newUserList = useSelector(state => state.users.newUserList);

  // -----------------------------------------------
  const [sortParam, setSortParam] = useState({});
  const [searchParam, setSearchParam] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  });
  // -----------------------------------------------

  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });

  const [active, setActive] = useState(-1);

  const [showModal, setShowModal] = useState({
    accept: false,
    rejection: false,
    waiting: false
  });

  // -------------------- 시간 선택 -------------------- //
  const [dateInput, setDateInput] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  })

  // ------------------- 시간 선택 끝 ------------------ //

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const getList = (page = 1, perPage = 10, search = searchParam, sortParams = sortParam) => {
    setPageInfo({
      ...pageInfo,
      curPage: page,
      perPage: Number(perPage)
    });

    const params = {
      page: page,
      perPage: perPage,
      ...sortParams,
      ...search,
    }
    setSearchParam(search);
    setSortParam(sortParams);

    dispatch(usersActions.getNewUserList(params));
    setActive(-1);
  }

  useEffect(() => {
    getList();
  }, []);


  // ------------------- Sort Start ----------------------- //
  const defaultSort = {
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    is_new: 0,
    rtp: 0,
    money: 0,
    rolling: 0,
    created_at: 0,
  }
  const [sort, setSort] = useState({
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    is_new: 0,
    rtp: 0,
    money: 0,
    rolling: 0,
    created_at: 0,
  });

  const onSort = (value) => {
    if (!isEmpty(value)) {
      const sortParams = {
        sortBy: value,
        sortOrder: (sort[value] === 0) ? "asc"
          :
          ((sort[value] === 1) ? "desc" : "asc")
      }
      getList(1, pageInfo.perPage, searchParam, sortParams);
      setSort({
        ...defaultSort,
        [value]: (sort[value] === 0) ? 1
          :
          ((sort[value] === 1) ? -1 : 1)
      });
    }
  }
  // ------------------- Sort End ----------------------- //

  return (
    <Fragment>
      <Search
        noDate={true}
        noTodayPicker={true}
        pageInfo={pageInfo}
        dateInput={dateInput}
        setDateInput={setDateInput}
        getSearch={getList} />

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* <TableTitle title="회원목록" /> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                <div className="row justify-content-end padding-bottom-15">
                  <div className="">

                  </div>
                </div>
                <div className="row">
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                    <table className="agent-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                      role="grid" aria-describedby="DataTables_Table_0_info">
                      <Thead theads={theads} sort={sort} onSort={onSort} />
                      <tbody>
                        {
                          newUserList.map((user, index) => {
                            return (
                              <tr role="row" className="odd" key={index} onClick={() => setActive(index)}>

                                <td className="sorting_1" style={{ textAlign: "center" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                <td style={{ textAlign: "center" }}>{user.loginid}</td>
                                <td style={{ textAlign: "center" }}>{user.parent_name}</td>
                                <td style={{ textAlign: "center" }}>{user.username}</td>
                                <td style={{ textAlign: "center" }}>{user.partner_code}</td>
                                <BankInfo data={user} />
                                <DateTd date={user.created_at} />
                                <td style={{ textAlign: "center" }}>
                                  <span className={`${Number(user.is_new) === 1 ? 'info-color' : "success-color"} font-weight-bold`}>
                                    {Number(user.is_new) === 1 ? '신규' : "대기"}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span>
                                    <button
                                      className="btn btn-info btn-sm mr-1"
                                      style={{ color: "#fff" }}
                                      title="승인"
                                      onClick={() => onShowModal("accept")}>
                                      <i className="fa   fa-check-square-o"></i>
                                    </button>
                                    {
                                      (user.is_new === 1) && <button
                                        className="btn btn-success btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        title="대기"
                                        onClick={() => onShowModal("waiting")}>
                                        <i className="fa fa-bell-slash-o"></i>
                                      </button>
                                    }
                                    <button
                                      className="btn btn-danger btn-sm mr-1"
                                      style={{ color: "#fff" }}
                                      title="거절"
                                      onClick={() => onShowModal("rejection")}>
                                      <i className="fa fa-ban"></i>
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(newUserList) ? ""
                        :
                        <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>
                <Pagenation
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo}
                  getList={getList} />


                {
                  (showModal.accept || showModal.rejection || showModal.waiting) &&
                  <MoneyAccept
                    pageInfo={pageInfo}
                    getList={getList}
                    data={newUserList[active]}
                    onClose={onShowModal}
                    type={getMoneyModalType(showModal)}
                    url={getNewUserAcceptlUrl(showModal)}
                  />
                }

              </div>
            </div>
          </div>
        </div>
      </div>


    </Fragment>
  );
}

export default NewUserListContent;
