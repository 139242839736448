import React, { useState } from "react";
import { Fragment } from "react";
import { dateFormation, getAgentLevel, getAgentLevelClass, getAgentLevelClassName, getAgentLevelEng, getLocalstringNumber, getStatus, getStatusColorClassName, getStatusTextColorClassName, isEmpty } from "../../utils";

const UserCalculateNode = (props) => {
  const { data, getChildren, activeAgent, setActiveAgent, level } = props;
  const [userData, UserData] = useState(data);
  const [active, setActive] = useState(false);

  const getMyChildren = (parent_loginid) => {

    getChildren(parent_loginid, false).then(subChildren => {
      if (!isEmpty(subChildren)) {
        UserData({
          ...userData,
          children: subChildren
        });
      }
    });
  }
  const onClickAgent = () => {
    setActive(!active);
    if (Number(data.agent_type > -1)) {
      setActiveAgent(data);
    }
    if ((Number(data.user_count) > 0) && (!active)) {
      getMyChildren(data.loginid);
    }
  }

  const setClassName = (value) => {
    switch (value) {
      case 3:
        return "btn-info"
        break;
      case 2:
        return "btn-secondary"
        break;
      case 1:
        return "btn-success"
        break;
      case -1:
        return "btn-warning"
        break;
    }
  }

  return (
    <Fragment>
      <tr role="row" className="odd" onClick={onClickAgent}>
        <td style={{ textAlign: "start" }}>
          <span className="el-table__indent" style={{ paddingLeft: 16 * (level - 1) }}></span>
          {(Number(data.user_count) > 0) ?
            <i className={` fa ${active ? "fa-minus-square" : "fa-plus-square"} margin-right-5`}></i>
            :
            <i className=" " style={{ width: "50px" }}>&emsp;</i>
          }
          <button type="button" className={`btn mb-1 btn-sm ${getStatusColorClassName(data.agent_type)}`} style={{ marginRight: "15px" }}>
            <i className="fa fa-user-o"></i>
          </button>
          {data.loginid}
        </td>
        <td style={{ textAlign: "center" }}>{data.username}</td>
        <td style={{ textAlign: "center" }}>
          <span className={getStatusTextColorClassName(data.agent_type)}>{getAgentLevel(data.agent_type)}</span>
        </td>
        <td style={{ textAlign: "end" }} className="color-twitter">
          {getLocalstringNumber(data.charge_money)}
        </td>
        <td style={{ textAlign: "end" }} className="color-youtube">
          {getLocalstringNumber(data.withdraw_money)}
        </td>
        <td style={{ textAlign: "end" }} className="">
          {getLocalstringNumber(data.money)}
        </td>
        <td style={{ textAlign: "end" }} className="">
          {getLocalstringNumber(data.rolling)}
        </td>
        <td style={{ textAlign: "end" }} className="color-youtube">
          {getLocalstringNumber(data.luckywhale_betting_money)}
        </td>
        <td style={{ textAlign: "end" }} className="color-twitter">
          {getLocalstringNumber(data.luckywhale_win_money)}
        </td>
        <td style={{ textAlign: "end" }} className="">
          {getLocalstringNumber(data.luckywhale_parent_money)}
        </td>
      </tr>
      {
        (!isEmpty(userData.children) && active) && userData.children.map((userItem, index) => {
          return (<UserCalculateNode
            data={userItem}
            level={level + 1}
            activeAgent={activeAgent}
            setActiveAgent={setActiveAgent}
            getChildren={getChildren}
            key={userItem.created_at + index} />)
        })
      }
    </Fragment>
  );
};

export default UserCalculateNode;
