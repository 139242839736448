function TableTitle(props) {
    const {title} = props;

    return (
        <div
            style={{
            marginLeft: "30px",
            marginTop: "25px",
            fontSize: "16px"
        }}
            className="card-title">
            {title}
            </div>
    );
}

export default TableTitle;
