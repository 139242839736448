import MyPageInfo from "./MyPageInfo";
import MyPagePwd from "./MyPagePwd";

function MyPage() {

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <div className="table-responsive overflow-unset">
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
              <div className="row justify-content-center padding-top-15 padding-bottom-15">
                <div className="">
                  <h4 className="card-title">마이페이지</h4>
                </div>
              </div>
              <div className="row">

                <MyPageInfo/>
                
                <MyPagePwd/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPage;
