import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { request } from "../../../api";
import { usersActions } from "../../../store/users.slice";
import { dateFormation, getLocalstringNumber, isEmpty, toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";
import MoneyModal from "../../common/modal/MoneyModal";
import DeleteModal from "../../common/modal/DeleteModal";
import EditModal from "../../common/modal/EditModal";
import Pagenation from "../../common/pagenation/pagenation";
import Search from "../../common/search/Search";
import MessageModal from "../../common/modal/MessageModal";
import ChangePasswordModal from "../../common/modal/ChangePasswordModal";
import { agentActions, userActions } from "../../../store";
import PointModal from "../../common/modal/PointModal";
import DateTd from "../../common/table/DateTd";
import Thead from "../../common/table/Thead";
import TableEmpty from "../../common/table/TableEmpty";
import BankInfo from "../../common/table/BankInfo";
import Confirm from "../../common/modal/Confirm";

const theads = [
  {
    name: "번호",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap', width: "55px" }
  },
  {
    name: "아이디",
    value: "loginid",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "상위업체 ",
    value: "parent_name",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "닉네임",
    value: "username",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  // {
  //   name: "추천코드",
  //   value: "partner_code",
  //   sort: true,
  //   style: { textAlign: "center", whiteSpace: 'nowrap' }
  // },
  // {
  //   name: "롤링퍼센트",
  //   value: "rolling_percent",
  //   sort: true,
  //   style: { textAlign: "center", whiteSpace: 'nowrap', width: "90px" }
  // },
  {
    name: "은행명",
    value: "bankname",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "계좌번호",
    value: "accountnumber",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "예금주",
    value: "mastername",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "보유금액",
    value: "money",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "보유포인트",
    value: "rolling",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "환수율",
    value: "rtp",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "상태관리",
    value: "status",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "가입일자",
    value: "created_at",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "머니지급",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "포인트지급",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  // {
  //   name: "포인트전환",
  //   value: "",
  //   sort: false,
  //   style: { textAlign: "center", whiteSpace: 'nowrap' }
  // },
  {
    name: "관리",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
]

function UserListContent() {
  const dispatch = useDispatch();
  const usersList = useSelector(state => state.users.usersList);
  const userData = useSelector(state => state.user.data);

  // -----------------------------------------------
  const [sortParam, setSortParam] = useState({});
  const [searchParam, setSearchParam] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  });
  // -----------------------------------------------

  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });

  const [active, setActive] = useState(-1);

  const [moneySetInfo, setmoneySetInfo] = useState({
    type: "",
  });
  const [pointSetInfo, setPointSetInfo] = useState({
    type: "",
  });

  const [showModal, setShowModal] = useState({
    moneySet: false,
    pointSet: false,
    create: false,
    edit: false,
    changePwd: false,
    delete: false,
    message: false,
    confirm: false
  });

  // -------------------- 시간 선택 -------------------- //
  const [dateInput, setDateInput] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  })

  // ------------------- 시간 선택 끝 ------------------ //

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const getList = (page = 1, perPage = 10, search = searchParam, sortParams = sortParam) => {
    setPageInfo({
      ...pageInfo,
      curPage: page,
      perPage: Number(perPage)
    });

    const params = {
      page: page,
      perPage: perPage,
      ...sortParams,
      ...search,
    }
    setSearchParam(search);
    setSortParam(sortParams);

    dispatch(usersActions.getUsers(params));
    setActive(-1);
  }

  useEffect(() => {
    getList();
    dispatch(userActions.getBankList());
    dispatch(agentActions.getAllParentAgentList());
  }, []);

  const onMoneySet = (type) => {
    setmoneySetInfo({
      type: type
    });
    onShowModal("moneySet");
  }

  const onPointSet = (type) => {
    setPointSetInfo({
      type: type
    });
    onShowModal("pointSet");
  }

  const onChangeStatus = (status, loginin) => {
    return (
      request.post(api_url + "/api/user/change_status", {
        loginid: loginin,
        status: Number(status) === 0 ? 1 : 0
      })
        .then(res => {
          toastr.success(res.message);
          getList(pageInfo.curPage, pageInfo.perPage);
          setShowModal("confirm");
        })
    )
  }


  // ------------------- Sort Start ----------------------- //
  const defaultSort = {
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    rolling: 0,
    rtp: 0,
    created_at: 0,
  }
  const [sort, setSort] = useState({
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    rolling: 0,
    rtp: 0,
    created_at: 0,
  });

  const onSort = (value) => {
    if (!isEmpty(value)) {
      const sortParams = {
        sortBy: value,
        sortOrder: (sort[value] === 0) ? "asc"
          :
          ((sort[value] === 1) ? "desc" : "asc")
      }
      getList(1, pageInfo.perPage, searchParam, sortParams);
      setSort({
        ...defaultSort,
        [value]: (sort[value] === 0) ? 1
          :
          ((sort[value] === 1) ? -1 : 1)
      });
    }
  }
  // ------------------- Sort End ----------------------- //

  const [confirmContent, setConfirmContent] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("")
  const onConfirm = (value) => {
    setConfirmStatus(value);
    onShowModal("confirm");
    // console.log('value: ', value);
    switch (value) {
      case "status": {
        setConfirmContent("상태를 변경하시겠습니까?");
        break;
      }

      default:
        break;
    }
  }

  const onOk = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    onChangeStatus(usersList[active].status, usersList[active].loginid);
  }

  const onCancel = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    setShowModal("confirm");
  }

  return (
    <Fragment>
      <Search
        noDate={true}
        noTodayPicker={true}
        pageInfo={pageInfo}
        dateInput={dateInput}
        setDateInput={setDateInput}
        getSearch={getList} />

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* <TableTitle title="회원목록" /> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                <div className="row justify-content-end padding-bottom-15">
                  <div className="mr-3 new-button">
                    <button type="button"
                      className="btn btn-primary"
                      onClick={() => onShowModal("create")}>
                      <i className="icon-user-follow f-s-14 mr-1" aria-hidden="true"></i>
                      신규등록
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                    <table className="agent-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                      role="grid" aria-describedby="DataTables_Table_0_info">
                      <Thead theads={theads} sort={sort} onSort={onSort} />
                      <tbody>
                        {
                          usersList.map((user, index) => {
                            return (
                              <tr role="row" className="odd" key={index} onClick={() => setActive(index)}>
                                <td className="sorting_1" style={{ textAlign: "center" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                <td style={{ textAlign: "center" }}>{user.loginid}</td>
                                <td style={{ textAlign: "center" }}>{user.parent_name}</td>
                                <td style={{ textAlign: "center" }}>{user.username}</td>
                                {/* <td style={{ textAlign: "center" }}>{user.partner_code}</td> */}
                                {/* <td style={{ textAlign: "end" }}>{user.rolling_percent}%</td> */}
                                <BankInfo data={user} />
                                <td style={{ textAlign: "end" }}>{getLocalstringNumber(user.money)}</td>
                                <td style={{ textAlign: "end" }}>{getLocalstringNumber(user.rolling)}</td>
                                <td style={{ textAlign: "end" }}>{user.rtp}%</td>
                                <td style={{ textAlign: "center", padding: "0px" }}>
                                  <label
                                    className={`${(Number(user.status) === 1) ? "success-switch" : "danger-switch false"} switch-label`}
                                    data-on-label=""
                                    data-off-label=""
                                    // onClick={() => onChangeStatus(agent.status, agent.loginid)} 
                                    onClick={() => onConfirm("status")} >
                                  </label>
                                  {/* <span
                                    className={getStatusColorClassName(user.status) + " enable-hover cursor-pointer"}
                                    onClick={() => onChangeStatus(user.status, user.loginid)}>
                                    {getStatus(user.status)}
                                  </span> */}
                                </td>
                                <DateTd date={user.created_at} />
                                <td>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <button className="btn btn-success btn-sm mr-1"
                                      style={{ color: "#fff" }}
                                      title="강제충전"
                                      onClick={() => onMoneySet("충전")}>
                                      <i className="fa fa-arrow-circle-up"></i>
                                    </button>
                                    <button className="btn btn-primary btn-sm mr-1"
                                      style={{ color: "#fff" }}
                                      title="강제환전"
                                      onClick={() => onMoneySet("환전")}>
                                      <i className="fa fa-arrow-circle-down"></i>
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <button className="btn btn-success btn-sm mr-1"
                                      style={{ color: "#fff" }}
                                      title="포인트지급"
                                      onClick={() => onPointSet("지급")}>
                                      <i className="fa fa-arrow-circle-up"></i>
                                    </button>
                                    <button className="btn btn-primary btn-sm mr-1"
                                      style={{ color: "#fff" }}
                                      title="포인트차감"
                                      onClick={() => onPointSet("차감")}>
                                      <i className="fa fa-arrow-circle-down"></i>
                                    </button>
                                  </div>
                                </td>
                                {/* <td>
                                  <div className="d-flex justify-content-center align-items-center">
                                      <button className="btn btn-success btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        title="포인트전환"
                                        onClick={() => onPointSet("지급")}>
                                        <i className="fa fa-arrow-circle-up"></i>
                                      </button>
                                  </div>
                                </td> */}
                                <td style={{ textAlign: "center" }}>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <button
                                      className="btn btn-primary btn-sm mr-1"
                                      style={{ color: "#fff" }}
                                      title="쪽지보내기"
                                      onClick={() => onShowModal("message")}>
                                      <i className=" fa fa-send"></i>
                                    </button>
                                    <button className="btn btn-info btn-sm mr-1"
                                      style={{ color: "#fff" }}
                                      onClick={() => onShowModal("edit")}
                                      title="수정">
                                      <i className="fa fa-edit"></i>
                                    </button>
                                    <button className="btn btn-warning btn-sm mr-1"
                                      style={{ color: "#fff" }}
                                      onClick={() => onShowModal("changePwd")}
                                      title="비밀번호변경">
                                      <i className=" fa fa-key"></i>
                                    </button>
                                    {
                                      userData.agent_type === 3 &&
                                      <button
                                        className="btn btn-danger btn-sm"
                                        style={{ color: "#fff" }} title="삭제"
                                        onClick={() => onShowModal("delete")}>
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    }

                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(usersList) ? ""
                        :
                        <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>
                <Pagenation
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo}
                  getList={getList} />

                {
                  showModal.moneySet &&
                  <MoneyModal
                    pageInfo={pageInfo}
                    moneySetInfo={moneySetInfo}
                    getList={getList}
                    data={usersList[active]}
                    url={moneySetInfo.type === "충전" ? "/api/user/user_charge" : "/api/user/user_withdraw"}
                    onClose={onShowModal} />
                }

                {
                  (showModal.edit || showModal.create) &&
                  <EditModal
                    pageInfo={pageInfo}
                    edit={showModal.edit}
                    getList={getList}
                    data={usersList[active]}
                    url="/api/user/create"
                    checkurl="/api/user/check_loginid"
                    onClose={onShowModal}
                    noPartnerCode={true}
                    noAgentType={true}
                    noRollingPercent={true}
                    noStatus={true}
                    noRtp={showModal.edit ? false : true}
                    noPassword={showModal.edit ? true : false}
                    name="회원" />
                }


                {showModal.pointSet &&
                  <PointModal
                    pageInfo={pageInfo}
                    pointSetInfo={pointSetInfo}
                    getList={getList}
                    type={pointSetInfo.type}
                    data={usersList[active]}
                    url={pointSetInfo.type === "지급" ? "/api/user/pay_rolling" : "/api/user/pay_rolling"}
                    onClose={onShowModal} />
                }

                {
                  showModal.delete &&
                  <DeleteModal
                    pageInfo={pageInfo}
                    getList={getList}
                    data={usersList[active]}
                    onClose={onShowModal}
                    method="post"
                    url="/api/user/delete" />
                }

                {
                  showModal.message &&
                  <MessageModal
                    pageInfo={pageInfo}
                    getList={getList}
                    data={usersList[active]}
                    onClose={onShowModal}
                    method="post"
                    url="/api/site/send_usermessage"
                    type="보내기"
                    sendId="id" />
                }

                {
                  showModal.changePwd &&
                  <ChangePasswordModal
                    pageInfo={pageInfo}
                    getList={getList}
                    data={usersList[active]}
                    onClose={onShowModal}
                    method="post"
                    url="/api/user/update_password" />
                }

                {
                  showModal.confirm &&
                  <Confirm
                    content={confirmContent}
                    onOk={onOk}
                    onClose={onCancel} />
                }

              </div>
            </div>
          </div>
        </div>
      </div>


    </Fragment>
  );
}

export default UserListContent;
