import { useState } from "react";
import { request } from "../../../api";
import { isEmpty, toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";
import Confirm from "../../common/modal/Confirm";
import Modalback from "../../common/modal/Modalback";


function BankEditModal(props) {
  const { getList, data, url, onClose, method, name } = props;

  const [image, setImage] = useState(null)
  const [inputs, setInputs] = useState({
    bankName: '',
    status: 0
  });

  const { bankName, status } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target
    setInputs({
      ...inputs,
      [name]: value
    })
  }

  const onSubmit = (e) => {
    onShowModal("confirm");
    if (isEmpty(bankName)) {
      return toastr.warning("은행명을 입력해주세요.");
    }

    let formData = new FormData();
    formData.append("image", image);
    const params = {
      bank_name: bankName,
      status: Number(status)
    }

    for (let param in params) {
      formData.append(param, params[param]);
    }

    request[method](api_url + url, formData)
      .then(res => {
        toastr.success(res.message);
        getList();
        onCloseModal();
      })
  }

  const onCloseModal = () => {
    onClose();
  }

  // ------------------------------------------------- //

  const [showModal, setShowModal] = useState({
    confirm: false
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const [confirmContent, setConfirmContent] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("");

  const onConfirm = (value) => {
    setConfirmStatus(value);
    onShowModal("confirm");
    setConfirmContent(`은행를 ${"생성"}하시겠습니까?`);
  }

  const onOk = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    onSubmit();
  }

  const onCancel = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    setShowModal("confirm");
  }

  return (
    <div className="modal fade passwordChangeModal show" tabIndex="-1" role="dialog" style={{ display: "block" }} aria-hidden="true">
      <Modalback onClose={onCloseModal} />
      <div className="modal-dialog modal-lg" style={{ width: "350px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{name}</h5>
            <button type="button" className="close" data-dismiss="modal" onClick={onCloseModal}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row d-block">
              <div className="col">
                <label htmlFor="bankName" className="col-form-label">은행명:</label>
                <input
                  type="text"
                  className="form-control"
                  id="bankName"
                  name="bankName"
                  value={bankName}
                  onChange={onChange} />
              </div>
              <div className="col">
                <label htmlFor="status" className="col-form-label">상태:</label>
                <select className="form-control" required id="status" name="status" onChange={onChange} value={status}>
                  <option className="danger-color" value="0">불허</option>
                  <option className="success-color" value="1">허용</option>
                </select>
              </div>
              <div className="col">
                <label className="col-form-label">로고:</label>
                {/* <input
                  className="form-control"
                  style={{ minHeight: "35px", height: "36px" }}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  // style={{ position: "absolute", left: "0", right: "0", zIndex: -1, }}
                  id="image"
                  name="image"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }} /> */}
                <div className="position-relative d-flex justify-content-center">
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    style={{ position: "absolute", left: "0", right: "0", zIndex: -1, }}
                    id="image"
                    name="image"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                    }} />
                  <label htmlFor='image'
                    className="cursor-pointer" 
                    style={{ width: "50%", aspectRatio: 1, 
                      position: "relative", borderRadius: "5px", 
                      backgroundColor: "#dedfe0", 
                      backgroundImage: image && `url(${URL.createObjectURL(image)})`, 
                      backgroundSize: "contain", backgroundPosition: "center", 
                      backgroundRepeat: "no-repeat"
                    }}
                  >
                    <i className="fa fa-pencil" style={{ position: "absolute", right: "-7px", top: "-7px", borderRadius: "50%", backgroundColor: "#FFF", color: '#393939', padding: "5px", zIndex: 1, }}></i>
                  </label>
                </div>
                {/* <input
                  type="password"
                  className="form-control"
                  id="passwordConfirm"
                  name="passwordConfirm"/> */}
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" onClick={onCloseModal}>취소</button>
            <button type="button" className="btn btn-primary" onClick={onConfirm}>생성</button>
          </div>
        </div>
      </div>

      {
        showModal.confirm &&
        <Confirm
          content={confirmContent}
          onOk={onOk}
          onClose={onCancel} />
      }
    </div>
  );
}

export default BankEditModal;
