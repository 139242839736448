import { request } from "../../../api";
import { toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";
import Modalback from "./Modalback";

const getName = (status) => {
  switch (status) {
    case "accept":
      return "승인";

    case "waiting":
      return "대기처리";

    case "rejection":
      return "거절";
    default:
      break;
  }
}

const getType = (status) => {
  switch (status) {
    case "accept":
      return 1;

    case "waiting":
      return "대기처리를 ";

    case "rejection":
      return "거절";
    default:
      break;
  }
}

function MoneyAccept(props) {
  const { type, onClose, getList, data, method, url, pageInfo } = props;


  const onModalClose = () => {
    onClose(type);
  }

  const onSubmit = () => {
    const params = {
      charge_id: data.id,
      id: data.id,
      withdraw_id: data.id,
      status: type ? 1 : 2
    }
    request.post(api_url + url, params)
      .then(res => {
        toastr.success(res.message);
        getList(pageInfo.curPage, pageInfo.perPage);
        onModalClose();
      })
  }

  return (
    <div className="modal fade bd-example-modal-sm agentDeleteModal show" id="EnableWithdrawModal" tabIndex="-1"  style={{ display: "block" }} aria-hidden="true">
      <Modalback onClose={onModalClose} />
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" onClick={onModalClose}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body d-flex justify-content-center align-items-center">
            <div>{getName(type)}하시겠습니까 ?</div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={onModalClose}>취소</button>
            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={onSubmit}>네</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoneyAccept;
