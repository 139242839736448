import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { siteActions } from "../../store";
import { dateFormation, isEmpty } from "../../utils";
import { resource_url } from "../../utils/keys";
import DeleteModal from "../common/modal/DeleteModal";
import EventModal from "../common/modal/EventModal";
import Pagenation from "../common/pagenation/pagenation";
import Search from "../common/search/Search";
import DateTd from "../common/table/DateTd";
import TableEmpty from "../common/table/TableEmpty";
// import TableTitle from "../common/TableTitle";

const theads = [
  {
    name: "번호",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap", width: "55px" }
  },
  {
    name: "제목",
    value: "title",
    sort: true,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "썸네일",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "내용",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "등록일자",
    value: "created_at",
    sort: true,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  // {
  //   name: "상태",
  //   value: "is_read",
  //   sort: true,
  //   style: { textAlign: "center", whiteSpace: "nowrap" }
  // },
  {
    name: "관리",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap", minWidth: "90px" }
  },
]

const searchOption = [
  {
    name: "제목",
    value: "title"
  }
]

function EventContent() {
  const dispatch = useDispatch();
  const eventList = useSelector(state => state.site.eventList);

  // -----------------------------------------------
  const [sortParam, setSortParam] = useState({});
  const [searchParam, setSearchParam] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  });
  // -----------------------------------------------

  const [active, setActive] = useState(-1);
  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });
  // -------------------- 시간 선택 -------------------- //
  const [dateInput, setDateInput] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  })

  // ------------------- 시간 선택 끝 ------------------ //

  const [showModal, setShowModal] = useState({
    create: false,
    delete: false,
    edit: false
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const getList = (page = 1, perPage = 10, search = searchParam, sortParams = sortParam) => {
    setPageInfo({
      ...pageInfo,
      curPage: page,
      perPage: Number(perPage)
    });

    const params = {
      page: page,
      perPage: perPage,
      ...search,
      ...sortParams
    }
    setSearchParam(search);
    setSortParam(sortParams);

    dispatch(siteActions.getEventList(params));
    setActive(-1);
  }

  useEffect(() => {
    getList();
  }, []);

  // ------------------- Sort Start ----------------------- //
  const defaultSort = {
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    prev_money: 0,
    amount: 0,
    rolling: 0,
    created_at: 0,
    updated_at: 0,
    memo: 0,
    description: 0,
    service_loginid: 0,
    game_code: 0,
    type: 0,
    round_id: 0,
    game_type: 0,
    provider_code: 0,
    title: 0,
    content: 0,
    is_read: 0,
    answer: 0,
    answered_at: 0,
  }
  const [sort, setSort] = useState({
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    amount: 0,
    prev_money: 0,
    rolling: 0,
    created_at: 0,
    updated_at: 0,
    memo: 0,
    description: 0,
    service_loginid: 0,
    game_code: 0,
    type: 0,
    round_id: 0,
    game_type: 0,
    provider_code: 0,
    title: 0,
    content: 0,
    is_read: 0,
    answer: 0,
    answered_at: 0,
  });

  const onSort = (value) => {
    if (!isEmpty(value)) {
      const sortParams = {
        sortBy: value,
        sortOrder: (sort[value] === 0) ? "asc"
          :
          ((sort[value] === 1) ? "desc" : "asc")
      }
      getList(1, pageInfo.perPage, searchParam, sortParams);
      setSort({
        ...defaultSort,
        [value]: (sort[value] === 0) ? 1
          :
          ((sort[value] === 1) ? -1 : 1)
      });
    }
  }
  // ------------------- Sort End ----------------------- //

  return (
    <Fragment>
      <Search
        pageInfo={pageInfo}
        dateInput={dateInput}
        setDateInput={setDateInput}
        getSearch={getList} 
        searchOption={searchOption} />

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* <TableTitle title="쪽지" /> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                <div className="row justify-content-end padding-bottom-15">
                  <div className="">
                    <button type="button"
                      className="btn btn-primary mr-3 new-button"
                      onClick={() => onShowModal("create")}>
                      <i className="icon-note f-s-14 mr-1" aria-hidden="true"></i>
                      새로 등록
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                    <table className="user-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                      role="grid" aria-describedby="DataTables_Table_0_info">
                      <thead>
                        <tr role="row">
                          {
                            theads.map((head, index) => {
                              return (
                                <th
                                  style={head.style}
                                  className={`my-th-w ${head.sort ?
                                    (sort[head.value] === 0 ?
                                      "sorting" :
                                      (sort[head.value] === 1 ? "sorting_asc" : "sorting_desc")
                                    )
                                    :
                                    ""}`}
                                  tabIndex="0"
                                  rowSpan="1"
                                  colSpan="1"
                                  key={index}
                                  onClick={() => onSort(head.value)}
                                >{head.name}</th>
                              )
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          eventList.map((item, index) => {
                            return (
                              <tr role="row" className="odd" key={index} onClick={() => setActive(index)}>
                                <td className="sorting_1" style={{ textAlign: "center" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                <td style={{ textAlign: "start" }}>{item.title}</td>
                                <td style={{ textAlign: "start" }}>
                                  <div style={{ maxHeight: "200px", width: "100%", overflow: 'auto', textAlign: "center" }}>
                                    <img src={`${resource_url + "/images/event/" + item.image}?version=${Date.now()}`} alt=""/>
                                  </div>
                                </td>
                                <td style={{ textAlign: "start" }}>
                                  <div style={{ maxHeight: "200px", width: "100%", overflow: 'auto', textAlign: "" }} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                </td>
                                <DateTd date={item.created_at} />
                                <td style={{ textAlign: "center" }}>
                                  <span>
                                    <button className="btn btn-info btn-sm mr-1"
                                      style={{ color: "#fff" }}
                                      onClick={() => onShowModal("edit")}
                                      title="수정">
                                      <i className="fa fa-edit"></i>
                                    </button>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      style={{ color: "#fff" }} title="삭제"
                                      onClick={() => onShowModal("delete")}>
                                      <i className="fa fa-trash"></i>
                                    </button>
                                    {/* <span
                                      class="label mr-1 label-danger cursor-pointer"
                                      onClick={() => onShowModal("delete")}
                                    >삭제</span> */}
                                  </span>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(eventList) ? ""
                        :
                        <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>
                <Pagenation
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo}
                  getList={getList} />

                {
                  showModal.delete &&
                  <DeleteModal 
                  pageInfo={pageInfo} 
                    getList={getList}
                    data={eventList[active]}
                    onClose={onShowModal}
                    method="post"
                    url="/api/site/event_delete" />
                }

                {
                  (showModal.edit || showModal.create) &&
                  <EventModal 
                  pageInfo={pageInfo} 
                    getList={getList}
                    name="이벤트관리"
                    edit={showModal.edit}
                    data={eventList[active]}
                    onClose={onShowModal}
                    method={"post"}
                    url={showModal.edit ? "/api/site/event_update" : "/api/site/event_register"}
                    type={showModal.edit ? "수정" : "등록"}
                    sendId="id" />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default EventContent;
