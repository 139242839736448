import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { moneyActions } from "../../store";
import { dateFormation, getLocalstringNumber, getPositiveClassName, isEmpty } from "../../utils";

import Pagenation from "../common/pagenation/pagenation";
import Search from "../common/search/Search";
import DateTd from "../common/table/DateTd";
import TableEmpty from "../common/table/TableEmpty";
import Thead from "../common/table/Thead";
import TableTitle from "../common/TableTitle";

const theads = [
  {
    name: "번호",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap', width: "55px" }
  },
  {
    name: "아이디",
    value: "loginid",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "닉네임",
    value: "username",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "사용머니",
    value: "amount",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "이전머니",
    value: "prev_money",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "보유머니",
    value: "money",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "내용",
    value: "memo",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "상세내용",
    value: "description",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap', minWidth: "150px" }
  },
  {
    name: "처리일자",
    value: "created_at",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
]

function MoneyhistoryContent(props) {
  const { type } = props;
  const dispatch = useDispatch();
  const moneyHistory = useSelector(state => state.money.moneyHistory);

  const [active, setActive] = useState(-1);

  // -----------------------------------------------
  const [sortParam, setSortParam] = useState({});
  const [searchParam, setSearchParam] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  });
  // -----------------------------------------------

  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });
  // -------------------- 시간 선택 -------------------- //
  const [dateInput, setDateInput] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  })

  // ------------------- 시간 선택 끝 ------------------ //

  const getList = (page = 1, perPage = 10, search = searchParam, sortParams = sortParam) => {
    setPageInfo({
      ...pageInfo,
      curPage: page,
      perPage: Number(perPage)
    });

    const params = {
      page: page,
      perPage: perPage,
      ...search,
      ...sortParams
    }
    setSearchParam(search);
    setSortParam(sortParams);

    dispatch(moneyActions.getMoneyHistory(params));
    setActive(-1);
  }

  useEffect(() => {
    getList();
  }, []);


  // ------------------- Sort Start ----------------------- //
  const defaultSort = {
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    prev_money: 0,
    amount: 0,
    rolling: 0,
    created_at: 0,
    updated_at: 0,
    memo: 0,
    description: 0,
  }
  const [sort, setSort] = useState({
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    amount: 0,
    prev_money: 0,
    rolling: 0,
    created_at: 0,
    updated_at: 0,
    memo: 0,
    description: 0,
  });

  const onSort = (value) => {
    if (!isEmpty(value)) {
      const sortParams = {
        sortBy: value,
        sortOrder: (sort[value] === 0) ? "asc"
          :
          ((sort[value] === 1) ? "desc" : "asc")
      }
      getList(1, pageInfo.perPage, searchParam, sortParams);
      setSort({
        ...defaultSort,
        [value]: (sort[value] === 0) ? 1
          :
          ((sort[value] === 1) ? -1 : 1)
      });
    }
  }
  // ------------------- Sort End ----------------------- //

  return (
    <Fragment>
      <Search
        pageInfo={pageInfo}
        dateInput={dateInput}
        setDateInput={setDateInput}
        getSearch={getList} />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* {
              <TableTitle title="머니사용이력" />
            } */}
            {/* <h4 style={{ marginLeft: "25px" }} className="card-title">머니사용이력</h4> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">

                <div className="row">
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                    <table className="money-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                      role="grid" aria-describedby="DataTables_Table_0_info">
                        <Thead theads={theads} sort={sort} onSort={onSort}/>
                      <tbody>
                        {
                          moneyHistory.map((history, index) => {
                            return (
                              <tr role="row" className="odd" key={index}>

                                <td className="sorting_1" style={{ textAlign: "center" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                <td style={{ textAlign: "center" }}>{history.loginid}</td>
                                <td style={{ textAlign: "center" }}>{history.username}</td>
                                <td style={{ textAlign: "end" }} 
                                  className={`${getPositiveClassName(history.money_type)} ${Number(history.amount) === 0 && "text-white-i"}`}>
                                  {getLocalstringNumber(history.amount)}
                                </td>
                                <td style={{ textAlign: "end" }} 
                                  className={`${getPositiveClassName(history.money_type)} ${Number(history.prev_money) === 0 && "text-white-i"}`}>
                                  {getLocalstringNumber(history.prev_money)}
                                </td>
                                <td style={{ textAlign: "end" }} 
                                  className={`${getPositiveClassName(history.money_type)} ${Number(history.money) === 0 && "text-white-i"}`}>
                                  {getLocalstringNumber(history.money)}
                                </td>
                                <td style={{ textAlign: "center" }} className={`${getPositiveClassName(history.money_type)} `}>{history.memo}</td>
                                <td style={{ textAlign: "center", lineHeight: "18px" }}>{history.description}</td>
                                <DateTd date={history.created_at}/>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(moneyHistory) ? "" 
                      : <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>
                <Pagenation
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo}
                  getList={getList} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default MoneyhistoryContent;
