import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { siteActions } from "../../store";
import { dateFormation, getStatus, getStatusColorClassName, isEmpty } from "../../utils";
import DeleteModal from "../common/modal/DeleteModal";
import MessageModal from "../common/modal/MessageModal";
import Pagenation from "../common/pagenation/pagenation";
import Search from "../common/search/Search";
import DateTd from "../common/table/DateTd";
import TableEmpty from "../common/table/TableEmpty";
import Thead from "../common/table/Thead";
import TableTitle from "../common/TableTitle";
import CustomerModal from "./CustomerModal";

const theads = [
    {
        name: "번호",
        value: "",
        sort: false,
        style: { textAlign: "center", whiteSpace: "nowrap", width: "55px" }
    },
    {
        name: "아이디",
        value: "loginid",
        sort: true,
        style: { textAlign: "center", whiteSpace: "nowrap" }
    },
    {
        name: "닉네임",
        value: "username",
        sort: true,
        style: { textAlign: "center", whiteSpace: "nowrap" }
    },
    {
        name: "예금주",
        value: "mastername",
        sort: true,
        style: { textAlign: "center", whiteSpace: "nowrap" }
    },
    // {
    //     name: "레벨",
    //     value: "",
    //     sort: true,
    //     style: { textAlign: "center", whiteSpace: "nowrap" }
    // },
    {
        name: "제목",
        value: "title",
        sort: true,
        style: { textAlign: "center", whiteSpace: "nowrap", width: "150px" }
    },
    {
        name: "문의내용",
        value: "",
        sort: false,
        style: { textAlign: "center", whiteSpace: "nowrap", width: "150px" }
    },
    {
        name: "답변내용",
        value: "",
        sort: false,
        style: { textAlign: "center", whiteSpace: "nowrap", width: "150px" }
    },
    {
        name: "문의일자",
        value: "created_at",
        sort: true,
        style: { textAlign: "center", whiteSpace: "nowrap" }
    },
    {
        name: "답변일자",
        value: "answered_at",
        sort: true,
        style: { textAlign: "center", whiteSpace: "nowrap" }
    },
    {
        name: "답변상태",
        value: "status",
        sort: true,
        style: { textAlign: "center", whiteSpace: "nowrap" }
    },
    {
        name: "설정",
        value: "",
        sort: false,
        style: { textAlign: "center", whiteSpace: "nowrap", minWidth: "90px" }
    },
]

function CustomerContent() {
    const dispatch = useDispatch();
    const customerList = useSelector(state => state.site.customerList);

    // -----------------------------------------------
    const [sortParam, setSortParam] = useState({});
    const [searchParam, setSearchParam] = useState({
      starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
      endtime: dateFormation(Date.now(), 3)
    });
    // -----------------------------------------------

    const [active, setActive] = useState(-1);
    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    // -------------------- 시간 선택 -------------------- //
    const [dateInput, setDateInput] = useState({
        starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
        endtime: dateFormation(Date.now(), 3)
    })

    // ------------------- 시간 선택 끝 ------------------ //

    const [showModal, setShowModal] = useState({
        answer: false,
        delete: false,
    });

    const onShowModal = (key) => {
        setShowModal({
            ...showModal,
            [key]: !showModal[key]
        })
    }

    const getList = (page = 1, perPage = 10, search = searchParam, sortParams = sortParam) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });

        const params = {
            page: page,
            perPage: perPage,
            ...search,
            ...sortParams
        }
        setSearchParam(search);
        setSortParam(sortParams);

        dispatch(siteActions.getCustomerList(params));
        setActive(-1);
    }

    useEffect(() => {
        getList();
    }, []);


    // ------------------- Sort Start ----------------------- //
    const defaultSort = {
        loginid: 0,
        parent_name: 0,
        username: 0,
        partner_code: 0,
        rolling_percent: 0,
        agent_type: 0,
        bankname: 0,
        accountnumber: 0,
        mastername: 0,
        status: 0,
        money: 0,
        prev_money: 0,
        amount: 0,
        rolling: 0,
        created_at: 0,
        updated_at: 0,
        memo: 0,
        description: 0,
        service_loginid: 0,
        game_code: 0,
        type: 0,
        round_id: 0,
        game_type: 0,
        provider_code: 0,
        title: 0,
        content: 0,
        is_read: 0,
        answer: 0,
        answered_at: 0,
    }
    const [sort, setSort] = useState({
        loginid: 0,
        parent_name: 0,
        username: 0,
        partner_code: 0,
        rolling_percent: 0,
        agent_type: 0,
        bankname: 0,
        accountnumber: 0,
        mastername: 0,
        status: 0,
        money: 0,
        amount: 0,
        prev_money: 0,
        rolling: 0,
        created_at: 0,
        updated_at: 0,
        memo: 0,
        description: 0,
        service_loginid: 0,
        game_code: 0,
        type: 0,
        round_id: 0,
        game_type: 0,
        provider_code: 0,
        title: 0,
        content: 0,
        is_read: 0,
        answer: 0,
        answered_at: 0,
    });

    const onSort = (value) => {
        if (!isEmpty(value)) {
            const sortParams = {
                sortBy: value,
                sortOrder: (sort[value] === 0) ? "asc"
                    :
                    ((sort[value] === 1) ? "desc" : "asc")
            }
            getList(1, pageInfo.perPage, searchParam, sortParams);
            setSort({
                ...defaultSort,
                [value]: (sort[value] === 0) ? 1
                    :
                    ((sort[value] === 1) ? -1 : 1)
            });
        }
    }
    // ------------------- Sort End ----------------------- //

    return (
        <Fragment>

            <Search
                pageInfo={pageInfo}
                dateInput={dateInput}
                setDateInput={setDateInput}
                getSearch={getList} />
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        {/* <h4 style={{ marginLeft: "25px" }} className="card-title">고객센터</h4> */}
                        <div className="table-responsive overflow-unset">
                            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                                <div className="row">
                                    <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                                        <table className="agent-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                                            role="grid" aria-describedby="DataTables_Table_0_info">
                                            <Thead theads={theads} sort={sort} onSort={onSort} />
                                            <tbody>
                                                {
                                                    customerList.map((item, index) => {
                                                        return (
                                                            <tr role="row" className="odd" key={index} onClick={() => setActive(index)}>

                                                                <td className="sorting_1" style={{ textAlign: "center" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                                                <td style={{ textAlign: "center" }}>{item.loginid}</td>
                                                                <td style={{ textAlign: "center" }}>{item.username}</td>
                                                                <td style={{ textAlign: "center" }}>{item.mastername}</td>
                                                                {/* <td style={{ textAlign: "center" }}></td> */}
                                                                <td style={{ textAlign: "start" }}>{item.title}</td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    <div style={{ maxHeight: "200px", maxWidth: "30vw", overflow: 'auto', textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item.question }}></div>
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    <div style={{ maxHeight: "200px", maxWidth: "30vw", overflow: 'auto', textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                                                                </td>
                                                                <DateTd date={item.created_at} />
                                                                <DateTd date={item.answered_at} />
                                                                <td style={{ textAlign: "center" }} className={item.status === 0 ? "color-youtube" : "color-twitter"}>
                                                                    {item.status === 0 ? "미답변" : "답변"}
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <span>
                                                                        {
                                                                            item.status === 0 &&
                                                                            <button className="btn btn-info btn-sm mr-1"
                                                                                style={{ color: "#fff" }}
                                                                                onClick={() => onShowModal("answer")}
                                                                                title="답변">
                                                                                <i className="fa fa-edit"></i>
                                                                            </button>
                                                                        }
                                                                        <button
                                                                            className="btn btn-danger btn-sm"
                                                                            style={{ color: "#fff" }} title="삭제"
                                                                            onClick={() => onShowModal("delete")}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>
                                                                        {/* {item.status === 0 && <span
                                                                            class="label mr-1 label-info cursor-pointer"
                                                                            onClick={() => onShowModal("answer")}
                                                                        >답변</span>
                                                                        }
                                                                        <span
                                                                            class="label mr-1 label-danger cursor-pointer"
                                                                            onClick={() => onShowModal("delete")}
                                                                        >삭제</span> */}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            !isEmpty(customerList) ? ""
                                                :
                                                <TableEmpty colSpan={theads.length} />
                                        }
                                    </div>
                                </div>

                                <Pagenation
                                    pageInfo={pageInfo}
                                    setPageInfo={setPageInfo}
                                    getList={getList} />

                                {
                                    showModal.delete &&
                                    <DeleteModal
                                        pageInfo={pageInfo}
                                        getList={getList}
                                        data={customerList[active]}
                                        onClose={onShowModal}
                                        method="post"
                                        url="/api/site/delete_question" />
                                }

                                {
                                    showModal.answer &&
                                    <CustomerModal
                                        pageInfo={pageInfo}
                                        getList={getList}
                                        data={customerList[active]}
                                        onClose={onShowModal}
                                        type="공지"
                                        method="post"
                                        url="/api/site/set_answer" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    );
};

export default CustomerContent;
