import moment from "moment";
import $ from "jQuery";
import { NotificationManager } from 'react-notifications';

export const isEmpty = (value) => {
  return value === null ||
    value === undefined ||
    (typeof (value) === "string" && value.trim().length === 0) ||
    (typeof (value) === "object" && Object.keys(value).length === 0)
}

export const convertObj = value => {
  if (typeof value === "object") return value;
  if (typeof value === "string") return JSON.parse(value);
  return value;
};

// To use toastr in node
export const toastr = {
  info: message => {
    NotificationManager.info(message, "", 5000);
  },
  success: message => {
    NotificationManager.success(message, "", 5000);
  },
  warning: message => {
    NotificationManager.warning(message, "", 5000);
  },
  error: message => {
    NotificationManager.error(message, "", 5000);
  }
};


// Show message after get the response from backend.
export const showMessage = message => {
  if (!message) return;
  if (message.warning) NotificationManager.warning(message.warning, "", 5000);
  if (message.info) NotificationManager.info(message.info, "", 5000);
  if (message.success) NotificationManager.success(message.success, "", 5000);
  if (message.error) NotificationManager.error(message.error, "", 5000);
};

// You need to format the date in different forms.
export const dateFormation = (date, type = 1) => {
  switch (type) {
    case 0:
      return moment(new Date(date)).utc().format("YY-MM-DD HH:mm:ss");
    case 1:
      return moment(new Date(date)).utc().format("YYYY-MM-DD HH:mm:ss");
    case 2:
      return moment(new Date(date)).utc().format("YY-MM-DD");
    case 3:
      return moment(new Date(date)).utc().format("YYYY-MM-DD");
    case 4:
      return moment(new Date(date)).utc().format("MM-DD");
    case 5:
      return moment(new Date(date)).utc().format("HH:mm");
    case 6:
      return moment(new Date(date)).utc().format("HH:mm:ss");
    case 7:
      return moment(new Date(date)).utc().format("hh:mm a");
    case 8:
      return moment(new Date(date)).utc().format("YYYY-MM-DD HH:mm");
    case 9:
      return moment(new Date(date)).utc().format("MM/DD HH:mm");
    case 10:
      return moment(new Date(date)).utc().format("YYYY-MM");
    default:
      return "";
  }
};

export const getAgentLevel = (level) => {
  switch (Number(level)) {
    case 3:
      return "마스터";
    case 2:
      return "오퍼레이터";
    case 1:
      return "에이젠트";
    case 0:
    case -1:
      return "회원";

    default:
      break;
  }
}

export const getAgentLevelClass = (level) => {
  switch (Number(level)) {
    case 3:
      return "bg-blue";
    case 2:
      return "bg-purple";
    case 1:
      return "bg-green";
    case 0:
    case -1:
      return "bg-yellow";

    default:
      break;
  }
}

export const getStatus = (status) => {
  switch (Number(status)) {
    case 1:
      return "정상"
    case 0:
      return "중지"
    default:

      return "대기"
      break;
  }
}

export const getBankStatus = (status) => {
  switch (Number(status)) {
    case 1:
      return "허용"
    case 0:
      return "불허"
    default:
      return "대기";
  }
}

export const getStatusColorClassName = (status) => {
  switch (Number(status)) {
    case 3:
      return "badge badge-info";
    case 2:
      return "badge badge-primary"
    case 1:
      return "badge badge-success";
    case -1:
      return "badge badge-warning";
    case 0:
      return "badge badge-danger";
    default:
      break;
  }
}

export const getStatusTextColorClassName = (status) => {
  switch (Number(status)) {
    case 3:
      return "info-color";
    case 2:
      return "primary-color"
    case 1:
      return "success-color";
    case -1:
      return "warning-color";
    case 0:
      return "danger-color";
    default:
      break;
  }
}

export const getStatusClassName = (status) => {
  switch (Number(status)) {
    case 1:
      return "bg-blue"
    case 0:
      return "bg-red";
    default:
      break;
  }
}

export const getAgentLevelClassName = (level) => {
  switch (Number(level)) {
    case 3:
      return "badge-state badge badge-info"
    case 2:
      return "badge-state badge badge-success";
    case 1:
      return "badge-state badge badge-warning";
    default:
      break;
  }
}

export const getAgentLevelEng = (data) => {
  switch (Number(data)) {
    case 3:
      return "A";
    case 2:
      return "B";
    case 1:
      return "C";
    case -1:
      return "D";

    default:
      return "";
  }
}

export const getPayStatus = (status) => {
  switch (Number(status)) {
    case 1:
      return "지급"
    case 0:
      return "차감"
    default:
      break;
  }
}



export const getResStatus = (status) => {
  if (Number(status) === 200)
    return "성공"
  else
    return "실패"

}

export const getResStatusClassName = (status) => {
  if (Number(status) === 200)
    return "bg-blue"
  else
    return "bg-red"

}


export const getGameStatus = (status) => {
  if (Number(status) === 0)
    return "당첨"
  else
    return "낙첨"

}

export const getGameStatusClassName = (status) => {
  if (Number(status) === 0)
    return "bg-blue"
  else
    return "bg-red"

}

export const getMoneyModalType = (status) => {
  if (status.accept) {
    return "accept"
  }
  else if (status.rejection) {
    return "rejection"
  }
  else if (status.waiting) {
    return "waiting"
  }
  else {
    return ""
  }
}

export const getMoneyModalUrl = (status, type) => {
  if (status.accept) {
    return type === "충전" ? "/api/money/enablecharge" : "/api/money/enablewithdraw"
  }
  else if (status.rejection) {
    return type === "충전" ? "/api/money/disablecharge" : "/api/money/disablewithdraw"
  }
  else if (status.waiting) {
    return type === "충전" ? "/api/money/charge_await" : "/api/money/withdraw_await"
  }
  else {
    return ""
  }
}

export const getNewUserAcceptlUrl = (status, type) => {
  if (status.accept) {
    return "/api/user/set_newuser_allow"
  }
  else if (status.rejection) {
    return "/api/user/set_newuser_refuse"
  }
  else if (status.waiting) {
    return "/api/user/set_newuser_ready"
  }
  else {
    return ""
  }
}

export const getPositiveClassName = (status) => {
  switch (Number(status)) {
    case 1:
    case 11:
    case 31:
      return "color-twitter";

    case -1:
    case -11:
    case 30:
      return "color-youtube";
    default:
      break;
  }
}

export const getNegativeClassName = (status) => {
  switch (Number(status)) {
    case 1:
    case 11:
    case 30:
      return "color-youtube";

    case -1:
    case -11:
    case 31:
      return "color-twitter";
    default:
      break;
  }
}

export const getLocalstringNumber = (value) => {
  if (isNaN(Number(value))) {
    return 0;
  }
  else {
    return Number(value).toLocaleString();
  }
}

export const no_comma = data => {
  var tmp = "";
  var comma = ",";
  var i;

  for (i = 0; i < data.length; i++) {
    if (data.charAt(i) != comma) tmp += data.charAt(i);
  }
  return tmp;
};


export const getIpAddr = () => {
  try {
    let theUrl = 'https://www.cloudflare.com/cdn-cgi/trace'
    // let theUrl = 'https://172.16.88.156:14998'
    let xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", theUrl, false) // false for synchronous request
    xmlHttp.send(null);

    let responseText = xmlHttp.responseText;
    let ip = responseText.split('ip=')[1].split('ts=')[0];
    localStorage.setItem('client-ip', ip);
  } catch (e) {
    //console.log('couldFlareCatch==>', e)
    ipify()
  }


  function ipify() {
    try {
      $.getJSON("https://api.ipify.org?format=json", function (data) {
        //console.log('ipify===>', data)
        if (data.ip)
          localStorage.setItem('client-ip', data.ip)
        else
          amazon()
      })
        .catch(err => {
          console.error('err: ', err);
          //console.log('ipifyCatch==>', err)
          amazon()
        })
    } catch (e) {
      //console.log('ipifyCatch==>', e)
      amazon()
    }
  }

  function amazon() {
    try {
      fetch("https://checkip.amazonaws.com/").then(res => res.text()).then(data => {
        //console.log('amazon', data);
        localStorage.setItem('client-ip', data)
      })
        .catch(err => {
          console.error('err: ', err);
          //console.log('amazonCatch==>', err)
        })
    } catch (e) {
      //console.log('amazonCatch==>', e)
    }
  }

}




export const loginMusic = () => {
  try {
    $("#noneMusic")[0].play();
  }
  catch (err) {
    //console.log("err", err)
  }
}

export const messageMusic = {
  start: () => {
    try {
      $("#messageMusic")[0].play()
      .catch(err => {
        console.log('err: ', err);
      });
    }
    catch (err) {
      //console.log("err", err)
    }
  },
  stop: () => {
    try {
      $("#messageMusic")[0].pause().catch(err => {
        console.log('err: ', err);
      });;
    }
    catch (err) {
      //console.log("err", err)
    }
  }

}