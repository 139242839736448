import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { dateFormation, getAgentLevel, getAgentLevelClassName, getAgentLevelEng, getLocalstringNumber, getStatus, getStatusColorClassName, getStatusTextColorClassName, isEmpty, showMessage, toastr } from '../../utils';
import axios from "axios";
import { DatePicker, Stack } from 'rsuite';
import 'rsuite/dist/datepicker.css';
import { api_url } from "../../utils/keys";
import UserCalculateNode from "./UserCalculateNode";

const DailyCalcuNode = (props) => {
  const { data, getCalcDailyFigure, activeAgent, setActiveAgent, level } = props;
  const [userData, UserData] = useState(data);
  const [active, setActive] = useState(false);

  const getMyChildren = (parent_loginid) => {

    const date = {
      starttime: dateFormation(data.created_at, 3),
      endtime: ""
    }

    getCalcDailyFigure(data.loginid, date)
      .then(subChildren => {
        if (!isEmpty(subChildren)) {
          UserData({
            ...userData,
            children: subChildren
          });
        }
      });
  }
  const onClickAgent = () => {
    setActive(!active);
    if (!active && (isEmpty(data.loginid) || (Number(data.user_count) > 0))) {
      getMyChildren(data.loginid);
    }
  }

  const setClassName = (value) => {
    switch (value) {
      case 3:
        return "btn-info"
        break;
      case 2:
        return "btn-secondary"
        break;
      case 1:
        return "btn-success"
        break;
      case -1:
        return "btn-warning"
        break;
    }
  }

  return (
    <Fragment>
      <tr role="row" className="odd" onClick={onClickAgent}>
        <td style={{ textAlign: "start" }}>
          <span className="el-table__indent" style={{ paddingLeft: 16 * (level - 1) }}></span>
          {((Number(data.user_count) > 0) || isEmpty(data.loginid)) ?
            <i className={` fa ${active ? "fa-minus-square" : "fa-plus-square"} margin-right-5`}></i>
            :
            <i className=" " style={{ width: "50px" }}>&emsp;</i>
          }
          { !isEmpty(data.agent_type) && <button type="button" className={`btn mb-1 btn-sm ${getStatusColorClassName(data.agent_type)}`} style={{ marginRight: "15px" }}>
            <i className="fa fa-user-o"></i>
          </button>}
          {isEmpty(data.username) ? dateFormation(data.created_at, 3) : data.loginid}
        </td>
        <td style={{ textAlign: "center" }}>{data.username}</td>
        <td style={{ textAlign: "center" }}>
          <span className={getStatusTextColorClassName(data.agent_type)}>{getAgentLevel(data.agent_type)}</span>
        </td>
        <td style={{ textAlign: "end" }} className="color-twitter">
          {getLocalstringNumber(data.charge_money)}
        </td>
        <td style={{ textAlign: "end" }} className="color-youtube">
          {getLocalstringNumber(data.withdraw_money)}
        </td>
        <td style={{ textAlign: "end" }} className="">
          {getLocalstringNumber(data.money)}
        </td>
        <td style={{ textAlign: "end" }} className="">
          {getLocalstringNumber(data.rolling)}
        </td>
        <td style={{ textAlign: "end" }} className="color-youtube">
          {getLocalstringNumber(data.luckywhale_betting_money)}
        </td>
        <td style={{ textAlign: "end" }} className="color-twitter">
          {getLocalstringNumber(data.luckywhale_win_money)}
        </td>
        <td style={{ textAlign: "end" }} className="">
          {getLocalstringNumber(data.luckywhale_parent_money)}
        </td>
      </tr>
      {
        (!isEmpty(userData.children) && active) && userData.children.map((item, index) => {
          return (
            <DailyCalcuNode
              data={item}
              level={level + 1}
              activeAgent={activeAgent}
              setActiveAgent={setActiveAgent}
              getCalcDailyFigure={getCalcDailyFigure}
              key={item.created_at + index} />)
        })
      }
    </Fragment>
  );
};

export default DailyCalcuNode;
