import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute(props) {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const userData = useSelector(state => state.user.data);
  const { role, children } = props;
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  }
  else if ((role !== 0) && (role > userData.agent_type)) {
    return <Navigate to="/" replace />
  }
  else {
    return children
  }
}
export default PrivateRoute;