import axios from "axios";
import { store, userActions } from "../store";
import { isEmpty, showMessage, toastr } from "../utils";

export const request = {
  get: (url, params) => {
    return new Promise(resolve => {
      axios.get(url, params)
        .then(res => {
          const data = res.data;
          if (data.success) {
            resolve(data);
          }
          else {
            if (!isEmpty(data.message)) {
              toastr.warning(data.message);
            }
            showMessage(data.message)
          }
        })
        .catch(err => {
        console.error("error", err);
        if (err.response && (err.response.status === 401)) {
          return store.dispatch(userActions.logout());
        }
        return toastr.warning("잠시후 다시 시도해주세요.");
      });
    })
  },
  post: (url, params) => {
    return new Promise(resolve => {
      axios.post(url, params)
        .then(res => {
          const data = res.data;
          if (data.success) {
            resolve(data);
          }
          else {
            if (!isEmpty(data.message)) {
              toastr.warning(data.message);
            }
            showMessage(data.message)
          }
        })
        .catch(err => {
        console.error("error", err);
        if (err.response && (err.response.status === 401)) {
          return store.dispatch(userActions.logout());
        }
        return toastr.warning("잠시후 다시 시도해주세요.");
      });
    })
  },
  put: (url, params) => {
    return new Promise(resolve => {
      axios.put(url, params)
        .then(res => {
          const data = res.data;
          if (data.success) {
            resolve(data);
          }
          else {
            if (!isEmpty(data.message)) {
              toastr.warning(data.message);
            }
            showMessage(data.message)
          }
        })
        .catch(err => {
        console.error("error", err);
        if (err.response && (err.response.status === 401)) {
          return store.dispatch(userActions.logout());
        }
        return toastr.warning("잠시후 다시 시도해주세요.");
      });
    })
  },
  delete: (url, params) => {
    return new Promise(resolve => {
      axios.delete(url, params)
        .then(res => {
          const data = res.data;
          if (data.success) {
            resolve(data);
          }
          else {
            if (!isEmpty(data.message)) {
              toastr.warning(data.message);
            }
            showMessage(data.message)
          }
        })
        .catch(err => {
        console.error("error", err);
        if (err.response && (err.response.status === 401)) {
          return store.dispatch(userActions.logout());
        }
        return toastr.warning("잠시후 다시 시도해주세요.");
      });
    })
  },
}