import { isEmpty } from "../../../utils";
import Modalback from "./Modalback";

function Confirm(props) {
  const { title, content, onClose, onOk, onCancel, okContent, cancelContent, isClose, headerStyle, iconClassName, iconStyle } = props;

  const onClickCancel = () => {
    if (!isEmpty(onCancel)) {
      onClose();
    }
    else {
      onCancel();
    }
  }
  return (
      <div 
      className="modal fade bd-example-modal-sm agentDeleteModal show" 
      tabIndex="-1" 
      style={{ display: "block" }}>
        <Modalback onClose={onClose}/>
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header d-flex justify-content-center align-items-center">
            {/* <i className="fa fa-exclamation-circle" style={{color: "red", fontSize: "45px", marginLeft: "120px"}}></i> */}
            <button type="button" className="close" onClick={onClose}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body d-flex justify-content-center align-items-center">{content}</div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger btn-sm" onClick={onClose}>{cancelContent ? cancelContent : "아니오"}</button>
            <button type="button" className="btn btn-primary btn-sm" style={{ width: "45px" }} onClick={onOk}>{okContent ? okContent : "네"}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confirm;
