import { useState } from "react";
import { useDispatch } from 'react-redux'
import { userActions } from "../../store";
import { isEmpty, loginMusic, toastr } from "../../utils";

function Login() {
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    adminLoginId: "",
    adminLoginPwd: ""
  });
  const { adminLoginId, adminLoginPwd } = inputs

  const Change = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value
    })
  }


  const onSubmit = (e) => {
    e.preventDefault();
    loginMusic();

    if (isEmpty(adminLoginId)) {
      return toastr.warning("아이디를 입력해주세요.")
    }
    if (isEmpty(adminLoginPwd)) {
      return toastr.warning("비밀번호를 입력해주세요.")
    }
    const params = {
      loginid: adminLoginId,
      password: adminLoginPwd
    }
    dispatch(userActions.loginUser(params));
  }

  const onEnter = (e) => {
    if (e.key === "Enter") {
      onSubmit(e);
    }
  }


  return (
    <div className="login-page login-form-bg h-100">
      <div className="container h-100">
        <div className="row justify-content-center h-100">
          <div className="col-lg-5 login-input-group-h-100">
            <div className="form-input-content">
              <div className="card login-form mb-0" style={{ backgroundColor: "rgba(120, 130, 140, 0.13)" }}>
                <div className="card-body pt-4">
                  <div className="text-center">
                    <img src="../images/logo.png" style={{ width: "50%" }} alt="" />
                  </div>

                  <div className="mt-4 mb-4 login-input" onKeyDown={onEnter}>
                    <div className="form-group d-flex justify-content-between align-items-center border-bottom-1 position-relative">
                      <div className="login-icon">
                        <i className="fa fa-user-o ml-2 mr-2" style={{ fontSize: "16px", color: "white" }}></i>
                      </div>
                      <input
                        type="text"
                        name="adminLoginId"
                        className="form-control bg-transparent border-0 login-text-input"
                        placeholder="아이디를 입력해주세요."
                        value={adminLoginId}
                        onChange={Change} />
                    </div>
                    <div className="form-group d-flex justify-content-between align-items-center border-bottom-1 position-relative">
                      <div className="login-icon">
                        <i className="fa fa-key ml-2 mr-2" style={{ fontSize: "16px", color: "white" }}></i>
                      </div>
                      <input
                        type="password"
                        name="adminLoginPwd"
                        className="form-control bg-transparent border-0 login-text-input"
                        placeholder="비번을 입력해주세요."
                        value={adminLoginPwd}
                        onChange={Change} />
                    </div>
                    <button className="btn login-form__btn submit w-100" onClick={onSubmit}>로그인</button>
                  </div>
                  <div>
                  </div>
                </div>
              </div>
              <span style={{ color: "#a4a2a2", textAlign: "center", lineHeight: "40px" }}>Copyright @ {(new Date()).getFullYear()} All rights reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
