import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gameActions } from "../../store/game.slice";
import { dateFormation, getLocalstringNumber, getPositiveClassName, isEmpty } from "../../utils";
import Search from "../common/search/Search";
import Pagenation from "../common/pagenation/pagenation";
// import TableTitle from "../common/TableTitle";
import DateTd from "../common/table/DateTd";
import Thead from "../common/table/Thead";
import TableEmpty from "../common/table/TableEmpty";

const theads = [
  {
    name: "번호",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap', width: "55px" }
  },
  {
    name: "아이디",
    value: "loginid",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "닉네임",
    value: "username",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "게임사유저아이디",
    value: "service_loginid",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "게임 코드",
    value: "game_code",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "타입",
    value: "type",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "베팅전잔액",
    value: "prev_money",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "베팅머니",
    value: "amount",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "당첨머니",
    value: "win_amount",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "변동머니",
    value: "change_amount",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "당첨후 금액",
    value: "money",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "게임라운드",
    value: "round_id",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  // {
  //   name: "게임명",
  //   value: "",
  //   sort: true,
  //   style: { textAlign: "center", whiteSpace: 'nowrap' }
  // },
  {
    name: "게임형태",
    value: "game_type",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "게임사",
    value: "provider_code",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "처리일자",
    value: "created_at",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
]

function SlotHistoryContent() {
  const dispatch = useDispatch();
  const slotBettingHistory = useSelector(state => state.game.slotBettingHistory);
  const total = useSelector(state => state.game.total);

  // -----------------------------------------------
  const [sortParam, setSortParam] = useState({});
  const [searchParam, setSearchParam] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  });
  // -----------------------------------------------

  // const [active, setActive] = useState(-1);
  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });
  // -------------------- 시간 선택 -------------------- //
  const [dateInput, setDateInput] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  })

  // ------------------- 시간 선택 끝 ------------------ //

  // const [showModal, setShowModal] = useState({
  //   delete: false,
  //   message: false
  // });

  // const onShowModal = (key) => {
  //   setShowModal({
  //     ...showModal,
  //     [key]: !showModal[key]
  //   })
  // }

  const getList = (page = 1, perPage = 10, search = searchParam, sortParams = sortParam ) => {
    setPageInfo({
      ...pageInfo,
      curPage: page,
      perPage: Number(perPage)
    });

    const params = {
      page: page,
      perPage: perPage,
      ...search,
      ...sortParams
    }
    setSearchParam(search);
    setSortParam(sortParams);

    dispatch(gameActions.getSlotBettingHistory(params));
    // setActive(-1);
  }

  // const getListTime = setTimeout(() => {
  //   getList();
  //   // if(pageInfo.curPage === 1){
  //   //   getList();
  //   // }
  // }, 5000);

  useEffect(() => {
    getList();
    // let listTime = null;
    // const getListTime = () => { 
    //   getList();
    //   listTime = setTimeout(() => {
    //     getListTime();
    //   }, 5000);
    // }
    // getListTime();
    // return () => {
    //   clearTimeout(listTime);
    // }
  }, []);

  // ------------------- Sort Start ----------------------- //
  const defaultSort = {
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    prev_money: 0,
    amount: 0,
    win_amount: 0,
    change_amount: 0,
    rolling: 0,
    created_at: 0,
    updated_at: 0,
    memo: 0,
    description: 0,
    service_loginid: 0,
    game_code: 0, 
    type: 0,
    round_id: 0,
    game_type: 0,
    provider_code: 0,
  }
  const [sort, setSort] = useState({
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    amount: 0,
    win_amount: 0,
    change_amount: 0,
    prev_money: 0,
    rolling: 0,
    created_at: 0,
    updated_at: 0,
    memo: 0,
    description: 0,
    service_loginid: 0,
    game_code: 0, 
    type: 0,
    round_id: 0,
    game_type: 0,
    provider_code: 0,
  });

  const onSort = (value) => {
    if (!isEmpty(value)) {
      const sortParams = {
        sortBy: ((value === "win_amount") || value === "change_amount") ? "amount" : value,
        sortOrder: (sort[value] === 0) ? "asc"
          :
          ((sort[value] === 1) ? "desc" : "asc")
      }
      getList(1, pageInfo.perPage, searchParam, sortParams);
      setSort({
        ...defaultSort,
        [value]: (sort[value] === 0) ? 1
          :
          ((sort[value] === 1) ? -1 : 1)
      });
    }
  }
  // ------------------- Sort End ----------------------- //
  return (
    <Fragment>
      <Search
        pageInfo={pageInfo}
        dateInput={dateInput}
        setDateInput={setDateInput}
        getSearch={getList} />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* <TableTitle title="슬롯베팅내역" /> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                <div className="row justify-content-end padding-bottom-15">
                  <div className="">
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex total-info-buttons">
                    <span className="margin-right-10 btn btn-info total-money-btn  d-flex align-items-center border-radius-0">
                      베팅금액&ensp;<span className="" style={{ color: "#FDC60A" }}>{getLocalstringNumber(total.totalBetmoney)}</span>
                    </span>
                    <span className="margin-right-10 btn btn-danger total-money-btn  d-flex align-items-center border-radius-0">
                      당첨금액&ensp;<span className="" style={{ color: "#F3E500" }}>{getLocalstringNumber(total.totalWinmoney)}</span>
                    </span>
                    <span className="margin-right-10 btn btn-success total-money-btn  d-flex align-items-center border-radius-0">
                      베팅손익&ensp;<span className="" style={{ color: "#ffeb3b" }}>{getLocalstringNumber(total.totalProfit)}</span>
                    </span>
                  </div>
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                    <table className="agent-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                      role="grid" aria-describedby="DataTables_Table_0_info">
                      <Thead theads={theads} sort={sort} onSort={onSort}/>
                      <tbody>
                        {
                          slotBettingHistory.map((user, index) => {
                            return (
                              <tr role="row" className="odd" key={index}>

                                <td className="sorting_1" style={{ textAlign: "center" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                <td style={{ textAlign: "center" }}>{user.loginid}</td>
                                <td style={{ textAlign: "center" }}>{user.username}</td>
                                <td style={{ textAlign: "center" }}>{user.service_loginid}</td>
                                <td style={{ textAlign: "center" }}>{user.game_code}</td>
                                <td style={{ textAlign: "center" }} className={getPositiveClassName(user.type)}>{user.type === 30 ? "베팅" : "당첨"}</td>
                                <td style={{ textAlign: "end" }} className={`${getPositiveClassName(user.type)} ${Number(user.prev_money === 0) && "text-white-i"}`}>
                                  {getLocalstringNumber(user.prev_money)}
                                </td>
                                <td style={{ textAlign: "end" }} 
                                  className={`${getPositiveClassName(user.type)} 
                                    ${(isNaN(user.amount) || (Number(user.type) !== 30) ||(Number(user.amount) === 0)) && "text-white-i"}`}>
                                  {(isNaN(user.amount) || (Number(user.type) !== 30)) ? 0 : Number(user.amount).toLocaleString()}
                                </td>
                                <td style={{ textAlign: "end" }} 
                                  className={`${getPositiveClassName(user.type)} 
                                    ${(isNaN(user.amount) || (Number(user.type) !== 31) ||(Number(user.prev_money) === 0)) && "text-white-i"}`}>
                                  {(isNaN(user.amount) || (Number(user.type) !== 31)) ? 0 : Number(user.amount).toLocaleString()}
                                </td>
                                <td style={{ textAlign: "end" }} 
                                  className={`${getPositiveClassName(user.type)} 
                                    ${Number(user.amount === 0) && "text-white-i"}`}>
                                  {getLocalstringNumber(user.amount)}
                                </td>
                                <td style={{ textAlign: "end" }} 
                                  className={`${getPositiveClassName(user.type)} 
                                    ${Number(user.money === 0) && "text-white-i"}`}>
                                  {getLocalstringNumber(user.money)}
                                </td>
                                <td style={{ textAlign: "center" }}>{user.round_id}</td>
                                <td style={{ textAlign: "center" }}>{user.game_type}</td>
                                {/* <td style={{ textAlign: "center" }}>{user.game_type}</td> */}
                                <td style={{ textAlign: "center" }}>{user.provider_code}</td>
                                <DateTd date={user.created_at}/>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(slotBettingHistory) ? "" 
                      : 
                      <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>
                <Pagenation
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo} 
                  getList={getList} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SlotHistoryContent;
