import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { request } from "../../api";
import { siteActions } from "../../store";
import { dateFormation, isEmpty, toastr } from "../../utils";
import { api_url, resource_url } from "../../utils/keys";
import Confirm from "../common/modal/Confirm";
import DeleteModal from "../common/modal/DeleteModal";
import Pagenation from "../common/pagenation/pagenation";
import TableEmpty from "../common/table/TableEmpty";
import Thead from "../common/table/Thead";
import BankEditModal from "./Bank/BankEditModal";
import BankNameEdit from "./Bank/BankNameEdit";

const theads = [
  {
    name: "번호",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap", width: "55px" }
  },
  {
    name: "은행명",
    value: "bank_name",
    sort: true,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "로고",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "로고관리",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "상태",
    value: "status",
    sort: true,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "관리",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
]

function BankManageContent() {
  const dispatch = useDispatch();
  const bankManageList = useSelector(state => state.site.bankManageList);

  // -----------------------------------------------
  const [sortParam, setSortParam] = useState({});
  const [searchParam, setSearchParam] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  });
  // -----------------------------------------------

  const [active, setActive] = useState(-1);
  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });

  const [inputs, setInputs] = useState({
    bankName: "",
    searchoption: "status",
    searchtext: "all"
  });

  const { searchtext } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value
    });

    if (name === "searchtext") {
      getList(1, pageInfo.perPage, { ...inputs, [name]: value });
    }
  }

  const getList = (page = 1, perPage = 10, search = searchParam, sortParams = sortParam) => {
    setPageInfo({
      ...pageInfo,
      curPage: page,
      perPage: Number(perPage)
    });

    const params = {
      page: page,
      perPage: perPage,
      ...search,
      ...sortParams
    }
    setSearchParam(search);
    setSortParam(sortParams);

    dispatch(siteActions.getBankManageList(params));
    setActive(-1);
  }

  useEffect(() => {
    getList();
  }, []);

  const [showModal, setShowModal] = useState({
    create: false,
    delete: false,
    imgDelete: false
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  // ------------------- Sort Start ----------------------- //
  const defaultSort = {
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    prev_money: 0,
    amount: 0,
    rolling: 0,
    created_at: 0,
    updated_at: 0,
    memo: 0,
    description: 0,
    service_loginid: 0,
    game_code: 0,
    type: 0,
    round_id: 0,
    game_type: 0,
    provider_code: 0,
    title: 0,
    content: 0,
    is_read: 0,
    answer: 0,
    answered_at: 0,
    money_type: 0,
    bank_name: 0
  }
  const [sort, setSort] = useState({
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    amount: 0,
    prev_money: 0,
    rolling: 0,
    created_at: 0,
    updated_at: 0,
    memo: 0,
    description: 0,
    service_loginid: 0,
    game_code: 0,
    type: 0,
    round_id: 0,
    game_type: 0,
    provider_code: 0,
    title: 0,
    content: 0,
    is_read: 0,
    answer: 0,
    answered_at: 0,
    money_type: 0,
    bank_name: 0,
  });

  const onSort = (value) => {
    if (!isEmpty(value)) {
      const sortParams = {
        sortBy: value,
        sortOrder: (sort[value] === 0) ? "asc"
          :
          ((sort[value] === 1) ? "desc" : "asc")
      }
      getList(1, pageInfo.perPage, searchParam, sortParams);
      setSort({
        ...defaultSort,
        [value]: (sort[value] === 0) ? 1
          :
          ((sort[value] === 1) ? -1 : 1)
      });
    }
  }
  // ------------------- Sort End ----------------------- //

  const onUpdateBankStatus = (id, status) => {
    onShowModal("confirm");
    const params = {
      id: id,
      status: status
    }
    request.post(api_url + "/api/site/updatebankstatus", params)
      .then(res => {
        toastr.success(res.message);
        getList(pageInfo.curPage, pageInfo.perPage);
      })
  }

  const onUpdateBankImg = (img, index) => {
    if (img === undefined) {
      return;
    }

    const data = bankManageList[index];

    if (!isEmpty(data)) {
      const paramdata = {
        id: data.id,
        status: data.status,
        bank_name: data.bank_name
      }
      let formData = new FormData();
      formData.append("image", img);
      for (let param in paramdata) {
        formData.append(param, paramdata[param]);
      }

      const params = formData;

      request.post(api_url + "/api/site/updatebankinfo", params)
        .then(res => {
          toastr.success(res.message);
          getList(pageInfo.curPage, pageInfo.perPage, {}, sort);
        })
    }
  }

  const [confirmContent, setConfirmContent] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("")
  const onConfirm = (value) => {
    setConfirmStatus(value);
    onShowModal("confirm");
    switch (value) {
      case "status": {
        setConfirmContent("상태를 변경하시겠습니까?");
        break;
      }

      default:
        break;
    }
  }

  const onOk = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    onUpdateBankStatus(bankManageList[active].id, bankManageList[active].status);
  }

  const onCancel = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    setShowModal("confirm");
  }

  return (
    <Fragment>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* <h4 style={{ marginLeft: "25px" }} className="card-title">은행관리</h4> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                <div className="row justify-content-between padding-bottom-15">
                  <div className=" ml-3 total-info-buttons">
                    <select className="form-control" name="searchtext" value={searchtext} onChange={onChange}>
                      <option value="all">전체</option>
                      <option value="allow">허용</option>
                      <option value="refuse">불허</option>
                    </select>
                  </div>
                  <div className="mr-3 new-button">
                    <div className="input-group icons">
                      {/* <input type="text" className="form-control mr-2" placeholder="은행이름" name="bankName" value={bankName} onChange={onChange} /> */}
                      <button type="button" className="btn btn-primary sm search-btn" onClick={() => onShowModal("create")}>
                        <i className="fa fa-bank f-s-14 mr-1" aria-hidden="true"></i>
                        생성
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                    <table className="user-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                      role="grid" aria-describedby="DataTables_Table_0_info">
                      <Thead theads={theads} sort={sort} onSort={onSort} />
                      <tbody>
                        {
                          bankManageList.map((item, index) => {
                            return (
                              <tr role="row" className="odd" key={index} onClick={() => setActive(index)}>

                                <td className="sorting_1" style={{ textAlign: "center" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                <td>
                                  <BankNameEdit data={item} getList={getList} pageInfo={pageInfo} key={item.id} />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <div style={{ maxHeight: "200px", width: "100%", overflow: 'auto', textAlign: "center" }}>
                                    {
                                      isEmpty(item.image) ? "이미지 없음"
                                        :
                                        <img src={`${resource_url + "/images/bank/" + item.image}?version=${Date.now()}`} alt={item.bank_name} />
                                    }
                                  </div>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span className="position-relative">
                                    <input
                                      type="file"
                                      accept=".png, .jpg, .jpeg"
                                      style={{ position: "absolute", left: "0", right: "0", zIndex: -1, }}
                                      id={"thumnail" + index}
                                      name="thumnail"
                                      onChange={(e) => {
                                        onUpdateBankImg(e.target.files[0], index);
                                      }} />
                                    <label htmlFor={"thumnail" + index}
                                      className="btn btn-info btn-sm mr-1 mb-0 cursor-pointer"
                                    >
                                      <i className="fa fa-edit"></i>
                                    </label>
                                    <button
                                      className="btn btn-danger btn-sm mr-1 cursor-pointer"
                                      onClick={() => onShowModal("imgDelete")}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <label
                                    className={`${(Number(item.status) === 1) ? "success-switch" : "danger-switch false"} switch-label`}
                                    data-on-label=""
                                    data-off-label=""
                                    onClick={() => onConfirm("status")} >
                                  </label>
                                  {/* <span className={`${getStatusColorClassName(item.status)} cursor-pointer`} onClick={() => onUpdateBankStatus(item.id, item.status)}>
                                    {getBankStatus(item.status)}
                                  </span> */}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span>
                                    {/* <button
                                      className="btn btn-warning btn-sm mr-1 cursor-pointer"
                                      onClick={() => onShowModal("message")}
                                    >불허</button> */}
                                    <button
                                      className="btn btn-danger btn-sm mr-1 cursor-pointer"
                                      onClick={() => onShowModal("delete")}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(bankManageList) ? ""
                        :
                        <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>
                <Pagenation
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo}
                  getList={getList} />

                {
                  showModal.create &&
                  <BankEditModal
                    pageInfo={pageInfo}
                    name="은행생성"
                    method="post"
                    url="/api/site/bank_register"
                    getList={getList}
                    onClose={() => onShowModal("create")}
                  />
                }

                {
                  (showModal.delete || showModal.imgDelete) &&
                  <DeleteModal
                    pageInfo={pageInfo}
                    getList={getList}
                    data={bankManageList[active]}
                    onClose={onShowModal}
                    imgDelete={showModal.imgDelete}
                    name={showModal.delete ? "은행을" : "은행이미지를"}
                    method="post"
                    url={showModal.delete ? "/api/site/delete_bank" : "/api/site/delete_bankimage"} />
                }

                {
                  showModal.confirm &&
                  <Confirm
                    content={confirmContent}
                    onOk={onOk}
                    onClose={onCancel} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default BankManageContent;
