import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { request } from "../../api";
import { userActions } from "../../store";
import { isEmpty, toastr } from "../../utils";
import { api_url } from "../../utils/keys";
import Confirm from "../common/modal/Confirm";

function MyPagePwd() {
  const userInfo = useSelector(state => state.user.data);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });

  const {
    oldPassword,
    newPassword,
    newPasswordConfirm,
  } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target
    setInputs({
      ...inputs,
      [name]: value
    })
  }

  const onSubmit = () => {
    if (isEmpty(oldPassword)) {
      return toastr.warning("현재 비밀번호를 입력해주세요.")
    }
    if (isEmpty(newPassword)) {
      return toastr.warning("새 비밀번호을 입력해주세요.")
    }
    if (newPassword !== newPasswordConfirm) {
      return toastr.warning("새 비밀번호 확인을 입력해주세요.")
    }

    const params = {
      old_password: oldPassword,
      new_password: newPassword,
      // newPasswordConfirm: newPasswordConfirm,
    }

    request.put(api_url + "/api/auth/updatePassword", params)
      .then((res) => {
        toastr.success(res.message);
        dispatch(userActions.getUserInfo());
      });
  }


  // ------------------------------------------------- //

  const [showModal, setShowModal] = useState({
    confirm: false
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const [confirmContent, setConfirmContent] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("");

  const onConfirm = (value) => {
    setConfirmStatus(value);
    onShowModal("confirm");
    setConfirmContent(`비밀번호를 변경하시겠습니까?`);
  }

  const onOk = () => {
    setConfirmStatus("");
    onShowModal("confirm");
    onSubmit();
  }

  const onCancel = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    setShowModal("confirm");
  }



  return (
    <Fragment>
      <div className="col-lg-6">
        <div className="form-group">
          <label>이전 비밀번호</label>
          <input
            type="password"
            className="form-control"
            placeholder="현재 비밀번호를 입력해주세요."
            name="oldPassword"
            value={oldPassword}
            onChange={onChange} />
        </div>
        <div className="form-group">
          <label>새 비밀번호</label>
          <input
            type="password"
            className="form-control"
            placeholder="새 비밀번호를 입력해주세요."
            name="newPassword"
            value={newPassword}
            onChange={onChange} />
        </div>
        <div className="form-group">
          <label>새 비밀번호확인</label>
          <input
            type="password"
            className="form-control"
            placeholder="새 비밀번호확인을 입력해주세요."
            name="newPasswordConfirm"
            value={newPasswordConfirm}
            onChange={onChange} />
        </div>
        <div className="form-group row">
          <div className="m-auto">
            <button type="submit" className="btn btn-primary" onClick={onConfirm}>비밀번호 변경</button>
          </div>
        </div>
      </div>

      {
        showModal.confirm ?
          <Confirm
            content={confirmContent}
            onOk={onOk}
            onClose={onCancel} />
          : ''
      }
    </Fragment>
  );
}

export default MyPagePwd;
