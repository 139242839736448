import { Fragment, useEffect } from "react";
import { useState } from "react";
import { useSelector } from 'react-redux';
import moment from "moment";

import { DatePicker, Stack } from 'rsuite';
import 'rsuite/dist/datepicker.css';
import { dateFormation, isEmpty } from "../../../utils";

function Search(props) {
  const { pageInfo, getSearch, dateInput, setDateInput, searchOption } = props;
  const { noDate, noTodayPicker, noSearchInput } = props;

  const timeOptionReset = useSelector(state => state.page.timeOptionReset);
  // const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    searchtext: "",
    searchoption: isEmpty(searchOption) ? "loginid" : searchOption[0].value
  });

  const { searchtext, searchoption } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value
    });
  }

  // -------------- 날짜선택 ---------------- //
  // const { starttime, endtime } = dateInput;
  const starttime = isEmpty(dateInput) ? dateFormation(new Date(), 3) : dateInput.starttime;
  const endtime = isEmpty(dateInput) ? dateFormation(new Date(), 3) : dateInput.endtime;
  const onPickDate = (name, date) => {
    setDateInput({
      ...dateInput,
      [name]: dateFormation(date, 3)
    });
  }

  useEffect(() => {
    if (timeOptionReset) {
      setDateInput({
        starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
        endtime: dateFormation(new Date(), 3)
      });
    }
  }, [timeOptionReset])

  // const clearDate = (name) => {
  //   setDateInput({
  //     ...dateInput,
  //     [name]: ""
  //   })
  // }
  // ---------------get bankList-----------------//

  // --------------- 특정 일자 검색 선택 --------------------- //
  const onToday = () => {
    const starttime = new Date();
    const parmas = {
      starttime: dateFormation(starttime, 3),
      endtime: dateFormation(new Date(), 3)
    }
    onDaySearch(parmas);
  }

  const onThisWeek = () => {
    const parmas = {
      starttime: moment(new Date()).utc().startOf("week").format("YYYY-MM-DD"),
      endtime: dateFormation(new Date(), 3)
    }
    onDaySearch(parmas);
  }

  const onThisMonth = () => {
    const parmas = {
      starttime: moment(new Date()).utc().startOf("month").format("YYYY-MM-DD"),
      endtime: dateFormation(new Date(), 3)
    }
    onDaySearch(parmas);
  }

  const onDaySearch = (params) => {
    setDateInput(params);
    getSearch(1, pageInfo.perPage, params);
  }
  // --------------- 검색 일자 선택 End------------------------------ //

  const onSearch = () => {
    const parmas = {
      ...inputs,
      ...dateInput
    }
    getSearch(1, pageInfo.perPage, parmas);
  }

  const onEnter = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  }

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body" style={{ padding: "10px 1rem" }}>
          <div className="d-flex align-items-center w-100">
            <div className={`d-flex  ${!noDate ? "justify-content-between- justify-content-start" : "justify-content-start"} w-100 flex-wrap `}>
              {!noDate &&
                <div className="d-flex">
                  <label className="line-h-29 mr-2  col-form-label" style={{ whiteSpace: "nowrap" }}>
                    검색일자
                  </label>
                  <div className="d-flex align-items-center">
                    <div className="input-daterange input-group flex-nowrap" id="date-range">
                      <Stack spacing={10} direction="column" alignItems="flex-start">
                        <DatePicker
                          style={{ width: 110 }}
                          value={new Date(starttime)}
                          format="yyyy-MM-dd"
                          name="starttime"
                          onOk={(starttime) => onPickDate("starttime", starttime)}
                          // onClean={() => clearDate("starttime")}
                          showMeridian />
                      </Stack>
                      <span className="input-group-addon b-0 text-dark mr-2 ml-2">~</span>
                      <Stack spacing={10} direction="column" alignItems="flex-start">
                        <DatePicker
                          style={{ width: 110 }}
                          value={new Date(endtime)}
                          format="yyyy-MM-dd"
                          name="endtime"
                          onOk={(endtime) => onPickDate("endtime", endtime)}
                          // onClean={() => clearDate("endtime")} 
                          showMeridian />
                      </Stack>
                    </div>
                  </div>
                </div>}
              <div className="d-flex align-items-center mr-2">
                {
                  !noTodayPicker && (
                    <Fragment>
                      <label type="button" className="btn-sm btn-info day-search-btn mt-1 mb-1 mr-1 ml-1" onClick={onToday}>오늘</label>
                      <label type="button" className="btn-sm btn-info day-search-btn mt-1 mb-1 mr-1 ml-1" onClick={onThisWeek}>이번주</label>
                      <label type="button" className="btn-sm btn-info day-search-btn mt-1 mb-1 mr-1 ml-1" onClick={onThisMonth}>이번달</label>
                    </Fragment>
                  )
                }
              </div>
              {
                !noSearchInput &&
                <Fragment>
                  <div className="d-flex align-items-center mr-2">
                    <label className="line-h-29 mr-2  col-form-label" style={{ whiteSpace: "nowrap" }}>검색타입</label>
                    <div className=" ">
                      <select className="form-control padding-top-0 padding-bottom-0" id="searchoption" name="searchoption" value={searchoption} onChange={onChange}>
                        {
                          !isEmpty(searchOption) ?
                            searchOption.map((option, index) => {
                              return (<option value={option.value} key={index}>{option.name}</option>)
                            })
                            :
                            <Fragment>
                              <option value="loginid">아이디</option>
                              <option value="username">닉네임</option>
                            </Fragment>
                        }
                      </select>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mr-2">
                    <label className="line-h-29 mr-2  col-form-label" style={{ whiteSpace: "nowrap" }}>검색어</label>
                    <div className="">
                      <div className="input-group icons">
                        <input
                          type="search"
                          className="form-control"
                          id="searchtext"
                          placeholder="검색"
                          name="searchtext"
                          value={searchtext}
                          onChange={onChange}
                          onKeyDown={onEnter} />
                      </div>
                    </div>
                  </div>

                </Fragment>
              }
              <div className="d-flex align-items-center">
                <button type="button" className="btn btn-primary search-btn ml-1" onClick={onSearch}>
                  <i className="mdi mdi-magnify mr-2"></i>
                    검색
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
