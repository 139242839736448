import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { request } from '../api';
import { history } from '../helpers/history';

import { isEmpty, messageMusic, toastr } from '../utils';
import { api_url } from '../utils/keys';
import setAuthToken from '../utils/setAuthToken';
import { socket } from '../utils/socket';

// create slice

const name = 'user';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const userReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        isAuthenticated: false,
        data: {},
        bankList: [],
        headInfo: {}
    }
}

function createReducers() {
    return {
        setUserInfo,
        setBankList,
        setHeadInfo,
    };

    function setUserInfo(state, action) {
        state.data = action.payload;
        state.isAuthenticated = !isEmpty(action.payload);
    }

    function setBankList(state, action) {
        state.bankList = action.payload;
    }

    function setHeadInfo(state, action) {
        state.headInfo = action.payload;
    }
}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        loginUser: loginUser(),
        logout: logout(),
        getUserInfo: getUserInfo(),
        getBankList: getBankList(),
        getHeadInfo: getHeadInfo(),
    };

    function loginUser() {
        return createAsyncThunk(
            `${name}/loginUser`,
            async function (params, { dispatch }) {
                messageMusic.stop();
                try {
                    request.post(api_url + "/api/auth/login", params)
                        .then(data => {
                            toastr.success(data.message);

                            setAuthToken(data.userData.token);
                            localStorage.setItem("jwtToken", data.userData.token)
                            dispatch(userActions.setUserInfo(data.userData));
                            const socketParams = {
                                id: data.userData.id,
                                token: data.userData.token
                            }
                            socket.emit("me", socketParams)
                            history.navigate("/");
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }


    function getUserInfo() {
        return createAsyncThunk(
            `${name}/getuserinfo`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/auth/userData")
                        .then(data => {
                            dispatch(userActions.setUserInfo(data.userData));
                            const socketParams = {
                                id: data.userData.id,
                                token: localStorage.getItem("jwtToken")
                            }
                            socket.emit("me", socketParams)
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getBankList() {
        return createAsyncThunk(
            `${name}/getBankList`,
            async function (arg, { dispatch }) {
                try {
                    request.post(api_url + "/api/user/getbank")
                        .then(data => {
                            dispatch(userActions.setBankList(data.data[0]));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getHeadInfo() {
        return createAsyncThunk(
            `${name}/getHeadInfo`,
            async function (arg, { dispatch }) {
                try {
                    request.post(api_url + "/api/user/get_head_info")
                        .then(data => {
                            dispatch(userActions.setHeadInfo(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function logout() {
        return createAsyncThunk(
            `${name}/logout`,
            async function (arg, { getState, dispatch }) {

                const params = {
                    token: localStorage.getItem("jwtToken"), 
                    ipADD: localStorage.getItem("client-ip")
                }
                if (getState().user.isAuthenticated) {
                    toastr.warning("로그아웃 되었습니다.");
                    axios.post(api_url + "/api/auth/logout", params)
                    .catch(error => {
                        console.error(error);
                    })
                }
                localStorage.removeItem("jwtToken");
                dispatch(userActions.setUserInfo({}));
                setAuthToken(false);
            }
        );
    }
}