import React, { useState } from "react";
import { Fragment } from "react";
import { dateFormation, getAgentLevel, getLocalstringNumber, getStatus, getStatusColorClassName, getStatusTextColorClassName, isEmpty } from "../../../utils";
import DateTd from "../../common/table/DateTd";

const AgentTreeNode = (props) => {
  const { data, getChildren, activeAgent, setActiveAgent, level } = props;
  const [agentData, setAgentData] = useState(data);
  const [active, setActive] = useState(false);

  const getMyChildren = (parent_loginid) => {
    
    getChildren(parent_loginid, "false").then(subChildren => {
      if (!isEmpty(subChildren)) {
        setAgentData({
          ...agentData,
          children: subChildren
        });
      }
    });
  }
  const onClickAgent = () => {
    setActive(!active);
    if (Number(data.agent_type > -1)) {
      setActiveAgent(data);
    }
    if ((Number(data.user_count) > 0) && (!active)) {
      getMyChildren(data.loginid);
    }
  }

  const setClassName = (value) => {
    switch(value) {
      case 3:
        return "btn-info"
        break;
      case 2:
          return "btn-secondary"
          break;
      case 1:
          return "btn-success"
           break;
      case -1:
          return "btn-warning"
            break;
    }
  }

  return (
    <Fragment>
      <tr>
        <td style={{textAlign: "start"}} onClick={onClickAgent}>
          <span className="el-table__indent" style={{ paddingLeft: 16 * (level - 1) }}></span>
          {(Number(data.user_count) > 0) ?
            <i className={` fa ${active ? "fa-minus-square" : "fa-plus-square"} margin-right-5`}></i>
            :
            <i className=" margin-right-5" style={{ width: "50px" }}>&ensp;</i>
          }
          <button type="button" className={`btn mb-1 btn-sm ${getStatusColorClassName(data.agent_type)}`} style={{marginRight: "15px"}}><i className="fa fa-user-o"></i></button>
            {data.loginid}
        </td>
        <td style={{textAlign: "center"}}>{data.parent_loginid}</td>
        <td style={{textAlign: "center"}}>{data.username}</td>
        <td style={{textAlign: "center"}}>{data.partner_code}</td>
        <td style={{textAlign: "center"}}><span className={getStatusTextColorClassName(data.agent_type)} disabled="disabled">{getAgentLevel(data.agent_type)}</span></td>
        <td className="white-space-nowrap" style={{textAlign: "center"}}>{data.bankname}</td>
        <td style={{textAlign: "center"}}>{data.accountnumber}</td>
        <td style={{textAlign: "center"}}>{data.mastername}</td>
        <td style={{textAlign: "center"}}><span className={getStatusTextColorClassName(data.status)}>{getStatus(data.status)}</span></td>
        <td style={{textAlign: "end"}}>{getLocalstringNumber(data.money)}</td>
        <td style={{textAlign: "end"}}>{getLocalstringNumber(data.rolling)}</td>
        <DateTd date={data.created_at}/>
        </tr>
      {
        (!isEmpty(agentData.children) && active) && agentData.children.map((agentItem, index) => {
          return (<AgentTreeNode
            data={agentItem}
            level={level + 1}
            activeAgent={activeAgent}
            setActiveAgent={setActiveAgent}
            getChildren={getChildren}
            key={index} />)
        })
      }
    </Fragment>
  );
};

export default AgentTreeNode;
