import { createSlice } from '@reduxjs/toolkit';
import { dateFormation } from '../utils';

// create slice

const name = 'page';
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

// exports

export const pageActions = { ...slice.actions };
export const pageReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        totalPage: 1,
        perPage: 1,
        curPage: 1,
        timeOptionReset: false,
    }
}

function createReducers() {
    return {
        setPage,
        setPerPage,
        setCurPage,
        setTimeOptionReset,
    };

    function setPage(state, action) {
        state.totalPage = action.payload.totalPage;
    }

    function setCurPage(state, action) {
        state.curPage = action.payload;
    }

    function setPerPage(state, action) {
        state.perPage = action.payload;
    }

    function setTimeOptionReset(state, action) {
        state.timeOptionReset = action.payload;
    }
}