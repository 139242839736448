import { configureStore } from '@reduxjs/toolkit';

import { userReducer } from './user.slice';
import { usersReducer } from './users.slice';
import { agentReducer } from './agent.slice';
import { pageReducer } from './page.slice';
import { moneyReducer } from './money.slice';
import { siteReducer } from './site.slice';
import { gameReducer } from './game.slice';

export * from './user.slice';
export * from './users.slice';
export * from './agent.slice';
export * from './page.slice';
export * from './money.slice';
export * from './site.slice';
export * from './game.slice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        users: usersReducer,
        agent: agentReducer,
        page: pageReducer,
        money: moneyReducer,
        site: siteReducer,
        game: gameReducer,
    },
});