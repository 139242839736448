import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { api_url } from "../../../utils/keys";
import { request } from "../../../api";
import { agentActions, userActions } from "../../../store";
import { isEmpty, dateFormation, getAgentLevel, toastr, getLocalstringNumber, getStatusTextColorClassName } from '../../../utils';
import Pagenation from "../../common/pagenation/pagenation";
// import TableTitle from "../../common/TableTitle";
import Search from "../../common/search/Search";
import MoneyModal from "../../common/modal/MoneyModal";
import EditModal from "../../common/modal/EditModal";
import DeleteModal from "../../common/modal/DeleteModal";
import ChangePasswordModal from "../../common/modal/ChangePasswordModal";
import PointModal from "../../common/modal/PointModal";
import DateTd from "../../common/table/DateTd";
import Thead from "../../common/table/Thead";
import TableEmpty from "../../common/table/TableEmpty";
import Confirm from "../../common/modal/Confirm";
import ConvertToMoneyModel from "../../common/modal/ConvertToMoneyModel";
import MoneyModal_bonsa from "../../common/modal/MoneyModal_bonsa";
import PointModal_bonsa from "../../common/modal/PointModal_bonsa";



const theads = [
  {
    name: "번호",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap', width: "55px" }
  },
  {
    name: "아이디",
    value: "loginid",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "상위업체",
    value: "parent_name",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "닉네임",
    value: "username",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "추천코드",
    value: "partner_code",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "롤링퍼센트",
    value: "rolling_percent",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "업체등급",
    value: "agent_type",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "은행명",
    value: "bankname",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "계좌번호",
    value: "accountnumber",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "예금주",
    value: "mastername",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "계정상태",
    value: "status",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "보유금액",
    value: "money",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "보유포인트",
    value: "rolling",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "가입일자",
    value: "created_at",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "머니지급",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "포인트지급",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "포인트전환",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "업체관리",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  }
]

function AgentListContent() {
  const dispatch = useDispatch();
  const agentList = useSelector(state => state.agent.agentList);
  const userData = useSelector(state => state.user.data);

  // -----------------------------------------------
  const [sortParam, setSortParam] = useState({});
  const [searchParam, setSearchParam] = useState({});
  // -----------------------------------------------

  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });
  const onPageInfo = (param) => {
    // console.log('param: ', param);
    setPageInfo({
      ...pageInfo,
      ...param
    })
  }

  const [active, setActive] = useState(-1);

  const [moneySetInfo, setmoneySetInfo] = useState({
    type: "",
  });

  const [pointSetInfo, setPointSetInfo] = useState({
    type: "",
  });

  const [ConvertToMoneySetInfo, setConvertToMoneySetInfo] = useState({
    type: "",
  });
  
  const [showModal, setShowModal] = useState({
    moneySet: false,
    pointSet: false,
    bonsapointSet: false,
    bonsamoneySet: false,
    convertToMoneySet: false,
    create: false,
    edit: false,
    changePwd: false,
    delete: false,
    confirm: false
  });

  // -------------------- 시간 선택 -------------------- //
  const [dateInput, setDateInput] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  })

  // ------------------- 시간 선택 끝 ------------------ //

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const getList = (page = 1, perPage = 10, search = searchParam, sortParams = sortParam) => {
    setPageInfo({
      ...pageInfo,
      curPage: page,
      perPage: Number(perPage)
    });

    const params = {
      page: page,
      perPage: perPage,
      ...sortParams,
      ...search,
    }
    setSearchParam(search);
    setSortParam(sortParams);

    dispatch(agentActions.getAgent(params));
    setActive(-1);
  }

  useEffect(() => {
    getList();
    dispatch(userActions.getBankList());
    dispatch(agentActions.getParentAgentList());
  }, []);

  const onMoneySet = (type) => {
    let checkbonsa = type.split('%')
    if(checkbonsa[0] != "본사")
    {
      setmoneySetInfo({
        type: type
      });
      onShowModal("moneySet");
    }
    else {
      setmoneySetInfo({
        type: checkbonsa[1]
      });
      onShowModal("bonsamoneySet");
    }
  }

  const onPointSet = (type) => {
    let checkbonsa = type.split("%")
    if(checkbonsa[0] != "본사")
    {
      setPointSetInfo({
        type: type
      });
      onShowModal("pointSet");
    }
    else {
      setPointSetInfo({
        type: checkbonsa[1]
      });
      onShowModal("bonsapointSet");
    }
  }

  const onConvertToMoney = (type) => {
    setConvertToMoneySetInfo({
      type: type
    });
    onShowModal("convertToMoneySet");
  }

  const onChangeStatus = (status, loginid) => {
    return (
      request.post(api_url + "/api/enterprise/change_status", {
        loginid: loginid,
        status: Number(status) === 0 ? 1 : 0
      })
        .then(res => {
          toastr.success(res.message);
          getList(pageInfo.curPage, pageInfo.perPage);
          setShowModal("confirm");
        })
    )
  }


  // ------------------- Sort Start ----------------------- //
  const defaultSort = {
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    rolling: 0,
    created_at: 0,
  }
  const [sort, setSort] = useState({
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    rolling: 0,
    created_at: 0,
  })

  const onSort = (value) => {
    if (!isEmpty(value)) {
      const sortParams = {
        sortBy: value,
        sortOrder: (sort[value] === 0) ? "asc"
          :
          ((sort[value] === 1) ? "desc" : "asc")
      }
      getList(1, pageInfo.perPage, searchParam, sortParams);
      setSort({
        ...defaultSort,
        [value]: (sort[value] === 0) ? 1
          :
          ((sort[value] === 1) ? -1 : 1)
      });
    }
  }
  // ------------------- Sort End ----------------------- //

  const [confirmContent, setConfirmContent] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("")
  const onConfirm = (value) => {
    setConfirmStatus(value);
    onShowModal("confirm");
    switch (value) {
      case "status": {
        setConfirmContent("상태를 변경하시겠습니까?");
        break;
      }

      default:
        break;
    }
  }

  const onOk = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    onChangeStatus(agentList[active].status, agentList[active].loginid);
  }

  const onCancel = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    setShowModal("confirm");
  }

  return (
    <Fragment>
      <Search
        noDate={true}
        noTodayPicker={true}
        pageInfo={pageInfo}
        dateInput={dateInput}
        setDateInput={setDateInput}
        getSearch={getList} />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* <TableTitle title="업체목록" /> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                <div className="d-flex justify-content-end padding-bottom-15 ">
                  <div className="new-button">
                    {
                      (userData.agent_type > 1) &&
                      <button type="button"
                        className="btn btn-primary"
                        onClick={() => onShowModal("create")}>
                        <i className="icon-user-follow f-s-14 mr-1" aria-hidden="true"></i>
                          신규등록
                        </button>
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                    <table className="agent-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                      role="grid" aria-describedby="DataTables_Table_0_info">
                      <Thead theads={theads} sort={sort} onSort={onSort} />
                      <tbody>
                        {
                          agentList.map((agent, index) => {
                            return (
                              <tr role="row" className="odd" key={index} onClick={() => setActive(index)}>
                                <td style={{ textAlign: "center" }} className="sorting_1">{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                <td style={{ textAlign: "center" }} className="sorting_1">{agent.loginid}</td>
                                <td style={{ textAlign: "center" }}>{agent.parent_name}</td>
                                <td style={{ textAlign: "center" }}>{agent.username}</td>
                                <td style={{ textAlign: "center" }}>{agent.partner_code}</td>
                                <td style={{ textAlign: "end" }}>{agent.rolling_percent}%</td>

                                <td style={{ textAlign: "center", padding: "0px" }}>
                                  <span className={getStatusTextColorClassName(agent.agent_type)}>
                                    {getAgentLevel(agent.agent_type, agent.loginid)}
                                  </span>
                                </td>
                                <td className="white-space-nowrap" style={{ textAlign: "center" }}>{agent.bankname}</td>
                                <td style={{ textAlign: "center" }}>{agent.accountnumber}</td>
                                <td style={{ textAlign: "center" }}>{agent.mastername}</td>
                                <td style={{ textAlign: "center", padding: "0px" }}>

                                  {
                                    agent.loginid !== userData.loginid &&
                                    <label
                                      className={`${(Number(agent.status) === 1) ? "success-switch" : "danger-switch false"} switch-label`}
                                      data-on-label=""
                                      data-off-label=""
                                      // onClick={() => onChangeStatus(agent.status, agent.loginid)} 
                                      onClick={() => onConfirm("status")} >
                                    </label>
                                  }
                                  {/* <span
                                    className={getStatusColorClassName(agent.status) + " enable-hover cursor-pointer"}
                                    onClick={() => onChangeStatus(agent.status, agent.loginid)}>
                                    {getStatus(agent.status)}
                                  </span> */}
                                </td>
                                <td style={{ textAlign: "end" }}>{getLocalstringNumber(agent.money)}</td>
                                <td style={{ textAlign: "end" }}>{getLocalstringNumber(agent.rolling)}</td>
                                <DateTd date={agent.created_at} />
                                <td>
                                  {
                                    agent.loginid !== userData.loginid ?
                                    <div className="d-flex justify-content-center align-items-center">
                                      <button className="btn btn-success btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        title="강제충전"
                                        onClick={() => onMoneySet("충전")}>
                                        <i className="fa fa-arrow-circle-up"></i>
                                      </button>
                                      <button className="btn btn-primary btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        title="강제환전"
                                        onClick={() => onMoneySet("환전")}>
                                        <i className="fa fa-arrow-circle-down"></i>
                                      </button>
                                    </div>
                                    :
                                    <div className="d-flex justify-content-center align-items-center">
                                      <button className="btn btn-success btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        title="강제충전"
                                        onClick={() => onMoneySet("본사%충전")}>
                                        <i className="fa fa-arrow-circle-up"></i>
                                      </button>
                                      <button className="btn btn-primary btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        title="강제환전"
                                        onClick={() => onMoneySet("본사%환전")}>
                                        <i className="fa fa-arrow-circle-down"></i>
                                      </button>
                                    </div>
                                  }
                                </td>
                                <td>
                                  {
                                    agent.loginid !== userData.loginid ?
                                    <div className="d-flex justify-content-center align-items-center">
                                      <button className="btn btn-success btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        title="포인트지급"
                                        onClick={() => onPointSet("지급")}>
                                        <i className="fa fa-arrow-circle-up"></i>
                                      </button>
                                      <button className="btn btn-primary btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        title="본사포인트차감"
                                        onClick={() => onPointSet("차감")}>
                                        <i className="fa fa-arrow-circle-down"></i>
                                      </button>
                                    </div>
                                    :
                                    <div className="d-flex justify-content-center align-items-center">
                                      <button className="btn btn-success btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        title="포인트지급"
                                        onClick={() => onPointSet("본사%지급")}>
                                        <i className="fa fa-arrow-circle-up"></i>
                                      </button>
                                      <button className="btn btn-primary btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        title="본사포인트차감"
                                        onClick={() => onPointSet("본사%차감")}>
                                        <i className="fa fa-arrow-circle-down"></i>
                                      </button>
                                    </div>
                                  }
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center align-items-center">
                                      <button className="btn btn-success btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        title="포인트전환"
                                        onClick={() => onConvertToMoney("포인트전환")}>
                                        <i className="fa fa-play-circle"></i>
                                      </button>
                                  </div>
                                </td>
                                <td >
                                  <div className="d-flex justify-content-center align-items-center">
                                    {
                                      ((agent.loginid !== userData.loginid) || (userData.agent_type === 3)) &&
                                      <button className="btn btn-info btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        onClick={() => onShowModal("edit")}
                                        title="수정">
                                        <i className="fa fa-edit"></i>
                                      </button>
                                    }
                                    {
                                      agent.loginid !== userData.loginid &&
                                      <button className="btn btn-warning btn-sm mr-1"
                                        style={{ color: "#fff" }}
                                        onClick={() => onShowModal("changePwd")}
                                        title="비밀번호변경">
                                        <i className=" fa fa-key"></i>
                                      </button>
                                    }
                                    {
                                      ((userData.agent_type === 3) && (agent.loginid !== userData.loginid)) &&
                                      <button className="btn btn-danger btn-sm"
                                        style={{ color: "#fff" }}
                                        onClick={() => onShowModal("delete")}
                                        title="삭제">
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    }
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(agentList) ? ""
                        :
                        <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>

                <Pagenation
                  pageInfo={pageInfo}
                  setPageInfo={(params) => setPageInfo(params)}
                  onPageInfo={onPageInfo}
                  getList={getList} />

                {
                  showModal.moneySet &&
                  <MoneyModal
                    pageInfo={pageInfo}
                    moneySetInfo={moneySetInfo}
                    getList={getList}
                    data={agentList[active]}
                    url={moneySetInfo.type === "충전" ? "/api/money/force_charge" : "/api/money/force_withdraw"}
                    onClose={onShowModal} />
                }


                {
                  showModal.pointSet &&
                  <PointModal
                    pageInfo={pageInfo}
                    pointSetInfo={pointSetInfo}
                    getList={getList}
                    data={agentList[active]}
                    url={pointSetInfo.type === "지급" ? "/api/user/pay_rolling" : "/api/user/pay_rolling"}
                    onClose={onShowModal} />
                }

                {
                  (showModal.edit || showModal.create) &&
                  <EditModal
                    pageInfo={pageInfo}
                    edit={showModal.edit}
                    getList={getList}
                    data={((userData.agent_type === 3) && (active === -1)) ? userData : agentList[active]}
                    url="/api/enterprise/create"
                    checkurl="/api/enterprise/check_loginid"
                    onClose={onShowModal}
                    noTel={true}
                    noPassword={showModal.edit ? true : false}
                    noWithdrawPassword={true}
                    // noPartnerCode={showModal.edit ? false : true}
                    noStatus={true}
                    noRtp={true}
                    // noAgentType={((userData.agent_type === 3) && (active === -1)) ? true : false}
                    noAgentType={false}
                    name="업체" />
                }

                {
                  showModal.delete &&
                  <DeleteModal
                    pageInfo={pageInfo}
                    getList={getList}
                    data={agentList[active]}
                    onClose={onShowModal}
                    method="post"
                    url="/api/enterprise/delete" />
                }

                {
                  showModal.changePwd &&
                  <ChangePasswordModal
                    pageInfo={pageInfo}
                    getList={getList}
                    data={agentList[active]}
                    onClose={onShowModal}
                    method="post"
                    url="/api/enterprise/update_password" />
                }

                {
                  showModal.convertToMoneySet && 
                  <ConvertToMoneyModel
                    pageInfo={pageInfo}
                    ConvertToMoneySetInfo={ConvertToMoneySetInfo}
                    getList={getList}
                    data={agentList[active]}
                    url={ConvertToMoneySetInfo.type === "포인트전환" ? "/api/user/convertToMoney" : ""}
                    onClose={onShowModal} />
                }

                {
                  showModal.bonsamoneySet &&
                  <MoneyModal_bonsa
                    pageInfo={pageInfo}
                    moneySetInfo={moneySetInfo}
                    getList={getList}
                    data={agentList[active]}
                    url={moneySetInfo.type === "충전" ? "/api/money/force_charge" : "/api/money/force_withdraw"}
                    onClose={onShowModal} />
                }
                
                {
                  showModal.bonsapointSet &&
                  <PointModal_bonsa
                    pageInfo={pageInfo}
                    pointSetInfo={pointSetInfo}
                    getList={getList}
                    data={agentList[active]}
                    url={pointSetInfo.type === "지급" ? "/api/user/pay_rolling" : "/api/user/pay_rolling"}
                    onClose={onShowModal} />
                }

                {
                  showModal.confirm &&
                  <Confirm
                    content={confirmContent}
                    onOk={onOk}
                    onClose={onCancel} />
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AgentListContent;
