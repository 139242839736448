import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { request } from "../../../api";
import { isEmpty, toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";
import Modalback from "./Modalback";

import QuillEditor from "react-quill";

// Importing styles
import "react-quill/dist/quill.snow.css";
import Confirm from "./Confirm";

function NoticeModal(props) {
  const { name, onClose, getList, url, method, data, type, sendId, edit, pageInfo } = props;
  const editorRef = useRef();

  const [inputs, setInputs] = useState({
    title: edit ? data.title : "",
    content: edit ? data.content : "",
  });

  const { title, content } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value
    })
  }

  const onSubmit = () => {
    onShowModal("confirm");
    if (isEmpty(title)) {
      return toastr.warning("제목을 입력해주세요.")
    }
    if (isEmpty(content)) {
      return toastr.warning("내용을 입력해주세요.")
    }

    const paramdata = {
      uid: !isEmpty(data) ? data[sendId] : "",
      ...inputs
    }

    const params = paramdata;

    request[!method ? "post" : method](api_url + url, params)
      .then(res => {
        toastr.success(res.message);
        getList(edit ? pageInfo.curPage : 1, pageInfo.perPage);
        onCloseModal()
      })
  }

  const onCloseModal = () => {
    onClose(edit ? "edit" : "create")
  }

  useEffect(() => {

  }, []);

  // ----------------- Quill Editor -----------------
  // Editor ref
  const quill = useRef();

  const imageHandler = useCallback(() => {
    // Create an input element of type 'file'
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // When a file is selected
    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();

      // Read the selected file as a data URL
      reader.onload = () => {
        const imageUrl = reader.result;
        const quillEditor = quill.current.getEditor();

        // Get the current selection range and insert the image at that index
        const range = quillEditor.getSelection(true);
        quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
      };

      reader.readAsDataURL(file);
    };
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [imageHandler]
  );

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "clean",
  ];

  // ------------------------------------------------- //

  const [showModal, setShowModal] = useState({
    confirm: false
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const [confirmContent, setConfirmContent] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("")
  const onConfirm = (value) => {
    setConfirmStatus(value);
    onShowModal("confirm");
    setConfirmContent(`공지를 ${type === "수정" ? "수정" : "등록"}하시겠습니까?`);
  }

  const onOk = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    onSubmit();
  }

  const onCancel = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    setShowModal("confirm");
  }


  return (
    <div className="modal fade sendMessageModal show" tabIndex="-1" role="dialog" style={{ display: "block" }} aria-hidden="true">
      <Modalback onClose={onCloseModal} />
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{name}</h4>
            <button type="button" className="close" data-dismiss="modal" onClick={onCloseModal}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ color: "#000" }}>
            <form className="row d-block">
              <div className="col">
                <label htmlFor="title" className="col-form-label">제목:</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  value={title}
                  onChange={onChange} />
              </div>
              <div className="col">
                <label htmlFor="messageContent" className="col-form-label">내용:</label>
                <div style={{ maxHeight: "calc(100vh - 350px)", height: "auto", overflow: "auto" }}>
                  <QuillEditor
                    ref={(el) => (quill.current = el)}
                    className={"form-control"}
                    style={{ minHeight: "300px", height: "auto" }}
                    theme="snow"
                    value={content}
                    formats={formats}
                    modules={modules}
                    onChange={(value) => setInputs({ ...inputs, content: value })}
                  />
                </div>
              </div>
            </form>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={onCloseModal}>취소</button>
            <button type="button" className="btn btn-primary" onClick={onConfirm}>{type}</button>
          </div>
        </div>
      </div>

      {
        showModal.confirm &&
        <Confirm
          content={confirmContent}
          onOk={onOk}
          onClose={onCancel} />
      }
    </div>
  );
}

export default NoticeModal;
