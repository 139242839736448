import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AgentTreeNode from "./AgentTreeNode";
import { isEmpty, toastr } from "../../../utils";
import { request } from "../../../api";
import { api_url } from "../../../utils/keys";
import Thead from "../../common/table/Thead";
import TableEmpty from "../../common/table/TableEmpty";

const theads = [
  {
    name: "아이디",
    value: "",
    sort: false,
    style: {
      textAlign: "center",
      minWidth: "200px"
    }
  },
  {
    name: "상위업체",
    value: "",
    sort: false,
    style: {
      textAlign: "center",
    }
  },
  {
    name: "닉네임",
    value: "",
    sort: false,
    style: {
      textAlign: "center",
    }
  },
  {
    name: "추천코드",
    value: "",
    sort: false,
    style: {
      textAlign: "center",
    }
  },
  {
    name: "업체등급",
    value: "",
    sort: false,
    style: {
      textAlign: "center",
    }
  },
  {
    name: "은행명",
    value: "",
    sort: false,
    style: {
      textAlign: "center",
    }
  },
  {
    name: "계좌번호",
    value: "",
    sort: false,
    style: {
      textAlign: "center",
    }
  },
  {
    name: "예금주",
    value: "",
    sort: false,
    style: {
      textAlign: "center",
    }
  },
  {
    name: "계정상태",
    value: "",
    sort: false,
    style: {
      textAlign: "center",
    }
  },
  {
    name: "보유금액",
    value: "",
    sort: false,
    style: {
      textAlign: "center",
    }
  },
  {
    name: "보유포인트",
    value: "",
    sort: false,
    style: {
      textAlign: "center",
    }
  },
  {
    name: "가입일자",
    value: "",
    sort: false,
    style: {
      textAlign: "center",
    }
  }
]

const AgentTree = () => {
  const { loginid } = useSelector((state) => state.user.data);
  const [bonsas, setBonsas] = useState([]);
  const [activeAgent, setActiveAgent] = useState({});
  const dispatch = useDispatch();

  const getChildren = (loginid, isSearch) => {
    const params = {
      loginid: loginid,
      isSearch: isSearch
    }
    return new Promise(resolve => {
      request.post(api_url + "/api/enterprise/treelist", {
        loginid: loginid,
        isSearch: isSearch
      }).then(res => {
        resolve(res.data);
      }).catch((err) => {
        console.log(err)
      })
    });
  }

  useEffect(() => {
    if (!isEmpty(loginid)) {
      getChildren(loginid, "true")
        .then(myChildren => {
          setBonsas(myChildren);
          setActiveAgent(myChildren[0])
        });
    }
  }, [loginid]);


  // ------------------- Sort Start ----------------------- //
  const [sort, setSort] = useState({
    loginid: 0,
    username: 0,
  })

  const onSort = (value) => {
  }
  // ------------------- Sort End ----------------------- //

  return (
    <Fragment>
      <div className="col-12">
        <div className="card padding-top-15">
          <div className="card-body padding-top-15 padding-bottom-15">
            {/* <h4 style={{ marginLeft: "25px" }} className="card-title">&ensp;
                  업체트리
                  </h4> */}
            <div className=" overflow-auto padding-top-15 padding-bottom-15">
              <table className="agent-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                <Thead theads={theads} sort={sort} onSort={onSort} />
                <tbody>
                  {
                    bonsas.map((bonsaItem, index) => {
                      return <AgentTreeNode
                        data={bonsaItem}
                        level={1}
                        activeAgent={activeAgent}
                        setActiveAgent={setActiveAgent}
                        getChildren={getChildren}
                        key={index} />
                    })
                  }
                </tbody>
              </table>
              {
                !isEmpty(bonsas) ? ""
                  :
                  <TableEmpty colSpan={theads.length} />
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AgentTree;
