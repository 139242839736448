import { Fragment } from "react";
import { isEmpty } from "../../../utils";

function BankInfo(props) {
  const { data } = props;

  return (
      <Fragment>
    <td style={{ textAlign: "center" }}>
      { data.bankname }
    </td>
    <td style={{ textAlign: "center" }}>
    { data.accountnumber }
    </td>
    <td style={{ textAlign: "center" }}>
    { data.mastername }
    </td>
        </Fragment>
  );
}

export default BankInfo;
