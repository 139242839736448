import { useState } from "react";
import { request } from "../../../api";
import { isEmpty, toastr, no_comma, getLocalstringNumber } from "../../../utils";
import { api_url } from "../../../utils/keys";
import Confirm from "./Confirm";

import Modalback from "./Modalback";

function MoneyModal(props) {
  const { moneySetInfo, getList, data, url, onClose, sendId, pageInfo } = props;
  const { type } = moneySetInfo;

  const [inputs, setInputs] = useState({
    amount: 0
  });

  const { amount } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target
    setInputs({
      ...inputs,
      [name]: no_comma(value)
    })
  }

  const onSetMoney = () => {
    onShowModal("confirm");
    if (isEmpty(amount) || (amount <= 0)) {
      return toastr.warning(type + "금액을 입력해주세요.")
    }
    if (!isEmpty(data)) {
      const params = {
        bonsa: false,
        loginid: data.loginid,
        amount: amount
      }
      request.post(api_url + url, params)
        .then(res => {
          toastr.success(res.message);
          getList(pageInfo.curPage, pageInfo.perPage);
          onCloseModal();
        })
    }
  }

  const onCloseModal = () => {
    onClose("moneySet")
  }

  // ------------------------------------------------- //
  
  const [showModal, setShowModal] = useState({
    confirm: false
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const [confirmContent, setConfirmContent] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("")
  const onConfirm = (value) => {
    setConfirmStatus(value);
    onShowModal("confirm");
    setConfirmContent(`머니를 ${type}을 하시겠습니까?`);
  }

  const onOk = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    onSetMoney();
  }

  const onCancel = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    setShowModal("confirm");
  }

  return (
    <div className="modal fade depositModal show" tabIndex="-1" role="dialog" style={{ display: "block" }} aria-hidden="true">
      <Modalback onClose={onCloseModal} />
      <div className="modal-dialog modal-lg" style={{ width: "350px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">강제{type}</h5>
            <button type="button" className="close" data-dismiss="modal" onClick={onCloseModal}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ padding: "30px" }}>
            <form className="row d-block">
              <div className="col">
                <label htmlFor="password" className="col-form-label">아이디:</label>
                <input
                  type="text"
                  className="form-control"
                  value={data.loginid}
                  disabled />
              </div>
              <div className="col">
                <label htmlFor="password" className="col-form-label">보유머니:</label>
                <input
                  type="text"
                  className="form-control"
                  value={getLocalstringNumber(data.money)}
                  disabled />
              </div>
              <div className="col">
                <label htmlFor="passwordConfirm" className="col-form-label">{type}금액:</label>
                <input
                  type="text"
                  className="form-control"
                  name="amount"
                  value={((Number(amount) === 0) || isNaN(amount)) ? "" : getLocalstringNumber(amount)}
                  onChange={onChange} />
              </div>
            </form>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={onCloseModal}>취소</button>
            <button type="button" className="btn btn-primary" onClick={() => onConfirm()}>{type}</button>
          </div>
        </div>
      </div>

      {
        showModal.confirm &&
        <Confirm
          content={confirmContent}
          onOk={onOk}
          onClose={onCancel} />
      }
    </div>
  );
}

export default MoneyModal;
