import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { request } from "../../api";
import { siteActions } from "../../store";
import { dateFormation, getLocalstringNumber, getPositiveClassName, isEmpty, no_comma, toastr } from "../../utils";
import { api_url } from "../../utils/keys";
import Confirm from "../common/modal/Confirm";
import DeleteModal from "../common/modal/DeleteModal";
import Pagenation from "../common/pagenation/pagenation";
// import Search from "../common/search/Search";
import DateTd from "../common/table/DateTd";
import TableEmpty from "../common/table/TableEmpty";
import Thead from "../common/table/Thead";
// import TableTitle from "../common/TableTitle";

const theads = [
  {
    name: "번호",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap", width: "55px" }
  },
  {
    name: "일자",
    value: "created_at",
    sort: true,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "구분",
    value: "money_type",
    sort: true,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "이전금액",
    value: "prev_money",
    sort: true,
    style: { textAlign: "center", whiteSpace: "nowrap", width: "150px" }
  },
  {
    name: "금액",
    value: "amount",
    sort: true,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "보유금액",
    value: "money",
    sort: true,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  // {
  //   name: "관리",
  //   value: "",
  //   sort: false,
  //   style: { textAlign: "center", whiteSpace: "nowrap", width: "90px" }
  // },
]

function AlManageContent() {
  const dispatch = useDispatch();
  const alManageList = useSelector(state => state.site.alManageList);

  // -----------------------------------------------
  const [sortParam, setSortParam] = useState({});
  const [searchParam, setSearchParam] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  });
  // -----------------------------------------------

  const [active, setActive] = useState(-1);
  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });

  // -------------------- 시간 선택 -------------------- //
  // const [dateInput, setDateInput] = useState({
  //   starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
  //   endtime: dateFormation(Date.now(), 3)
  // })

  // ------------------- 시간 선택 끝 ------------------ //

  // -------------------- 알, 머니 선택 -------------------- //
  const [inputs, setInputs] = useState({
    searchtext: "all",
    searchoption: "",
    amount: 0
  });

  const { searchtext, amount } = inputs;
  // ------------------- 알, 머니 선택 끝 ------------------ //
  const onChangeAmount = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: isNaN(no_comma(value)) ? 0 : Number(no_comma(value))
    });
  }

  const [showModal, setShowModal] = useState({
    delete: false
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const getList = (page = 1, perPage = 10, search = searchParam, sortParams = sortParam) => {
    setPageInfo({
      ...pageInfo,
      curPage: page,
      perPage: Number(perPage)
    });

    const params = {
      page: page,
      perPage: perPage,
      ...search,
      ...sortParams
    }
    setSearchParam(search);
    setSortParam(sortParams);

    dispatch(siteActions.getAlManageList(params));
    setActive(-1);
  }

  useEffect(() => {
    getList();
  }, []);


  // ------------------- Sort Start ----------------------- //
  const defaultSort = {
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    prev_money: 0,
    amount: 0,
    rolling: 0,
    created_at: 0,
    updated_at: 0,
    memo: 0,
    description: 0,
    service_loginid: 0,
    game_code: 0,
    type: 0,
    round_id: 0,
    game_type: 0,
    provider_code: 0,
    title: 0,
    content: 0,
    is_read: 0,
    answer: 0,
    answered_at: 0,
    money_type: 0
  }
  const [sort, setSort] = useState({
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    amount: 0,
    prev_money: 0,
    rolling: 0,
    created_at: 0,
    updated_at: 0,
    memo: 0,
    description: 0,
    service_loginid: 0,
    game_code: 0,
    type: 0,
    round_id: 0,
    game_type: 0,
    provider_code: 0,
    title: 0,
    content: 0,
    is_read: 0,
    answer: 0,
    answered_at: 0,
    money_type: 0
  });

  const onSort = (value) => {
    if (!isEmpty(value)) {
      const sortParams = {
        sortBy: value,
        sortOrder: (sort[value] === 0) ? "asc"
          :
          ((sort[value] === 1) ? "desc" : "asc")
      }
      getList(1, pageInfo.perPage, searchParam, sortParams);
      setSort({
        ...defaultSort,
        [value]: (sort[value] === 0) ? 1
          :
          ((sort[value] === 1) ? -1 : 1)
      });
    }
  }
  // ------------------- Sort End ----------------------- //

  // -------------------- 알, 머니 선택 인풋관리-------------------- //
  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value
    });

    getList(1, pageInfo.perPage, { ...inputs, [name]: value }, sort)
  }

  // ------------------- 알, 머니 선택 인풋관리 끝 ------------------ //

  // -------------------- 알생성, 소멸-------------------- //
  const onAl = (type) => {
    if (amount <= 0) {
      return toastr.warning("알을 입력해주세요.")
    }
    const params = {
      money_type: type,
      amount: amount
    }

    request.post(api_url + "/api/site/money_manage", params)
      .then(res => {
        toastr.success(res.message);
        getList(pageInfo.curPage, pageInfo.perPage);
        setInputs({ ...inputs, amount: 0 });
      })
  }

  // ------------------- 알생성, 소멸 끝 ------------------ //

  const [confirmContent, setConfirmContent] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("")
  const onConfirm = (value) => {
    setConfirmStatus(value);
    onShowModal("confirm");
    // console.log('value: ', value);
    switch (value) {
      case 11: {
        setConfirmContent("알생성을 하시겠습니까?");
        break;
      }

      case -11: {
        setConfirmContent("알소멸을 하시겠습니까?");
        break;
      }

      default:
        break;
    }
  }

  const onOk = () => {
    setConfirmStatus("");
    onShowModal("confirm");
    onAl(confirmStatus);
    // switch (confirmStatus) {
    //   case 11: {
    //     onAl(11);
    //     break;
    //   }

    //   case -11: {
    //     onAl(-11);
    //     break;
    //   }

    //   default:
    //     break;
    // }
  }

  const onCancel = () => {
    setConfirmStatus("");
    setShowModal("confirm");
  }

  return (
    <Fragment>
      {/* <Search
        pageInfo={pageInfo}
        dateInput={dateInput}
        setDateInput={setDateInput}
        getSearch={getList} /> */}

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* <TableTitle title="알관리" /> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                <div className="row justify-content-end padding-bottom-15">
                  <div className="">

                  </div>
                </div>
                <div className="row">
                  <div className="padding-left-15 margin-bottom-15 d-flex flex-wrap total-info-buttons">
                    <div className="d-flex padding-bottom-5">
                      {/* <select className="form-control mr-3" name="searchtext" value={searchtext}>
                          <option value="all">전체</option>
                          <option value="normal">알</option>
                          <option value="stop">포인트</option>
                        </select> */}
                      <span className="d-flex align-items-center mr-1 white-space-nowrap">머니타입</span>
                      <select className="form-control mr-3" name="searchtext" value={searchtext} onChange={onChange}>
                        <option value="all">전체</option>
                        <option value="add_money">알생성</option>
                        <option value="sub_money">알소멸</option>
                      </select>
                    </div>
                    <div className="d-flex flex-nowrap mr-3 padding-bottom-5">
                      <div className="input-group icons">
                        <input type="text"
                          className="form-control mr-2"
                          placeholder="0"
                          name="amount"
                          value={((Number(amount) === 0) || isNaN(amount)) ? "" : Number(amount).toLocaleString()}
                          onChange={onChangeAmount} />
                        <button type="button" className="btn btn-success search-btn sm mr-1" onClick={() => onConfirm(11)}>알생성</button>
                        <button type="button" className="btn btn-danger search-btn sm" onClick={() => onConfirm(-11)}>알소멸</button>
                      </div>
                    </div>
                    {/* <div className="d-flex flex-nowrap mr-3 padding-bottom-5">
                        <div className="input-group icons">
                          <input type="search" className="form-control mr-2" placeholder="0"/>
                          <button type="button" className="btn btn-success search-btn sm mr-1">포인트생성</button>
                          <button type="button" className="btn btn-danger search-btn sm">포인트소멸</button>
                        </div>
                      </div> */}
                  </div>
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                    <table className="user-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                      role="grid" aria-describedby="DataTables_Table_0_info">
                      <Thead theads={theads} sort={sort} onSort={onSort} />
                      <tbody>
                        {
                          alManageList.map((item, index) => {
                            return (
                              <tr role="row" className="odd" key={index} onClick={() => setActive(index)}>
                                <td className="sorting_1" style={{ textAlign: "center" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                <DateTd date={item.created_at} />
                                <td style={{ textAlign: "center" }} className={getPositiveClassName(item.money_type)}>{item.money_type === 11 ? "알생성" : "알소멸"}</td>
                                <td style={{ textAlign: "end" }} className={getPositiveClassName(item.money_type)}>{getLocalstringNumber(item.prev_money)}</td>
                                <td style={{ textAlign: "end" }} className={getPositiveClassName(item.money_type)}>{getLocalstringNumber(item.amount)}</td>
                                <td style={{ textAlign: "end" }} className={getPositiveClassName(item.money_type)}>{getLocalstringNumber(item.money)}</td>
                                {/* <td style={{ textAlign: "center" }}>
                                  <span>
                                    <span
                                      class="label mr-1 label-danger cursor-pointer"
                                      onClick={() => onShowModal("delete")}
                                    >삭제</span>
                                  </span>
                                </td> */}
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(alManageList) ? ""
                        :
                        <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>
                <Pagenation
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo}
                  getList={getList} />

                {
                  showModal.delete &&
                  <DeleteModal
                    pageInfo={pageInfo}
                    getList={getList}
                    data={alManageList[active]}
                    onClose={onShowModal}
                    method="post"
                    url="/api/site/del_al_hist" />
                }

                {
                  showModal.confirm &&
                  <Confirm
                    content={confirmContent}
                    onOk={onOk}
                    onClose={onCancel} />
                }

                {/* {
                  showModal.message &&
                  <MessageModal
                    getList={getList}
                    data={messageList[active]}
                    onClose={onShowModal}
                    method="put"
                    url="/api/site/update_message"
                    type="수정"
                    sendId="uid" />
                } */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AlManageContent;
