import { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

function Layout({ children, parentName }) {
  const [showSidebar, setShowSidebar] = useState(false);

  const onShowSidebar = () => {
    setShowSidebar(!showSidebar);
  }

  return (
    <div id="main-wrapper" className="show" style={{ height: "100%-" }}>
      <Header onShowSidebar={onShowSidebar} showSidebar={showSidebar}/>
      <Sidebar parentName={parentName} onShowSidebar={onShowSidebar}/>
      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
