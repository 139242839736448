function Modalback(props) {
    const { onClose, transparent, pcNone } = props;
    return (
        <div
            className={`modal fade depositModal modal-back show ${ pcNone && "pc-none" }`}
            tabIndex="-1"
            role="dialog"
            style={{ backgroundColor: transparent ? "transparent" : "#000000AA" }}
            aria-hidden="true"
            onClick={onClose}></div>
    );
}

export default Modalback;
