import { useState } from "react";
import { request } from "../../../api";
import { isEmpty, toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";
import Confirm from "./Confirm";

import Modalback from "./Modalback";

function ChangePasswordModal(props) {
  const { getList, data, url, onClose, method } = props;

  const [inputs, setInputs] = useState({
    password: '',
    passwordConfirm: ''
  });

  const { password, passwordConfirm } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target
    setInputs({
      ...inputs,
      [name]: value
    })
  }

  const onSubmit = () => {
    onShowModal("confirm");
    if (isEmpty(password)) {
      return toastr.warning("비번을 입력해주세요.");
    }

    if (password !== passwordConfirm) {
      return toastr.warning("비번확인을 입력해주세요.");
    }

    if (!isEmpty(data)) {
      const params = {
        id: data.id,
        loginid: data.loginid,
        loginpwd: password,
        parent_id: data.parent_id
      }
      request[method](api_url + url, params)
        .then(res => {
          toastr.success(res.message);
          onCloseModal();
        })
    }
  }

  const onCloseModal = () => {
    onClose("changePwd")
  }

  // ------------------------------------------------- //

  const [showModal, setShowModal] = useState({
    confirm: false
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const [confirmContent, setConfirmContent] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("")
  const onConfirm = (value) => {
    setConfirmStatus(value);
    onShowModal("confirm");
    setConfirmContent(`비밀번호를 변경하시겠습니까?`);
  }

  const onOk = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    onSubmit();
  }

  const onCancel = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    setShowModal("confirm");
  }

  return (
    <div className="modal fade passwordChangeModal show" tabIndex="-1" role="dialog" style={{ display: "block" }} aria-hidden="true">
      <Modalback onClose={onCloseModal} />
      <div className="modal-dialog modal-lg" style={{ width: "350px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">비밀번호변경</h5>
            <button type="button" className="close" data-dismiss="modal" onClick={onCloseModal}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <form className="row d-block">
              <div className="col">
                <label htmlFor="password" className="col-form-label">비밀번호:</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={password}
                  onChange={onChange} />
              </div>
              <div className="col">
                <label htmlFor="passwordConfirm" className="col-form-label">비밀번호확인:</label>
                <input
                  type="password"
                  className="form-control"
                  id="passwordConfirm"
                  name="passwordConfirm"
                  value={passwordConfirm}
                  onChange={onChange} />
              </div>
            </form>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" onClick={onCloseModal}>취소</button>
            <button type="button" className="btn btn-primary" onClick={onConfirm}>변경</button>
          </div>
        </div>
      </div>

      {
        showModal.confirm &&
        <Confirm
          content={confirmContent}
          onOk={onOk}
          onClose={onCancel} />
      }
    </div>
    // <div className="modal-dialog modal-lg" style={{ width: "350px" }}>
    //   <div className="modal-content">
    //     <div className="modal-header">
    //       <h5 className="modal-title">강제{type}</h5>
    //       <button type="button" className="close" data-dismiss="modal" onClick={onCloseModal}>
    //         <span>×</span>
    //       </button>
    //     </div>
    //     <div className="modal-body" style={{ padding: "30px" }}>
    //       <form className="row d-block">
    //         <div className="col">
    //           <label htmlFor="password" className="col-form-label">아이디:</label>
    //           <input
    //             type="text"
    //             className="form-control"
    //             value={data.loginid}
    //             disabled />
    //         </div>
    //         <div className="col">
    //           <label htmlFor="password" className="col-form-label">보유머니:</label>
    //           <input
    //             type="text"
    //             className="form-control"
    //             value={isNaN(data.money) ? 0 : Number(data.money).toLocaleString()}
    //             disabled />
    //         </div>
    //         <div className="col">
    //           <label htmlFor="passwordConfirm" className="col-form-label">{type}금액:</label>
    //           <input
    //             type="number"
    //             className="form-control"
    //             id="passwordConfirm"
    //             min="0"
    //             name="amount"
    //             value={amount}
    //             onChange={onChange} />
    //         </div>
    //       </form>

    //     </div>
    //     <div className="modal-footer">
    //       <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={onCloseModal}>취소</button>
    //       <button type="button" className="btn btn-primary" onClick={() => onSetMoney() }>{type}</button>
    //     </div>
    //   </div>
    // </div>
  );
}

export default ChangePasswordModal;
