import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { request } from '../api';
import { history } from '../helpers/history';

import { isEmpty, showMessage, toastr } from '../utils';
import { api_url } from '../utils/keys';
import setAuthToken from '../utils/setAuthToken';
import { pageActions } from './page.slice';

// create slice

const name = 'users';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const usersActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        usersList: [], 
        newUserList: [], 
        loginHistory: [], 
        connectUsersList: [], 
    }
}

function createReducers() {
    return {
        setUsers, 
        setNewUserList, 
        setLoginHistory, 
        setConnectedUsers, 
    };

    function setUsers(state, action) {
        state.usersList = action.payload;
    }

    function setNewUserList(state, action) {
        state.newUserList = action.payload;
    }

    function setLoginHistory(state, action) {
        state.loginHistory = action.payload;
    }

    function setConnectedUsers(state, action) {
        state.connectUsersList = action.payload;
    }
}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        getUsers: getUsers(),
        getNewUserList: getNewUserList(),
        getLoginHistory: getLoginHistory(),
    };

    function getUsers() {
        return createAsyncThunk(
            `${name}/usersList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/user/userlist", params)
                        .then(data => {
                            dispatch(usersActions.setUsers(data.users.data));
                            dispatch(pageActions.setPage(data.users));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getLoginHistory() {
        return createAsyncThunk(
            `${name}/getLoginHistory`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/user/user_login_hist", params)
                        .then(data => {
                            dispatch(usersActions.setLoginHistory(data.data.data));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getNewUserList() {
        return createAsyncThunk(
            `${name}/getNewUserList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/user/get_newuserlist", params)
                        .then(data => {
                            dispatch(usersActions.setNewUserList(data.data.data));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
}