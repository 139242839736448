import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { userActions } from "../../store";
import { isEmpty, messageMusic } from "../../utils";
import Modalback from "../common/modal/Modalback";

// const headerMenus = [
//   {
//     name: "금일베팅",
//     value: ""
//   },
//   {
//     name: "금일당첨",
//     value: ""
//   },
//   {
//     name: "금일입금",
//     value: ""
//   },
//   {
//     name: "금일출",
//     value: ""
//   },
//   {
//     name: "금일회원충전신청/완료",
//     value: ""
//   },
//   {
//     name: "금일회원환전신청/완료",
//     value: ""
//   },
//   {
//     name: "금일업체충전신청/완료",
//     value: ""
//   },
//   {
//     name: "금일업체환전신청/완료",
//     value: ""
//   },
//   {
//     name: "금일문의신청/완료",
//     value: ""
//   },
//   {
//     name: "회원충전신청",
//     value: ""
//   },
//   {
//     name: "회원환전신청",
//     value: ""
//   },
//   {
//     name: "회원충전신청",
//     value: ""
//   },
//   {
//     name: "회원환전신청",
//     value: ""
//   },
//   {
//     name: "업체충전신청",
//     value: ""
//   },
//   {
//     name: "업체환전신청",
//     value: ""
//   },
//   {
//     name: "전체 회원",
//     value: ""
//   },
//   {
//     name: "현재접속자",
//     value: ""
//   },
// ]

const Header = (props) => {
  const dispatch = useDispatch();
  const headInfo = useSelector(state => state.user.headInfo);
  const userInfo = useSelector(state => state.user.data);

  const { onShowSidebar, showSidebar } = props;

  const [showLogout, setShowLogout] = useState(false);

  const defaultMuted = (localStorage.getItem("isMuted") === "true");
  const [isMuted, setMuted] = useState(defaultMuted);

  const onMuted = () => {
    if (isMuted) {
      setMuted(false);
      localStorage.setItem("isMuted", "false");
    }
    else {
      setMuted(true);
      localStorage.setItem("isMuted", "true");
    }
  }

  const getHeadInfo = (value) => {
    if (isEmpty(headInfo) || isEmpty(headInfo[value])) {
      return 0
    }
    else if (((value === "money") || (value === "point")) && (Number(headInfo[value]) < 0)) {
      return 0
    }
    else {
      return Number(headInfo[value]).toLocaleString();
    }
  }

  const onLogout = () => {
    setShowLogout(!showLogout)
  }

  const logout = () => {
    dispatch(userActions.logout());
  }

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      dispatch(userActions.getHeadInfo())
    }
  }, [userInfo]);

  useEffect(() => {
    if (Number(userInfo.agent_type) === 3) {
      if (!isMuted) {
        if ((Number(headInfo.charge_alarm) > 0) || (Number(headInfo.withdraw_alarm) > 0) || (Number(headInfo.total_newuser) > 0)) {
          messageMusic.start();
        }
        else {
          messageMusic.stop();
        }
      }
    }
    else {
      if ((Number(headInfo.total_newuser) > 0)) {
        messageMusic.start();
        // console.log('messageMusic: ', messageMusic);
      }
      else {
        messageMusic.stop();
      }
    }
  }, [headInfo.charge_alarm, headInfo.withdraw_alarm, headInfo.total_newuser])

  return (
    <Fragment>

      <div className={`header ${showSidebar ? "active" : "margin-left-375-"} d-flex`}>
        { showSidebar && <Modalback onClose={onShowSidebar} transparent={true} pcNone={true}/> }
        <div className="header-content clearfix d-flex justify-content-between align-items-center">
          <div className="hamburger" onClick={onShowSidebar}>
            <span className="toggle-icon">
              <i className="icon-menu"></i>
            </span>
          </div>

          <div className="d-flex flex-wrap head-info ">
            <div className="">
              <span className="justify-content-between">보유금액&ensp;
                <span className="">
                  <span className={`head-info-color ${ Number(headInfo["money"]) === 0 && "text-white-i" }`}>
                    {getHeadInfo("money")}
                    </span>원
                  </span>
              </span>
              <span>보유포인트&ensp;
                <span className="">
                  <span className={`head-info-color ${ Number(headInfo["point"]) === 0 && "text-white-i" }`}>
                    {getHeadInfo("point")}
                    </span>P
                  </span>
              </span>
            </div>
            {
              Number(userInfo.agent_type) === 3 &&
              <Fragment>
                <div>
                  <Link to="/bettingHistory" className="justify-content-between d-flex">금일베팅&ensp;
                <span className="">
                  <span className={`head-info-color ${ Number(headInfo["total_betmoney"]) === 0 && "text-white-i" }`}>
                    {getHeadInfo("total_betmoney")}
                    </span>원
                  </span>
                  </Link>
                  <Link to="/bettingHistory" className="justify-content-between d-flex">금일당첨&ensp;
                <span className="">
                  <span className={`head-info-color ${ Number(headInfo["total_winmoney"]) === 0 && "text-white-i" }`}>
                    {getHeadInfo("total_winmoney")}
                    </span>원
                  </span>
                  </Link>
                </div>
                <div>
                  <Link to="/deposit" className="justify-content-between d-flex">금일입금&ensp;
                <span className="">
                  <span className={`head-info-color ${ Number(headInfo["total_chargemoney"]) === 0 && "text-white-i" }`}>
                    {getHeadInfo("total_chargemoney")}
                    </span>원
                  </span>
                  </Link>
                  <Link to="/withdraw" className="justify-content-between d-flex">금일출금&ensp;
                <span className="">
                  <span className={`head-info-color ${ Number(headInfo["total_withdrawmoney"]) === 0 && "text-white-i" }`}>
                    {getHeadInfo("total_withdrawmoney")}
                    </span>원
                  </span>
                  </Link>
                </div>
                <div>
                  <Link to="/deposit" className="justify-content-between d-flex">금일회원충전신청/완료&ensp;
                <span className="">
                      
                      <span className={`head-info-color ${ Number(headInfo["dayuser_ready_chargecount"]) === 0 && "text-white-i" }`}>
                        {getHeadInfo("dayuser_ready_chargecount")}
                        
                      </span>
                      /
                      <span className={`head-info-color ${ Number(headInfo["dayuser_end_chargecount"]) === 0 && "text-white-i" }`}>
                        {getHeadInfo("dayuser_end_chargecount")}
                        
                      </span>
                    </span>
                건
              </Link>
                  <Link to="/withdraw" className="justify-content-between d-flex">금일회원환전신청/완료&ensp;
                <span className="">
                      
                      <span className={`head-info-color ${ Number(headInfo["dayuser_ready_withdrawcount"]) === 0 && "text-white-i" }`}>
                        {getHeadInfo("dayuser_ready_withdrawcount")}
                        
                      </span>
                      /
                      <span className={`head-info-color ${ Number(headInfo["dayuser_end_withdrawcount"]) === 0 && "text-white-i" }`}>
                        {getHeadInfo("dayuser_end_withdrawcount")}
                        
                      </span>
                    </span>
                건
              </Link>
                </div>
                <div>
                  <Link to="/deposit" className="justify-content-between d-flex">금일업체충전신청/완료&ensp;
                <span className="">
                      
                      <span className={`head-info-color ${ Number(headInfo["dayenterprise_ready_chargecount"]) === 0 && "text-white-i" }`}>
                        {getHeadInfo("dayenterprise_ready_chargecount")}
                        
                      </span>
                  /
                  <span className={`head-info-color ${ Number(headInfo["dayenterprise_end_chargecount"]) === 0 && "text-white-i" }`}>
                    {getHeadInfo("dayenterprise_end_chargecount")}
                    
                  </span>
                    </span>
                건
              </Link>
                  <Link to="/withdraw" className="justify-content-between d-flex">금일업체환전신청/완료&ensp;
                <span className="">
                      
                      <span className={`head-info-color ${ Number(headInfo["dayenterprise_ready_withdrawcount"]) === 0 && "text-white-i" }`}>
                        {getHeadInfo("dayenterprise_ready_withdrawcount")}
                        
                      </span>
                  /
                  <span className={`head-info-color ${ Number(headInfo["dayenterprise_ready_withdrawcount"]) === 0 && "text-white-i" }`}>
                    {getHeadInfo("dayenterprise_ready_withdrawcount")}
                    
                  </span>
                    </span>
                건
              </Link>
                </div>
                <div>
                  <Link to="/customerCenter" className="justify-content-between d-flex">금일문의신청/완료&ensp;
                <span className="">
                      
                      <span className={`head-info-color ${ Number(headInfo["day_ready_questioncount"]) === 0 && "text-white-i" }`}>
                        {getHeadInfo("day_ready_questioncount")}
                        
                      </span>
                  /
                  <span className={`head-info-color ${ Number(headInfo["day_end_questioncount"]) === 0 && "text-white-i" }`}>
                    {getHeadInfo("day_end_questioncount")}
                    
                  </span>
                    </span>
                건
              </Link>
                  <Link to="/newuser" className="justify-content-between d-flex">회원신규/대기&ensp;
                  <span className="">
                      <span className="">
                        
                        <span className={`head-info-color ${ Number(headInfo["total_newuser"]) === 0 && "text-white-i" }`}>
                          {getHeadInfo("total_newuser")}
                          
                        </span>
                      /
                      <span className={`head-info-color ${ Number(headInfo["total_newreadyuser"]) === 0 && "text-white-i" }`}>
                        {getHeadInfo("total_newreadyuser")}
                        
                      </span>
                      </span>건
                  </span>
                  </Link>
                </div>
                {/* <div>
                  <Link to="/deposit" className="justify-content-between d-flex">회원충전신청&ensp;
                
                <span className={`head-info-color ${ Number(headInfo["user_chargecount"]) === 0 && "text-white-i" }`}>
                  {getHeadInfo("user_chargecount")}
                  
                </span>
                건
              </Link>
                  <Link to="/withdraw" className="justify-content-between d-flex">회원환전신청&ensp;
                
                <span className={`head-info-color ${ Number(headInfo["user_withdrawcount"]) === 0 && "text-white-i" }`}>
                  {getHeadInfo("user_withdrawcount")}
                  
                </span>
                건
              </Link>
                </div>
                <div>
                  <Link to="/deposit" className="justify-content-between d-flex">업체충전신청&ensp;
                <span className={`head-info-color ${ Number(headInfo["enterprise_chargecount"]) === 0 && "text-white-i" }`}>
                      {getHeadInfo("enterprise_chargecount")}
                    </span>
                건
              </Link>
                  <Link to="/withdraw" className="justify-content-between d-flex">업체환전신청&ensp;
                
                <span className={`head-info-color ${ Number(headInfo["enterrpise_withdrawcount"]) === 0 && "text-white-i" }`}>
                  {getHeadInfo("enterrpise_withdrawcount")}
                  
                </span>
                건
              </Link>
                </div> */}
              </Fragment>
            }
            <div>
              <Link to="/userList" className="justify-content-between d-flex">전체 회원&ensp;
              <span className="">
                <span className={`head-info-color ${ Number(headInfo["totalusers"]) === 0 && "text-white-i" }`}>
                  {getHeadInfo("totalusers")}
                  </span>명
                </span>
              </Link>
              {
                userInfo.agent_type === 3 ?
                  <Link to="/userAccess" className="justify-content-between d-flex">현재접속자&ensp;
                  <span className="">
                    <span className={`head-info-color ${ Number(headInfo["CurrentLoginUserCounts"]) === 0 && "text-white-i" }`}>
                      {getHeadInfo("CurrentLoginUserCounts")}
                      </span>명
                    </span>
                  </Link>
                  :
                  <Link to="/newuser" className="justify-content-between d-flex">회원신규/대기&ensp;
                  <span className="">
                      <span className="">
                        
                        <span className={`head-info-color ${ Number(headInfo["total_newuser"]) === 0 && "text-white-i" }`}>
                          {getHeadInfo("total_newuser")}
                          
                        </span>
                      /
                      <span className={`head-info-color ${ Number(headInfo["total_newreadyuser"]) === 0 && "text-white-i" }`}>
                        {getHeadInfo("total_newreadyuser")}
                        
                      </span>
                      </span>건
                  </span>
                  </Link>
              }
            </div>
          </div>

          <div className="header-right d-flex justify-content-center align-items-center">
            <Link to="/mypage">
              <span style={{ color: "#fff", fontSize: "25px", marginRight: "5px", cursor: "pointer" }} >
                <i title="마이페이지" className="mdi mdi-account-settings-variant"></i>
              </span>
            </Link>
            <span
              style={{
                color: "#fff",
                fontSize: "20px",
                marginRight: "10px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                whiteSpace: "nowrap",
                width: "40px"
              }}
              onClick={onMuted}>
              <i title="음끄기" className={`fa ${isMuted ? "fa-bell-slash-o" : "fa-bell-o"}`}></i>
            </span>
            <span style={{ color: "#fff", fontSize: "27px", cursor: "pointer" }} onClick={onLogout}>
              <i title="로그아웃" className="fa fa-sign-out"></i>
            </span>
          </div>
        </div>
      </div>


      {
        showLogout && <div className="modal fade show d-block">
          <Modalback onClose={onLogout} />
          <div className="modal-dialog modal-dialog-centered fade-out-anim" role="document" style={{ maxWidth: "420px" }}>
            <div className="modal-content" style={{ padding: "20px" }}>
              <div className="modal-header d-flex justify-content-center align-items-center" style={{ border: "0px" }}>
                <i style={{ color: "", fontSize: "55px" }} className="fa fa-question-circle"></i>
              </div>
              <div className="modal-body d-flex justify-content-center align-items-center">
                <h5 className="modal-title">로그아웃하시겠습니까 ?</h5>

              </div>
              <div className="modal-footer d-flex justify-content-center align-items-center" style={{ border: "0px" }}>
                <button type="button" className="btn btn-danger btn-sm mr-2" onClick={onLogout}>아니오</button>
                <button type="button" className="btn btn-primary btn-sm" style={{ width: "55px" }} onClick={logout}>네</button>
              </div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
};

export default Header;
