import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../api';

import { api_url } from '../utils/keys';
import { pageActions } from './page.slice';

// create slice

const name = 'money';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const moneyActions = { ...slice.actions, ...extraActions };
export const moneyReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        depositList: [],
        totalDeposit: 0,
        withdrawList: [],
        totalWithdraw: 0,
        moneyHistory: [],
        pointHistory: [],
    }
}

function createReducers() {
    return {
        setDepositList,
        setTotalDeposit,
        setWithdrawList,
        setTotalWithdraw,
        setMoneyHistory,
        setPointHistory
    };

    function setDepositList(state, action) {
        state.depositList = action.payload;
    }

    function setTotalDeposit(state, action) {
        state.totalDeposit = Number(action.payload);
    }

    function setWithdrawList(state, action) {
        state.withdrawList = action.payload;
    }

    function setTotalWithdraw(state, action) {
        state.totalWithdraw = Number(action.payload);
    }

    function setMoneyHistory(state, action) {
        state.moneyHistory = action.payload;
    }

    function setPointHistory(state, action) {
        state.pointHistory = action.payload;
    }
}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        getDepositList: getDepositList(),
        getWithdrawList: getWithdrawList(),
        getMoneyHistory: getMoneyHistory(),
        getPointHistory: getPointHistory(),
    };

    function getDepositList() {
        return createAsyncThunk(
            `${name}/getDepositList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/money/charge_history", params)
                        .then(data => {
                            dispatch(moneyActions.setDepositList(data.data.data));
                            dispatch(moneyActions.setTotalDeposit(data.data.total_chargemoney));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getWithdrawList() {
        return createAsyncThunk(
            `${name}/getWithdrawList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/money/withdraw_history", params)
                        .then(data => {
                            dispatch(moneyActions.setWithdrawList(data.data.data));
                            dispatch(moneyActions.setTotalWithdraw(data.data.total_withdrawmoney));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getMoneyHistory() {
        return createAsyncThunk(
            `${name}/getMoneyHistory`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/money/money_hist", params)
                        .then(data => {
                            dispatch(moneyActions.setMoneyHistory(data.data.data));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getPointHistory() {
        return createAsyncThunk(
            `${name}/getPointHistory`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/money/point_hist", params)
                        .then(data => {
                            dispatch(moneyActions.setPointHistory(data.data.data));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
}