import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux"
import moment from "moment";

import { dateFormation, isEmpty } from '../../utils';
import 'rsuite/dist/datepicker.css';
import { api_url } from "../../utils/keys";
import MonthlyCalcuNode from "./MonthlyCalcuNode";
import { request } from "../../api";
import TableEmpty from "../../component/common/table/TableEmpty";
import Thead from "../../component/common/table/Thead";

const theads = [
  {
    name: "아이디",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap", width: "200px" }
  },
  {
    name: "닉네임",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "회원등급",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "입금금액",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "출금금액",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "보유금액",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "보유포인트",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "베팅금",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "당첨금",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "수익",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
]

const months = [
  {
    name: '1월',
    value: 0
  },
  {
    name: '2월',
    value: 1
  },
  {
    name: '3월',
    value: 2
  },
  {
    name: '4월',
    value: 3
  },
  {
    name: '5월',
    value: 4
  },
  {
    name: '6월',
    value: 5
  },
  {
    name: '7월',
    value: 6
  },
  {
    name: '8월',
    value: 7
  },
  {
    name: '9월',
    value: 8
  },
  {
    name: '10월',
    value: 9
  },
  {
    name: '11월',
    value: 10
  },
  {
    name: '12월',
    value: 11
  },
]

const MonthlyCalcu = () => {

  // const dispatch = useDispatch();
  const { loginid } = useSelector((state) => state.user.data);

  const [dailyFigList, setDailyFigList] = useState([])
  const [activeAgent, setActiveAgent] = useState({});
  const [activeMonth, setActiveMonth] = useState((new Date()).getMonth());

  // -------------- 날짜선택 ---------------- //
  const [years, setYears] = useState([]);
  
  useEffect(() => {
    const nowyear = (new Date()).getFullYear();
    let yearsArr = [];
    for (let index = 2024; index <= nowyear; index++){
      yearsArr.push(index);
    }
    setYears(yearsArr);
  }, [])

  const [inputs, setInputs] = useState({
    searchtext: "",
    searchoption: "loginid",
    searchYear: new Date().getFullYear()
  });

  const { searchYear } = inputs;

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value
    });
  }

  const [Input, setInput] = useState({
    startDate: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endDate: dateFormation(Date.now(), 3)
  });

  const { startDate, endDate } = Input;

  // const onPickDate = (name, date) => {
  //   setInput({
  //     ...Input,
  //     [name]: dateFormation(date, 3)
  //   })
  // }

  // const clearDate = (name) => {
  //   setInput({
  //     ...Input,
  //     [name]: ""
  //   })
  // }
  // --------------------------------------//

  const getCalcDailyFigure = (startDate, loginid = "", endDate = "", search={}) => {

    const params = {
      start_time: startDate,
      end_time: endDate,
      loginid: loginid, 
      ...search
    }

    return new Promise(resolve => {
      request.post(api_url + "/api/calc/calc_month/userlist", params)
        .then(res => {
          resolve(res.data);
        }).catch((err) => {
          console.log("err", err)
        });
    })
  }

  const get_figureAgentList = (loginid, search) => {
    getCalcDailyFigure(startDate, loginid, endDate, search)
      .then(res => {
        setDailyFigList(res);
      })
  }

  useEffect(() => {
    if (!isEmpty(loginid)) {
      get_figureAgentList("");
    }
  }, [loginid]);

  // ------------------- Sort Start ----------------------- //
  const [sort, setSort] = useState({
    loginid: 0,
    username: 0,
  })

  const onSort = (value) => {

  }
  // ------------------- Sort End ----------------------- //

  const onMonth = (value) => {
    setActiveMonth(value);
    let nowDate = new Date(new Date().setFullYear(searchYear));
    const params = {
      starttime: moment(nowDate.setMonth(value)).utc().startOf("month").format("YYYY-MM-DD"),
      endtime: moment(nowDate.setMonth(value)).utc().endOf("month").format("YYYY-MM-DD")
    }
    getCalcDailyFigure(params.starttime, "", params.endtime)
      .then(res => {
        setDailyFigList(res);
      });
  }


  // --------------------------------------
  // const onSearch = () => {
  //   const parmas = {
  //     ...inputs,
  //     ...Input
  //   }
  //   get_figureAgentList("", parmas);
  // }

  return (
    <Fragment>

      <div className="col-12">
        <div className="card">
          <div className="card-body" style={{ padding: "10px 1rem" }}>
            <div className="d-flex align-items-center w-100">
              <div className={`d-flex   w-100 flex-wrap `} >

                <div className="d-flex">
                  {/* <label className="line-h-29 mr-2  col-form-label" style={{ whiteSpace: "nowrap" }}>
                      검색연도
                  </label> */}
                  <div className="d-flex align-items-center">
                    <select className="form-control padding-top-0 padding-bottom-0 mr-1" id="searchYear" name="searchYear" value={searchYear} onChange={onChangeSearch}>
                      {
                        years.map((year, index) => {
                          return (<option value={year} key={index}>{year}</option>)
                        })
                      }
                    </select>
                  </div>
                </div>
                <div className="d-flex align-items-center flex-wrap">
                  {
                    months.map((month, index) => {
                      return (
                        <label type="button" className={`btn-sm ${activeMonth === month.value ? "btn-success" : "btn-info"} mt-1 mb-1 mr-1 ml-1 cursor-pointer`}
                          style={{ height: "30px", width: "45px", display: "flex", alignItems: "center", justifyContent: "center" }}
                          onClick={() => onMonth(month.value)}
                          key={index}>
                          {month.name}
                        </label>
                      )
                    })
                  }
                </div>

                {/* <div className="d-flex align-items-center">
                  <label className="line-h-29 mr-2 ml-2  col-form-label" style={{ whiteSpace: "nowrap" }}>검색타입
                </label>
                  <div className="mr-2 ">
                    <select className="form-control padding-top-0 padding-bottom-0" id="searchoption" name="searchoption" value={searchoption} onChange={onChangeSearch}>
                      <option value="loginid">아이디</option>
                      <option value="username">닉네임</option>
                    </select>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <label className="line-h-29 mr-2 ml-2  col-form-label" style={{ whiteSpace: "nowrap" }}>검색어
                </label>
                  <div className="mr-2 ">
                    <div className="input-group icons">
                      <input
                        type="search"
                        className="form-control"
                        id="searchtext"
                        placeholder="검색"
                        name="searchtext"
                        value={searchtext}
                        onChange={onChangeSearch} />
                    </div>
                  </div>
                </div> */}
                {/* <div className="d-flex align-items-center">
                  <button type="button" className="btn btn-primary search-btn" onClick={onSearch}>
                    <i className="mdi mdi-magnify mr-2"></i>
                    검색
                </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="col-12">
        <div className="card">
          <div className="card-body padding-top-15 padding-bottom-15">
            {/* <h4 style={{ marginLeft: "25px" }} className="card-title">유저별정산</h4> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                <div className="row  padding-top-15 padding-bottom-15">
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                    <table className="money-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                      role="grid" aria-describedby="DataTables_Table_0_info">
                      <Thead theads={theads} sort={sort} onSort={onSort} />
                      <tbody>
                        {
                          // type === CurrentApiName &&
                          !isEmpty(dailyFigList) && dailyFigList.map((item, index) => {
                            return (
                              <MonthlyCalcuNode
                                data={item}
                                level={1}
                                activeAgent={activeAgent}
                                setActiveAgent={setActiveAgent}
                                getCalcDailyFigure={getCalcDailyFigure}
                                key={item.created_at + index} />
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(dailyFigList) ? ""
                        :
                        <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment >
  );
};

export default MonthlyCalcu;
