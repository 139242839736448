import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import ForceLogoutModal from "../../component/common/modal/LogoutModal";
import DateTd from "../../component/common/table/DateTd";
import TableEmpty from "../../component/common/table/TableEmpty";
import Thead from "../../component/common/table/Thead";
import { isEmpty } from "../../utils";

const theads = [
  {
    name: "번호",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "유저아이디",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "닉네임",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "도메인",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "아이피",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "접속일시",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "관리",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
]

function ConnectedUsers() {
  const connectUsersList = useSelector(state => state.users.connectUsersList);

  const [active, setActive] = useState(-1);
  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });

  const [showModal, setShowModal] = useState({
    forceLogout: false
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  // ------------------- Sort Start ----------------------- //
  const defaultSort = {
    loginid: 0,
    username: 0,
  }
  const [sort, setSort] = useState({
    loginid: 0,
    username: 0,
  })

  const onSort = (value) => {
  }
  // ------------------- Sort End ----------------------- //

  const getList = () => {
  }
  return (
    <Fragment>

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* <div style={{ marginLeft: "25px" }} className="card-title">&ensp;
                  유저접속자내역
                  </div> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                <div className="row">
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto padding-top-15 padding-bottom-15 mb-2">
                    <table className="user-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0">
                      <Thead theads={theads} sort={sort} onSort={onSort} />
                      <tbody>
                        {
                          connectUsersList.map((user, index) => {
                            return (
                              <tr role="row" className="odd" key={index} onClick={() => setActive(user.UserID)}>

                                <td className="sorting_1" style={{ textAlign: "center" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                <td style={{ textAlign: "center" }}>{user.UserLoginID}</td>
                                <td style={{ textAlign: "center" }}>{user.UserUserName}</td>
                                <td style={{ textAlign: "center" }}>{user.UserHost}</td>
                                <td style={{ textAlign: "center" }}>{user.IPAddress}</td>
                                <DateTd date={user.UserLastSocketTime} />
                                <td style={{ textAlign: "center" }}>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    style={{ color: "#fff" }} title="강퇴"
                                    onClick={() => onShowModal("forceLogout")}>
                                    <i className="fa fa-sign-out"></i>
                                  </button>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(connectUsersList) ? ""
                        :
                        <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>
                {/* <Pagenation
                      pageInfo={pageInfo}
                      setPageInfo={setPageInfo} /> */}
                {
                  (showModal.forceLogout && !isEmpty(connectUsersList.find(list => list.UserID === active))) &&
                  <ForceLogoutModal 
                    pageInfo={pageInfo} 
                    getList={getList}
                    data={connectUsersList.find(list => list.UserID === active)}
                    onClose={onShowModal}
                    method="post"
                    url="/api/auth/force_logout" />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
}

export default ConnectedUsers;
