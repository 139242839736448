import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../api';
import { api_url } from '../utils/keys';
import { pageActions } from './page.slice';

// create slice

const name = 'site';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const siteActions = { ...slice.actions, ...extraActions };
export const siteReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        eventList: [],
        noticeList: [],
        messageList: [],
        customerList: [],
        alManageList: [],
        bankManageList: [],
    }
}

function createReducers() {
    return {
        setEventList,
        setNoticeList,
        setMessageList,
        setCustomerList,
        setAlManageList,
        setBankManageList
    };

    function setEventList(state, action) {
        state.eventList = action.payload;
    }

    function setNoticeList(state, action) {
        state.noticeList = action.payload;
    }

    function setMessageList(state, action) {
        state.messageList = action.payload;
    }

    function setCustomerList(state, action) {
        state.customerList = action.payload;
    }

    function setAlManageList(state, action) {
        state.alManageList = action.payload;
    }

    function setBankManageList(state, action) {
        state.bankManageList = action.payload;
    }
}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        getEventList: getEventList(),
        getNoticeList: getNoticeList(),
        getMessageList: getMessageList(),
        getCustomerList: getCustomerList(),
        getAlManageList: getAlManageList(),
        getBankManageList: getBankManageList(),
    };

    function getEventList() {
        return createAsyncThunk(
            `${name}/getEventList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/site/get_eventlist", params)
                        .then(data => {
                            dispatch(siteActions.setEventList(data.data.data));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getNoticeList() {
        return createAsyncThunk(
            `${name}/getNoticeListList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/site/get_notice_hist", params)
                        .then(data => {
                            dispatch(siteActions.setNoticeList(data.data.data));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getMessageList() {
        return createAsyncThunk(
            `${name}/getMessageListist`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/site/get_message_hist", params)
                        .then(data => {
                            dispatch(siteActions.setMessageList(data.data.data));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getCustomerList() {
        return createAsyncThunk(
            `${name}/getCustomerListst`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/site/get_question", params)
                        .then(data => {
                            dispatch(siteActions.setCustomerList(data.data.data));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getAlManageList() {
        return createAsyncThunk(
            `${name}/getAlManageListst`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/site/get_al_hist", params)
                        .then(data => {
                            dispatch(siteActions.setAlManageList(data.data.data));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getBankManageList() {
        return createAsyncThunk(
            `${name}/getBankManageList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/site/get_banklist", params)
                        .then(data => {
                            dispatch(siteActions.setBankManageList(data.data.data));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
}