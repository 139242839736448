import React, { Fragment, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useSelector } from "react-redux";

const menuList = [
  // {
  //   name: "종합내역",
  //   icon: "icon-speedometer menu-icon",
  //   path: '/dashboard'
  // },
  {
    name: "업체관리",
    role: 0,
    icon: "fa fa-slideshare",
    children: [
      {
        name: "업체정보",
        icon: "icon-hourglass",
        path: '/agentList'
      },
      {
        name: "업체트리",
        icon: "icon-hourglass",
        path: '/agentTreeView'
      }
    ]
  },
  {
    name: "회원관리",
    role: 0,
    icon: "fa fa-group",
    children: [
      {
        name: "회원정보",
        icon: "icon-hourglass",
        path: '/userList'
      },
      {
        name: "현재접속자",
        icon: "icon-hourglass",
        path: '/userAccess',
        role: 3,
      },
      {
        name: "신규가입목록",
        icon: "icon-hourglass",
        path: '/newuser',
        role: 3,
      },
      {
        name: "로그인이력",
        icon: "icon-hourglass",
        path: '/loginhistory',
        role: 3,
      }

    ]
  },
  {
    name: "머니관리",
    role: 3,
    icon: "fa fa-credit-card",
    children: [
      {
        name: "충전관리",
        icon: "icon-hourglass",
        path: '/deposit'
      },
      {
        name: "환전관리",
        icon: "icon-hourglass",
        path: '/withdraw'
      },
      {
        name: "머니사용이력",
        icon: "icon-hourglass",
        path: '/moneyHistory'
      },
      {
        name: "포인트사용이력",
        icon: "icon-hourglass",
        path: '/pointHistory'
      }

    ]
  },
  {
    name: "게임관리",
    role: 0,
    icon: "fa fa-gamepad",
    children: [
      {
        name: "슬롯베팅내역",
        icon: "icon-hourglass",
        path: '/bettingHistory'
      },
      // {
      //   name: "베팅실시간상황",
      //   icon: "icon-hourglass",
      //   path: '/bettingStatus'
      // },
      // {
      //   name: "게임설정",
      //   icon: "icon-hourglass",
      //   path: '/gameSetting'
      // }
    ]
  },
  {
    name: "사이트관리",
    role: 3,
    icon: "fa fa-share-alt",
    children: [
      {
        name: "쪽지관리",
        icon: "icon-hourglass",
        path: '/message'
      },
      {
        name: "고객센터",
        icon: "icon-hourglass",
        path: '/customerCenter'
      },
      {
        name: "공지관리",
        icon: "icon-hourglass",
        path: '/notice'
      },
      {
        name: "이벤트관리",
        icon: "icon-hourglass",
        path: '/event'
      },
      {
        name: "알관리",
        icon: "icon-hourglass",
        path: '/alManage'
      },
      {
        name: "은행관리",
        icon: "icon-hourglass",
        path: '/bankList'
      }

    ]
  },
  {
    name: "정산관리",
    role: 0,
    icon: "fa fa-bar-chart-o",
    children: [
      {
        name: "유저별정산",
        icon: "icon-hourglass",
        path: '/usercalculate'
      },
      {
        name: "일자별정산",
        icon: "/",
        path: '/dailycalculate'
      },
      {
        name: "월별정산",
        icon: "icon-hourglass",
        path: '/monthlycalculate'
      }
    ]
  }

]
const Sidebar = (props) => {
  const { parentName, onShowSidebar } = props;
  const [activeId, setActiveId] = useState(parentName);
  const userData = useSelector(state => state.user.data);

  const onActive = (name) => {
    if (activeId === name) {
      setActiveId("");
    }
    else {
      setActiveId(name);
    }
  }

  useEffect(() => {
    setActiveId(parentName);
  }, [parentName])

  return (
    <Fragment>
      <div className="nk-sidebar" >
        <div className="nk-nav-scroll">

          <div className={`nav-header ${"active- width-375-"}`}>
            <Link to="/">
              <img className={`${"d-none"}`} src="../images/favicon.png" alt="" style={{ width: "45px", height: "auto" }} />
              <img className={`${""}`} src="../images/logo.png" alt="" style={{ width: "180px", height: "auto" }} />
            </Link>
          </div>

          <div className="side-user-info ">
            {/* <div></div> */}
            <div className="info-item">
              <i className="fa fa-id-card">&ensp;{userData.username}({userData.loginid})</i>
            </div>
          </div>

          <ul className="metismenu" id="menu">
            {/* <li className="nav-label" ></li> */}
            <li className={activeId === "대쉬보드" ? "active" : ""}>
              <NavLink className={({ isActive }) => isActive ? "active" : ""} to="/" onClick={() => { onActive("대쉬보드"); onShowSidebar();}}>
                <i className={`fa fa-home`}></i>
                <span className="nav-text">대쉬보드</span>
              </NavLink>
            </li>
            {/* <li className={activeId == "마이페이지" ? "active" : ""}>
              <NavLink className={({ isActive }) => isActive ? "active" : ""} to="/mypage" onClick={() => onActive("마이페이지")}>
                <i className={`fa fa-user`}></i>
                <span className="nav-text">마이페이지</span>
              </NavLink>
            </li> */}
            {
              menuList.map((menuitem, index) => {
                if ((menuitem.role !== 0) && (menuitem.role > userData.agent_type)) {
                  return "";
                }
                else {
                  return (
                    <li key={index} className={activeId === menuitem.name ? "active" : ""} >
                      <a className={menuitem.children && "has-arrow"} aria-expanded="false" onClick={() => onActive(menuitem.name)}>
                        <i className={`${menuitem.icon} menu-icon`}></i>
                        <span className="nav-text">{menuitem.name}</span>
                      </a>
                      {
                        menuitem.children &&
                        <ul className={activeId === menuitem.name ? "d-block" : "d-none"}>
                          {
                            menuitem.children.map((item, ind) => {
                              // if ((item.role !== 0) && (item.role > userData.agent_type)) {
                              //   return "";
                              // }
                              return (
                                <li key={ind}>
                                  <NavLink to={item.path} className={({ isActive }) => isActive ? "active" : ""} onClick={onShowSidebar}>
                                    {item.name}
                                  </NavLink>
                                </li>
                              )

                            })
                          }
                        </ul>
                      }

                    </li>
                  )
                }

              })
            }


          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
