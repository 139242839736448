import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../api';

import { api_url } from '../utils/keys';
import { pageActions } from './page.slice';

// create slice

const name = 'agent';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const agentActions = { ...slice.actions, ...extraActions };
export const agentReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        agentList: [], 
        parentAgentList: [], 
    }
}

function createReducers() {
    return {
        setAgent,
        setParentAgentList,
    };

    function setAgent(state, action) {
        state.agentList = action.payload;
    }

    function setParentAgentList(state, action) {
        state.parentAgentList = action.payload;
    }
}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        getAgent: getAgent(),
        getParentAgentList: getParentAgentList(), 
        getAllParentAgentList: getAllParentAgentList(),
    };

    function getAgent() {
        return createAsyncThunk(
            `${name}/agentList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/enterprise/userlist", params)
                        .then(data => {
                            dispatch(agentActions.setAgent(data.data.data));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getParentAgentList() {
        return createAsyncThunk(
            `${name}/getParentAgentList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/enterprise/get_enterpriselist", params)
                        .then(data => {
                            dispatch(agentActions.setParentAgentList(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getAllParentAgentList() {
        return createAsyncThunk(
            `${name}/getAllParentAgentList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/enterprise/get_allenterpriselist", params)
                        .then(data => {
                            dispatch(agentActions.setParentAgentList(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
}