import AgentTree from "../component/AgentMange/AgentTree/AgentTree";
import MyPage from "../component/MyPage/MyPage";
import AgentList from "../container/AgentMange/AgentList/AgentList";
import DailyCalcu from "../container/Calculate/DailyCalcu";
import MonthlyCalcu from "../container/Calculate/MonthlyCalcu";
import UserCalculate from "../container/Calculate/UserCalculate";
import Dashboard from "../container/Dashboard";
import GameSetting from "../container/Game/GameSetting";
import RealTime from "../container/Game/RealTime";
import SlotHistory from "../container/Game/SlotHistory";
import Deposit from "../container/Money/Deposit";
import Moneyhistory from "../container/Money/Moneyhistory";
import Pointhistory from "../container/Money/Pointhistory";
import Withdraw from "../container/Money/Withdraw";
import AlManage from "../container/Site/AlManage";
import BankManage from "../container/Site/BankManage";
import Customer from "../container/Site/Customer";
import Event from "../container/Site/Event";
import Message from "../container/Site/Message";
import Notice from "../container/Site/Notice";
import ConnectedUsers from "../container/UserManage/ConnectedUsers";
import LoginHistory from "../container/UserManage/LoginHistory";
import NewUserList from "../container/UserManage/NewUserList";
import UserList from "../container/UserManage/UserList";


export const routers = [
  {
    name: "대쉬보드",
    path: "/",
    parentPath: "대쉬보드",
    role: 0,
    element: Dashboard
  },
  {
    name: "마이페이지",
    path: "/mypage",
    parentPath: "마이페이지",
    role: 0,
    element: MyPage
  },


  {
    name: "업체관리",
    path: "/agentlist",
    parentPath: "업체관리",
    role: 0,
    element: AgentList
  },
  {
    name: "업체트리",
    path: '/agentTreeView',
    parentPath: "업체관리",
    role: 0,
    element: AgentTree
  },


  {
    name: "회원관리",
    path: "/userList",
    parentPath: "회원관리",
    role: 0,
    element: UserList
  },
  {
    name: "현재접속자수",
    path: "/userAccess",
    parentPath: "회원관리",
    role: 0,
    element: ConnectedUsers
  },
  {
    name: "신규가입목록",
    path: "/newuser",
    parentPath: "회원관리",
    role: 0,
    element: NewUserList
  },
  {
    name: "로그인이력",
    path: "/loginhistory",
    parentPath: "회원관리",
    role: 0,
    element: LoginHistory
  },



  {
    name: "충전관리",
    path: '/deposit',
    parentPath: "머니관리",
    role: 3,
    element: Deposit
  },
  {
    name: "환전관리",
    path: "/withdraw",
    parentPath: "머니관리",
    role: 3,
    element: Withdraw
  },
  {
    name: "머니사용이역",
    path: "/moneyHistory",
    parentPath: "머니관리",
    role: 3,
    element: Moneyhistory
  },
  {
    name: "포인트사용이역",
    path: "/pointHistory",
    parentPath: "머니관리",
    role: 3,
    element: Pointhistory
  },


  
  {
    name: "쪽지관리",
    path: '/message',
    parentPath: "사이트관리",
    role: 3,
    element: Message
  },
  {
    name: "고객센터",
    path: "/customerCenter",
    parentPath: "사이트관리",
    role: 3,
    element: Customer
  },
  {
    name: "공지관리",
    path: "/notice",
    parentPath: "사이트관리",
    role: 3,
    element: Notice
  },
  {
    name: "이벤트관리",
    path: "/event",
    parentPath: "사이트관리",
    role: 3,
    element: Event
  },
  {
    name: "알관리",
    path: '/AlManage',
    parentPath: "사이트관리",
    role: 3,
    element: AlManage
  },
  {
    name: "은행관리",
    path: '/bankList',
    parentPath: "사이트관리",
    role: 3,
    element: BankManage
  },

  

  {
    name: "슬롯베팅내역",
    path: "/bettingHistory",
    parentPath: "게임관리",
    role: 0,
    element: SlotHistory
  },
  // {
  //   name: "베팅실시간상황",
  //   path: '/bettingStatus',
  //   parentPath: "게임관리",
  //   role: 0,
  //   element: RealTime
  // },
  // {
  //   name: "게임설정",
  //   path: '/gameSetting',
  //   parentPath: "게임관리",
  //   role: 0,
  //   element: GameSetting
  // },

  
  {
    name: "유저별정산",
    path: "/usercalculate",
    parentPath: "정산관리",
    role: 0,
    element: UserCalculate
  },
  {
    name: "일자별정산",
    path: '/dailycalculate',
    parentPath: "정산관리",
    role: 0,
    element: DailyCalcu
  },
  {
    name: "월별정산",
    path: '/monthlycalculate',
    parentPath: "정산관리",
    role: 0,
    element: MonthlyCalcu
  },

]