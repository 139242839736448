
function TableEmpty(props) {
  const { colSpan } = props;

  return (
    <div className="d-flex justify-content-center align-items-center border- margin-bottom-5" style={{ height: "200px" }}>
      <div colSpan={colSpan} className="">데이터가 없습니다. </div>
    </div>
  );
}

export default TableEmpty;