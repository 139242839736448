import { useEffect, useState } from "react";
import { request } from "../../../api";
import { isEmpty, toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";
import Confirm from "../../common/modal/Confirm";

function BankNameEdit(props) {
  const { data, getList, pageInfo, sort } = props;

  const [inputs, setInputs] = useState({
    bankName: data.bank_name,
  });

  const { bankName } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value
    })
  }

  const onSubmit = () => {
    onShowModal("confirm");
    if (isEmpty(bankName)) {
      return toastr.warning("은행명을 입력해주세요.")
    }

    const paramdata = {
      id: data.id,
      image: data.image,
      status: data.status,
      bank_name: bankName
    }

    const params = paramdata;

    request.post(api_url + "/api/site/updatebankinfo", params)
      .then(res => {
        toastr.success(res.message);
        getList(pageInfo.curPage, pageInfo.perPage, {}, sort);
      })
  }

  useEffect(() => {
    setInputs({
      ...inputs,
      bankName: data.bank_name
    })
  }, [data])

  // ------------------------------------------------- //

  const [showModal, setShowModal] = useState({
    confirm: false
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const [confirmContent, setConfirmContent] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("")
  const onConfirm = (value) => {
    setConfirmStatus(value);
    onShowModal("confirm");
    setConfirmContent(`은행명을 ${"수정"}하시겠습니까?`);
  }

  const onOk = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    onSubmit();
  }

  const onCancel = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    setShowModal("confirm");
  }

  return (
    <div className="d-flex align-items-center" style={{ minWidth: "200px" }}>
      <input type="text"
        name="bankName"
        className="form-control mr-3"
        value={bankName}
        onChange={onChange} />
      <button type="button" className="btn btn-primary sm" onClick={onConfirm}>수정</button>

      {
        showModal.confirm ?
        <Confirm
          content={confirmContent}
          onOk={onOk}
          onClose={onCancel} />
          : ""
      }
    </div>
  );
}

export default BankNameEdit;
