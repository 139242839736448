import { Fragment } from "react";
import { useSelector } from "react-redux";
import { dateFormation, isEmpty } from "../utils";

function DashboardContent() {
  const headInfo = useSelector(state => state.user.headInfo);
  const userInfo = useSelector(state => state.user.data);
  return (
    <Fragment>
      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
        <div className="card dashboard gradient-1">
          <div className="card-body">
            <h3 className="card-title text-white">나의 보유포인트</h3>
            <div className="d-inline-block">
              <h2 className="text-white">{(isEmpty(headInfo) || isEmpty(headInfo.point) || (Number(headInfo.point) < 0)) ? 0 : Number(headInfo.point).toLocaleString()}</h2>
              <p className="text-white mb-0">{dateFormation(new Date(), 3)}</p>
            </div>
            <span className="float-right display-5 opacity-5"><i className="fa fa-product-hunt"></i></span>
          </div>
        </div>
      </div>
      {/* <div className="col-xl-4 col-lg-6 col-md-6 col-12">
        <div className="card dashboard gradient-2">
          <div className="card-body">
            <h3 className="card-title text-white">당일 나의 수익[슬롯]</h3>
            <div className="d-inline-block">
              <h2 className="text-white">$ 8541</h2>
              <p className="text-white mb-0">{dateFormation(new Date(), 3)}</p>
            </div>
            <span className="float-right display-5 opacity-5"><i className="fa fa-usd"></i></span>
          </div>
        </div>
      </div> */}
      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
        <div className="card dashboard gradient-3">
          <div className="card-body">
            <h3 className="card-title text-white"> 하부(전체)회원수 </h3>
            <div className="d-inline-block">
              <h2 className="text-white">
                {(isEmpty(headInfo) || isEmpty(headInfo.my_total_users) || (Number(headInfo.my_total_users) < 0)) ? 0 : Number(headInfo.my_total_users).toLocaleString()}
              </h2>
              <p className="text-white mb-0">{dateFormation(new Date(), 3)}</p>
            </div>
            <span className="float-right display-5 opacity-5"><i className="fa fa-users"></i></span>
          </div>
        </div>
      </div>
      {/* <div className="col-xl-4 col-lg-6 col-md-6 col-12">
        <div className="card dashboard gradient-4">
          <div className="card-body">
            <h3 className="card-title text-white">게임사</h3>
            <div className="d-inline-block">
              <h2 className="text-white">6/99</h2>
              <p className="text-white mb-0">{dateFormation(new Date(), 3)}</p>
            </div>
            <span className="float-right display-5 opacity-5"><i className="icon-screen-tablet"></i></span>
          </div>
        </div>
      </div> */}
      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
        <div className="card dashboard gradient-3">
          <div className="card-body">
            <h3 className="card-title text-white">전체 회원수</h3>
            <div className="d-inline-block">
              <h2 className="text-white">
              {(isEmpty(headInfo) || isEmpty(headInfo.totalusers) || (Number(headInfo.totalusers) < 0)) ? 0 : Number(headInfo.totalusers).toLocaleString()}
              </h2>
              <p className="text-white mb-0">{dateFormation(new Date(), 3)}</p>
            </div>
            <span className="float-right display-5 opacity-5"><i className="fa fa-users"></i></span>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
        <div className="card dashboard gradient-1">
          <div className="card-body">
            <h3 className="card-title text-white">하부(전체) 포인트</h3>
            <div className="d-inline-block">
              <h2 className="text-white">
                {(isEmpty(headInfo) || isEmpty(headInfo.totalsubpoint) || (Number(headInfo.totalsubpoint) < 0)) ? 0 : Number(headInfo.totalsubpoint).toLocaleString()}
              </h2>
              <p className="text-white mb-0">{dateFormation(new Date(), 3)}</p>
            </div>
            <span className="float-right display-5 opacity-5"><i className="fa fa-product-hunt"></i></span>
          </div>
        </div>
      </div>
      {/* <div className="col-xl-4 col-lg-6 col-md-6 col-12">
        <div className="card dashboard gradient-2">
          <div className="card-body">
            <h3 className="card-title text-white">당일 하부수익[슬롯]</h3>
            <div className="d-inline-block">
              <h2 className="text-white">765</h2>
              <p className="text-white mb-0">{dateFormation(new Date(), 3)}</p>
            </div>
            <span className="float-right display-5 opacity-5"><i className="fa fa-usd"></i></span>
          </div>
        </div>
      </div> */}
      <div className="col-xl-4 col-lg-6 col-md-6 col-12">
        <div className="card dashboard gradient-4">
          <div className="card-body">
            <h3 className="card-title text-white">하부(전체)업체수</h3>
            <div className="d-inline-block">
              <h2 className="text-white">{(isEmpty(headInfo) || isEmpty(headInfo.my_subtotal_enterrpises) || (Number(headInfo.my_subtotal_enterrpises) < 0)) ? 0 : Number(headInfo.my_subtotal_enterrpises).toLocaleString()}</h2>
              <p className="text-white mb-0">{dateFormation(new Date(), 3)}</p>
            </div>
            <span className="float-right display-5 opacity-5"><i className="icon-grid"></i></span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default DashboardContent;
