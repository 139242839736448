import { Fragment, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

// import PrivateRoute from './component/common/PrivateRoute';
// import './App.css';

import { store, userActions } from './store';
import setAuthToken from './utils/setAuthToken';
import { history } from './helpers/history';
import { getIpAddr, isEmpty } from './utils';
import { initSocket } from './utils/socket';
import Login from './container/Auth/Login';
import { routers } from './router/index.js';
import PrivateRoute from './component/common/PrivateRoute';
import Layout from './component/Layout/Layout';

if (!isEmpty(localStorage.jwtToken)) {
  setAuthToken(localStorage.jwtToken);
  const currentTime = Date.now() / 1000;
  const decoded = jwt_decode(localStorage.jwtToken);

  store.dispatch(userActions.setUserInfo(decoded));
  store.dispatch(userActions.getUserInfo());
  // localStorage.setItem("exp", decoded.exp);
  localStorage.setItem("time", currentTime);
}

initSocket();

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  
  useEffect(() => {
    getIpAddr();
  }, []);

  return (
    <Fragment>
      <NotificationContainer />
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        {
          routers.map((router, index) => {
            return (
              <Route
                key={index}
                path={router.path}
                element={
                  <PrivateRoute role={router.role}>
                    <Layout parentName={router.parentPath}>
                      <router.element />
                    </Layout>
                  </PrivateRoute>
                }
              />
            )
          })
        }
      </Routes>
    </Fragment>
  );
}

export default App;