import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { request } from "../../api";
import { userActions } from "../../store";
import { isEmpty, toastr } from "../../utils";
import { api_url } from "../../utils/keys";
import Confirm from "../common/modal/Confirm";

function MyPageInfo() {
  const userInfo = useSelector(state => state.user.data);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    loginId: userInfo.loginid,
    parentId: userInfo.parent_id,
    username: userInfo.username,
    tel: userInfo.telnum,
    partnerCode: userInfo.partner_code,
    rollingPercent: userInfo.rolling_percent,
    rtp: userInfo.rtp,
    agentType: userInfo.agent_type,
    bankName: userInfo.bankname,
    accountNumber: userInfo.accountnumber,
    mastername: userInfo.mastername,
    status: userInfo.status,
    withdrawPassword: "",
    withdrawPasswordConfirm: "",
  });

  const {
    loginId,
    parentId,
    username,
    tel,
    partnerCode,
    rollingPercent,
    rtp,
    agentType,
    bankName,
    accountNumber,
    mastername,
    status,
    withdrawPassword,
    withdrawPasswordConfirm
  } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target
    setInputs({
      ...inputs,
      [name]: value
    })
  }
  const onSubmit = () => {
    if (isEmpty(loginId)) {
      return toastr.warning("닉네임를 입력해주세요.")
    }
    // if (isEmpty(loginId)) {
    //   return toastr.warning("아이디를 입력해주세요.")
    // }
    // if (isEmpty(username)) {
    //   return toastr.warning("닉네임을 입력해주세요.")
    // }
    // if (isEmpty(tel) && !props.noTel) {
    //   return toastr.warning("연락처를 입력해주세요.")
    // }
    // if (isEmpty(partnerCode) && !props.noPartnerCode) {
    //   return toastr.warning("추천코드를 입력해주세요.")
    // }
    // if (isEmpty(bankName)) {
    //   return toastr.warning("은행명을 선택해주세요.")
    // }
    // if (isEmpty(accountNumber)) {
    //   return toastr.warning("계좌번호를 입력해주세요.")
    // }
    // if (isEmpty(mastername)) {
    //   return toastr.warning("예금주를 입력해주세요.")
    // }
    //   if (isEmpty(withdrawPassword)) {
    //     return toastr.warning("출금비번을 입력해주세요.")
    //   }
    //   if (!(withdrawPassword === withdrawPasswordConfirm)) {
    //     return toastr.warning("출금비번확인을 확인해주세요.")
    //   }
    // }

    const params = {
      // loginid: loginId,
      // parent_id: parentId,
      username: username,
      // telnum: tel,
      // partner_code: partnerCode,
      // rolling_percent: rollingPercent,
      // rtp: rtp,
      // agent_type: agentType,
      // bankname: bankName,
      // accountnumber: accountNumber,
      // mastername: mastername,
      // status: status,
      // oldPassword: oldPassword,
      // newPassword: newPassword,
      // withdraw_password: withdrawPassword,
    }

    request.put(api_url + "/api/auth/userData", params)
      .then((res) => {
        toastr.success(res.message);
        dispatch(userActions.getUserInfo());
      });

  }

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      setInputs({
        loginId: userInfo.loginid,
        parentId: userInfo.parent_id,
        username: userInfo.username,
        tel: userInfo.telnum,
        partnerCode: userInfo.partner_code,
        rollingPercent: userInfo.rolling_percent,
        rtp: userInfo.rtp,
        agentType: userInfo.agent_type,
        bankName: userInfo.bankname,
        accountNumber: userInfo.accountnumber,
        mastername: userInfo.mastername,
        status: userInfo.status,
        withdrawPassword: "",
        withdrawPasswordConfirm: "",
      })
    }
  }, [userInfo]);


  // ------------------------------------------------- //

  const [showModal, setShowModal] = useState({
    confirm: false
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const [confirmContent, setConfirmContent] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("");

  const onConfirm = (value) => {
    setConfirmStatus(value);
    onShowModal("confirm");
    setConfirmContent(`정보변경을 하시겠습니까?`);
  }

  const onOk = () => {
    setConfirmStatus("");
    onShowModal("confirm");
    onSubmit();
  }

  const onCancel = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    setShowModal("confirm");
  }


  return (
    <Fragment>

      <div className="col-lg-6">
        <div className="form-group">
          <label>로그인 아이디</label>
          <input
            type="text"
            className="form-control"
            name="loginId"
            value={loginId}
            disabled />
        </div>
        <div className="form-group">
          <label>닉네님</label>
          <input
            type="text"
            className="form-control"
            name="username"
            value={username}
            onChange={onChange} />
        </div>
        <div className="form-group">
          <label>은행명</label>
          <input
            type="text"
            className="form-control"
            name="bankName"
            value={bankName}
            disabled />
        </div>
        <div className="form-group">
          <label>계좌번호</label>
          <input
            type="text"
            className="form-control"
            name="accountNumber"
            value={accountNumber}
            disabled />
        </div>
        <div className="form-group">
          <label>추천코드</label>
          <input
            type="text"
            className="form-control"
            value={partnerCode}
            disabled />
        </div>
        <div className="form-group row">
          <div className="m-auto">
            <button type="submit" className="btn btn-primary" onClick={onConfirm}>정보변경</button>
          </div>
        </div>
      </div>

      {
        showModal.confirm ?
        <Confirm
          content={confirmContent}
          onOk={onOk}
          onClose={onCancel} />
          : ''
      }
    </Fragment>
  );
}

export default MyPageInfo;
