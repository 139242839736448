import { Fragment } from "react";
import { dateFormation, isEmpty } from "../../../utils";

function DateTd(props) {
  const { date } = props;

  return (
    <td style={{ textAlign: "center", width: "90px" }}>
      {
        !isEmpty(date) && 
        <Fragment>
          <span className="line-h-22 white-space-nowrap">{dateFormation(date, 3)}</span>
          <br />
          {dateFormation(date, 6)}
        </Fragment>
      }
    </td>
  );
}

export default DateTd;
