import { Fragment } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { request } from "../../../api";
import { agentActions } from "../../../store";
import { isEmpty, toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";
import Confirm from "./Confirm";
import Modalback from "./Modalback";

function EditModal(props) {
  const { data, edit, url, getList, onClose, checkurl, name, pageInfo } = props;
  const dispatch = useDispatch();
  const bankList = useSelector(state => state.user.bankList);
  const parentAgentList = useSelector(state => state.agent.parentAgentList);
  const [inputs, setInputs] = useState({
    loginId: edit ? data.loginid : "",
    parentId: edit ? data.parent_id : "",
    username: edit ? data.username : "",
    tel: edit ? data.telnum : "",
    partnerCode: edit ? data.partner_code : "",
    rollingPercent: edit ? data.rolling_percent : "",
    rtp: edit ? data.rtp : "",
    agentType: edit ? data.agent_type : "",
    bankName: edit ? data.bankname : "",
    accountNumber: edit ? data.accountnumber : "",
    mastername: edit ? data.mastername : "",
    status: edit ? data.status : "",
    password: "",
    passwordConfirm: "",
    withdrawPassword: edit ? data.withdrawPassword : "",
    withdrawPasswordConfirm: edit ? data.withdrawPasswordConfirm : "",
  });

  const {
    loginId,
    parentId,
    username,
    tel,
    partnerCode,
    rollingPercent,
    rtp,
    agentType,
    bankName,
    accountNumber,
    mastername,
    status,
    password,
    passwordConfirm,
    withdrawPassword,
    withdrawPasswordConfirm
  } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target
    if ((name === "loginId") || (name === "parentId") || (name === "agentType")) {
      if (edit) {
        return;
      }
      else {
        setInputs({
          ...inputs,
          [name]: value
        })
      }
    }
    else {
      setInputs({
        ...inputs,
        [name]: value
      })
    }
  }
  const onSubmit = () => {
    onShowModal("confirm");
    if (isEmpty(loginId)) {
      return toastr.warning("아이디를 입력해주세요.")
    }
    if (isEmpty(parentId)) {
      return toastr.warning("상위업체를 선택해주세요.")
    }
    if (isEmpty(username)) {
      return toastr.warning("닉네임을 입력해주세요.")
    }
    if (isEmpty(tel) && !props.noTel) {
      return toastr.warning("연락처를 입력해주세요.")
    }
    if (isEmpty(partnerCode) && !props.noPartnerCode) {
      return toastr.warning("추천코드를 입력해주세요.")
    }
    if (isEmpty(rollingPercent) && !props.noRollingPercent) {
      return toastr.warning("롤링퍼센트를 입력해주세요.")
    }
    if (isEmpty(rtp) && !props.noRtp) {
      return toastr.warning("환수율을 입력해주세요.")
    }
    if (((rtp < 0) || (rtp > 220)) && !props.noRtp) {
      return toastr.warning("환수율을 정확히 입력해주세요.")
    }
    if (isEmpty(agentType) && !props.noAgentType) {
      return toastr.warning("업체등급을 선택해주세요.")
    }
    if (isEmpty(bankName)) {
      return toastr.warning("은행명을 선택해주세요.")
    }
    if (isEmpty(accountNumber)) {
      return toastr.warning("계좌번호를 입력해주세요.")
    }
    if (isEmpty(mastername)) {
      return toastr.warning("예금주를 입력해주세요.")
    }
    if (isEmpty(status) && !props.noStatus) {
      return toastr.warning("계정상태를 선택해주세요.")
    }
    if (isEmpty(password) && !props.noPassword) {
      return toastr.warning("비번을 입력해주세요.")
    }
    if ((password !== passwordConfirm) && !props.noPassword) {
      return toastr.warning("비번확인을 확인해주세요.")
    }
    if(url === "/api/user/create") {
      if (isEmpty(withdrawPassword) && !props.noWithdrawPassword) {
        return toastr.warning("출금비번을 입력해주세요.")
      }
      if (!(withdrawPassword === withdrawPasswordConfirm) && !props.noWithdrawPassword) {
        return toastr.warning("출금비번확인을 확인해주세요.")
      }
    }

    const params = {
      id: edit ? data.id : "",
      loginid: loginId,
      parent_id: parentId,
      username: username,
      telnum: tel,
      partner_code: partnerCode,
      rolling_percent: rollingPercent,
      rtp: rtp,
      agent_type: agentType,
      loginpwd: password,
      bankname: bankName,
      accountnumber: accountNumber,
      mastername: mastername,
      status: status,
      password: password,
      withdraw_password: withdrawPassword,
    }

    const method = edit ? "put" : "post"

    request[method](api_url + url, params)
      .then(res => {
        toastr.success(res.message);
        if (name === "회원") {
          dispatch(agentActions.getAllParentAgentList());
        }
        else {
          dispatch(agentActions.getParentAgentList());
        }
        getList(edit ? pageInfo.curPage : 1, pageInfo.perPage);
        onCloseModal();
      })
  }

  const onCheckid = () => {
    if (isEmpty(loginId)) {
      return toastr.warning("아이디를 입력해주세요.")
    }
    const params = { loginid: loginId }
    if (!isEmpty(checkurl)) {
      request.post(api_url + checkurl, params)
        .then(res => {
          toastr.success(res.message);
        })
    }
  }

  const onCloseModal = () => {
    onClose(edit ? "edit" : "create");
  }

  const onCheckCode = () => {
    if (isEmpty(loginId)) {
      return toastr.warning("아이디를 입력해주세요.")
    }
    if (isEmpty(partnerCode)) {
      return toastr.warning("추천코드를 입력해주세요.");
    }
    const params = {
      partner_code: partnerCode,
      loginid: loginId
    }
    request.post(api_url + "/api/enterprise/check_partnercode", params)
      .then(res => {
        toastr.success(res.message);
      })
  }

  // ------------------------------------------------- //

  const [showModal, setShowModal] = useState({
    confirm: false
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const [confirmContent, setConfirmContent] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("")
  const onConfirm = (value) => {
    setConfirmStatus(value);
    onShowModal("confirm");
    setConfirmContent(`${name}${edit ? "수정을" : "추가를"} 하시겠습니까?`);
  }

  const onOk = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    onSubmit();
  }

  const onCancel = () => {
    onShowModal(confirmStatus);
    setConfirmStatus("");
    setShowModal("confirm");
  }

  return (
    <div className="modal fade createRegistration show" tabIndex="-1" role="dialog" style={{ display: "block" }} aria-hidden="true">
      <Modalback onClose={onCloseModal} />
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{name}정보</h5>
            <button type="button" className="close" data-dismiss="modal" onClick={onCloseModal}><span>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ padding: "40px" }}>
            <form className="row">
              <div className="col-sm-6 col-12">
                <label htmlFor="login_id" className="col-form-label">아이디:</label>
                <input
                  type="text"
                  required
                  className="form-control has-check-btn-input"
                  id="login_id"
                  name="loginId"
                  onChange={onChange}
                  value={loginId}
                  disabled={edit ? true : false} />
                {edit !== true && <label className="col-form-label check-input-btn btn-primary" onClick={onCheckid}>중복확인</label>}
              </div>
              <div className="col-sm-6 col-12">
                <label htmlFor="parent_id" className="col-form-label">상위업체:</label>
                <select
                  className="form-control"
                  required id="parent_id"
                  name="parentId"
                  onChange={onChange}
                  value={parentId}
                  disabled={edit ? true : false}
                >
                  <option value="" style={{ display: "none" }}>업체를 선택해주세요.</option>
                  {
                    !isEmpty(parentAgentList) &&
                    parentAgentList.map((agent_list, index) => {
                      return (
                        <option value={agent_list.id} key={index}>{agent_list.loginid}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="col-sm-6 col-12">
                <label htmlFor="user_name" className="col-form-label">닉네임:</label>
                <input
                  type="text"
                  required
                  className="form-control"
                  id="user_name"
                  name="username"
                  onChange={onChange}
                  value={username} />
              </div>

              {
                !props.noTel &&
                <div className="col-sm-6 col-12">
                  <label htmlFor="partner_code" className="col-form-label">연락처:</label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    id="tel"
                    name="tel" 
                    maxLength={11}
                    onChange={onChange}
                    value={tel} />
                </div>
              }

              {
                !props.noPartnerCode &&
                <div className="col-sm-6 col-12">
                  <label htmlFor="partner_code" className="col-form-label">추천코드:</label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    id="partner_code"
                    name="partnerCode"
                    onChange={onChange}
                    value={partnerCode} />
                  <label htmlFor="partner_code" className="col-form-label check-input-btn btn-primary" onClick={onCheckCode}>중복확인</label>
                </div>
              }
              {
                !props.noRollingPercent &&
                <div className="col-sm-6 col-12">
                  <label htmlFor="rolling_percent" className="col-form-label">롤링퍼센트:</label>
                  <input
                    type="number"
                    required
                    className="form-control"
                    id="rolling_percent"
                    name="rollingPercent"
                    onChange={onChange}
                    value={rollingPercent} />
                </div>
              }
              {
                !props.noRtp &&
                <div className="col-sm-6 col-12">
                  <label htmlFor="rtp" className="col-form-label">환수율:</label>
                  <input
                    type="number"
                    required
                    className="form-control"
                    min="0"
                    max="120"
                    id="rtp"
                    name="rtp"
                    onChange={onChange}
                    value={rtp} />
                </div>
              }
              {
                !props.noAgentType &&
                <div className="col-sm-6 col-12">
                  <label htmlFor="agent_type" className="col-form-label">업체등급:</label>
                  <select className="form-control" required id="agent_type" name="agentType" onChange={onChange} value={agentType} disabled={edit && (data.agent_type === 3)}>
                    <option value="" style={{ display: "none" }}>업체등급을 선택해주세요.</option>
                    <option value="1">에이젠트</option>
                    <option value="2">오퍼레이터</option>
                  </select>
                </div>
              }
              <div className="col-sm-6 col-12">
                <label htmlFor="bank_name" className="col-form-label">은행명:</label>
                <div className="col-lg-12 p-0">
                  <select className="form-control" required id="bank_name" name="bankName" onChange={onChange} value={bankName}>
                    <option value="" style={{ display: "none" }}>은행을 선택해주세요.</option>
                    {
                      !isEmpty(bankList) &&
                      bankList.map((bank, index) => {
                        return (
                          <option value={bank.bank_name} key={index}>{bank.bank_name}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <label htmlFor="masterr" className="col-form-label">계좌번호:</label>
                <input
                  type="text"
                  required
                  className="form-control"
                  id="account_number"
                  name="accountNumber"
                  onChange={onChange}
                  value={accountNumber} />
              </div>
              <div className="col-sm-6 col-12">
                <label htmlFor="masterr" className="col-form-label">예금주:</label>
                <input
                  type="text"
                  required
                  className="form-control"
                  id="masterr"
                  name="mastername"
                  onChange={onChange}
                  value={mastername} />
              </div>
              {
                !props.noStatus &&
                <div className="col-sm-6 col-12">
                  <label htmlFor="statuss" className="col-form-label">계정상태:</label>
                  <select className="form-control" required id="statuss" name="status" onChange={onChange} value={status}>
                    <option value="" style={{ display: "none" }}>계정상태를 선택해주세요.</option>
                    <option value="1">정상</option>
                    <option value="0">중지</option>
                  </select>
                </div>
              }

            </form>


            <form className="row">
              {
                !props.noPassword &&
                <Fragment>
                  <div className="col-sm-6 col-12">
                    <label htmlFor="password" className="col-form-label">비밀번호:</label>
                    <input
                      type="password"
                      required
                      className="form-control"
                      id="password"
                      name="password"
                      onChange={onChange}
                      value={password} />
                  </div>
                  <div className="col-sm-6 col-12">
                    <label htmlFor="passwordConfirm" className="col-form-label">비밀번호확인:</label>
                    <input type="password"
                      required
                      className="form-control"
                      id="passwordConfirm"
                      name="passwordConfirm"
                      onChange={onChange}
                      value={passwordConfirm} />
                  </div>
                </Fragment>
              }
              {
                (!props.noWithdrawPassword && (url === "/api/user/create")) &&
                <Fragment>
                  <div className="col-sm-6 col-12">
                    <label htmlFor="withdraw_assword" className="col-form-label">출금비밀번호:</label>
                    <input
                      type="password"
                      required
                      className="form-control"
                      id="withdraw_password"
                      name="withdrawPassword"
                      onChange={onChange}
                      value={withdrawPassword} />
                  </div>
                  <div className="col-sm-6 col-12">
                    <label htmlFor="withdraw_password_confirm" className="col-form-label">출금비밀번호확인:</label>
                    <input
                      type="password"
                      required
                      className="form-control"
                      id="withdraw_password_confirm"
                      name="withdrawPasswordConfirm"
                      onChange={onChange}
                      value={withdrawPasswordConfirm} />
                  </div>
                </Fragment>
              }
            </form>

          </div>
          <div className="modal-footer" style={{ padding: "40px" }}>
            <button type="button" className="btn btn-danger" onClick={onCloseModal}>취소</button>
            <button type="button" className="btn btn-primary" onClick={onConfirm}>{edit ? "수정" : "추가"}</button>
          </div>
        </div>
      </div>

      {
        showModal.confirm &&
        <Confirm
          content={confirmContent}
          onOk={onOk}
          onClose={onCancel} />
      }
    </div>
  );
}

export default EditModal;
