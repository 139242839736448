import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../api';

import { api_url } from '../utils/keys';
import { pageActions } from './page.slice';

// create slice

const name = 'game';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const gameActions = { ...slice.actions, ...extraActions };
export const gameReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        slotBettingHistory: [],
        total: {
            totalBetmoney: 0,
            totalProfit: 0,
            totalWinmoney: 0
        },
        realTimeList: [],
    }
}

function createReducers() {
    return {
        setSlotBettingHistory,
        setRealTimeList,
        setTotal,
    };

    function setSlotBettingHistory(state, action) {
        state.slotBettingHistory = action.payload;
    }

    function setRealTimeList(state, action) {
        state.realTimeList = action.payload;
    }

    function setTotal(state, action) {
        state.total = action.payload;
    }
}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        getSlotBettingHistory: getSlotBettingHistory(),
        getRealTimeList: getRealTimeList(),
    };

    function getSlotBettingHistory() {
        return createAsyncThunk(
            `${name}/getSlotBettingHistory`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/game/slot_betting_hist", params)
                        .then(data => {
                            dispatch(gameActions.setSlotBettingHistory(data.data.data));
                            dispatch(gameActions.setTotal({
                                totalBetmoney: data.data.total_betmoney,
                                totalProfit: data.data.total_profit,
                                totalWinmoney: data.data.total_winmoney,
                            }));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getRealTimeList() {
        return createAsyncThunk(
            `${name}/getRealTimeList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/user/userlist", params)
                        .then(data => {
                            dispatch(gameActions.setRealTimeList(data.data.data));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
}