import React from "react";

const Footer = () => {

  return (
      <div className="footer">
        <div className="copyright">
          <p>Copyright @ 2024 All rights reserved.</p>
        </div>
      </div>
  );
};

export default Footer;
