import { request } from "../../../api";
import { isEmpty, toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";
import Modalback from "./Modalback";

function DeleteModal(props) {
  const { onClose, getList, url, method, data, name, pageInfo } = props;

  const onSubmit = () => {
    const paramData = {
      loginid: data.loginid,
      id: data.id,
      uid: isEmpty(data.uid) ? data.id : data.uid
    }
    const params = method === "post" ? paramData
    : {
      params: paramData
    }
    request[!method ? "delete" : method](api_url + url, params)
    .then(res => {
      toastr.success(res.message);
      getList(pageInfo.curPage, pageInfo.perPage);
      onCloseModal()
    })
  }

  const onCloseModal = () => {
    onClose(props.imgDelete ? "imgDelete" : "delete");
  }

  return (
    <div 
      className="modal fade bd-example-modal-sm agentDeleteModal show" 
      tabIndex="-1" 
      style={{ display: "block" }}>
        <Modalback onClose={onCloseModal}/>
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header d-flex justify-content-center align-items-center">
            {/* <i className="fa fa-exclamation-circle" style={{color: "red", fontSize: "45px", marginLeft: "120px"}}></i> */}
            <button type="button" className="close" onClick={onCloseModal}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body d-flex justify-content-center align-items-center">{name} 삭제하시겟습니까 ?</div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger btn-sm" onClick={onCloseModal}>아니오</button>
            <button type="button" className="btn btn-primary btn-sm" style={{ width: "45px" }} onClick={onSubmit}>네</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
