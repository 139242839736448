import { dateFormation, isEmpty, toastr } from ".";
import { history } from "../helpers/history";
import { gameActions, moneyActions, siteActions, store, userActions, usersActions } from "../store";
import { api_url } from "./keys"

const io = window.io(api_url, { origins: "*", "transports": ["websocket"] });
export const socket = io;

export const initSocket = () => {
  socket.on('connect', (err) => {
    console.log(' --------- Socket Connected -------- ');
    const userData = store.getState().user.data;
    if (!isEmpty(userData.id)) {
      const socketParams = {
        id: userData.id,
        token: localStorage.getItem("jwtToken")
      }
      socket.emit('me', socketParams);
    }
    store.dispatch(userActions.getUserInfo());
  });

  socket.on('disconnect', (err) => {
    console.error('disconneced: ', err);
    store.dispatch(userActions.logout());
  });

  socket.on('error', (err) => {
    console.error('error: ', err);
  });

  socket.on('connectedUser', (data) => {
    store.dispatch(usersActions.setConnectedUsers(JSON.parse(data.CurrentUserSocketIds)))
  });

  socket.on('headinfo', (data) => {
    store.dispatch(userActions.setHeadInfo(data));
  });

  socket.on('chargeRequest', (data) => {
    toastr.info(data.message)
    if (history.location.pathname === "/deposit") {
      const params = {
        page: 1,
        perPage: store.getState().page.perPage,
        starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
        endtime: dateFormation(Date.now(), 3)
      }
      store.dispatch(moneyActions.getDepositList(params));
    }
  });

  socket.on('withdrawRequest', (data) => {
    toastr.info(data.message)
    if (history.location.pathname === "/withdraw") {
      const params = {
        page: 1,
        perPage: store.getState().page.perPage,
        starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
        endtime: dateFormation(Date.now(), 3)
      }
      store.dispatch(moneyActions.getWithdrawList(params));
    }
  });

  socket.on('questionRequest', (data) => {
    toastr.info(data.message)
    if (history.location.pathname === "/customerCenter") {
      const params = {
        page: 1,
        perPage: store.getState().page.perPage,
        starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
        endtime: dateFormation(Date.now(), 3)
      }
      store.dispatch(siteActions.getCustomerList(params));
    }
  });

  socket.on('newUserRequest', (data) => {
    toastr.info(data.message)
    if (history.location.pathname === "/newuser") {
      const params = {
        page: 1,
        perPage: store.getState().page.perPage,
        starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
        endtime: dateFormation(Date.now(), 3)
      }
      store.dispatch(usersActions.getNewUserList(params));
    }
  });

  socket.on('RealTimeBettingRequest', (data) => {
    // toastr.info(data.message)
    if (history.location.pathname === "/bettingHistory") {
      if(store.getState().page.curPage === 1){
        const params = {
          page: 1,
          perPage: store.getState().page.perPage,
          starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
          endtime: dateFormation(Date.now(), 3)
        }
        store.dispatch(gameActions.getSlotBettingHistory(params));
      }
    }
  });
}