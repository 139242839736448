import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux"
import { dateFormation, isEmpty } from '../../utils';
import 'rsuite/dist/datepicker.css';
import { api_url } from "../../utils/keys";
import UserCalculateNode from "./UserCalculateNode";
import { request } from "../../api";
import Search from "../../component/common/search/Search";
import Thead from "../../component/common/table/Thead";
import TableEmpty from "../../component/common/table/TableEmpty";

const theads = [
  {
    name: "아이디",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap", width: "200px" }
  },
  {
    name: "닉네임",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "회원등급",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "입금금액",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "출금금액",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "보유금액",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "보유포인트",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "베팅금",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "당첨금",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
  {
    name: "수익",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: "nowrap" }
  },
]

const UserCalculate = () => {

  const { loginid } = useSelector((state) => state.user.data);
  // const { res } = useSelector((state) => state.api);
  const [bonsas, setBonsas] = useState([]);
  const [activeAgent, setActiveAgent] = useState({});


  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });

  // -------------------- 시간 선택 -------------------- //
  const [dateInput, setDateInput] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  })

  // ------------------- 시간 선택 끝 ------------------ //

  // const dispatch = useDispatch();
  const getChildren = (loginid, isSearch, search = dateInput) => {
    const params = {
      loginid: loginid,
      isSearch: isSearch,
      ...search
    }
    return new Promise(resolve => {
      request.post(api_url + "/api/calc/calc_user/userlist", params)
        .then(res => {
          resolve(res.data);
        })
    });
  }

  const getBonsas = (page = 1, perPage = 10, search = dateInput, sortParams = {}) => {
    getChildren(loginid, true, search)
      .then(myChildren => {
        setBonsas(myChildren);
        setActiveAgent(myChildren[0])
      });
  }

  useEffect(() => {
    if (!isEmpty(loginid)) {
      getChildren(loginid, true)
        .then(myChildren => {
          setBonsas(myChildren);
          setActiveAgent(myChildren[0])
        });
    }
  }, [loginid]);

  // ------------------- Sort Start ----------------------- //
  const [sort, setSort] = useState({
    loginid: 0,
    username: 0,
  })

  const onSort = (value) => {

  }
  // ------------------- Sort End ----------------------- //


  return (
    <Fragment>

      <Search
        pageInfo={pageInfo}
        dateInput={dateInput}
        setDateInput={setDateInput}
        getSearch={getBonsas} />
      <div className="col-12">
        <div className="card padding-top-15 padding-bottom-15">
          <div className="card-body">
            {/* <h4 style={{ marginLeft: "25px" }} className="card-title">유저별정산</h4> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                <div className="row">
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                    <table className="money-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                      role="grid" aria-describedby="DataTables_Table_0_info">
                      <Thead theads={theads} sort={sort} onSort={onSort} />
                      <tbody>
                        {
                          // type === CurrentApiName &&
                          !isEmpty(bonsas) && bonsas.map((user, index) => {
                            return (
                              <UserCalculateNode
                                data={user}
                                level={1}
                                activeAgent={activeAgent}
                                setActiveAgent={setActiveAgent}
                                getChildren={getChildren}
                                key={user.created_at + index} />
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(bonsas) ? ""
                        :
                        <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>
                {/* <Pagenation
                        pageInfo={pageInfo}
                        setPageInfo={setPageInfo} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment >
  );
};

export default UserCalculate;
