import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { request } from "../../../api";
import { usersActions } from "../../../store/users.slice";
import { dateFormation, getLocalstringNumber, getStatus, getStatusColorClassName, isEmpty, toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";
import Pagenation from "../../common/pagenation/pagenation";
import Search from "../../common/search/Search";
import TableTitle from "../../common/TableTitle";
import { agentActions, userActions } from "../../../store";
import DateTd from "../../common/table/DateTd";
import Thead from "../../common/table/Thead";
import TableEmpty from "../../common/table/TableEmpty";

const theads = [
  {
    name: "번호",
    value: "",
    sort: false,
    style: { textAlign: "center", whiteSpace: 'nowrap', width: "55px" }
  },
  {
    name: "아이디",
    value: "loginid",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "닉네임",
    value: "username",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "도메인 ",
    value: "domain",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "아이피",
    value: "ip",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "접속시간",
    value: "login_date",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
  {
    name: "탈퇴시간",
    value: "logout_date",
    sort: true,
    style: { textAlign: "center", whiteSpace: 'nowrap' }
  },
]

function LoginHistoryContent() {
  const dispatch = useDispatch();
  const loginHistory = useSelector(state => state.users.loginHistory);

  // -----------------------------------------------
  const [sortParam, setSortParam] = useState({});
  const [searchParam, setSearchParam] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  });
  // -----------------------------------------------

  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });

  const [active, setActive] = useState(-1);

  const [showModal, setShowModal] = useState({
    moneySet: false,
    pointSet: false,
    create: false,
    edit: false,
    changePwd: false,
    delete: false,
    message: false
  });

  // -------------------- 시간 선택 -------------------- //
  const [dateInput, setDateInput] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  })

  // ------------------- 시간 선택 끝 ------------------ //

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }

  const getList = (page = 1, perPage = 10, search = searchParam, sortParams = sortParam) => {
    setPageInfo({
      ...pageInfo,
      curPage: page,
      perPage: Number(perPage)
    });

    const params = {
      page: page,
      perPage: perPage,
      ...sortParams,
      ...search,
    }
    setSearchParam(search);
    setSortParam(sortParams);

    dispatch(usersActions.getLoginHistory(params));
    setActive(-1);
  }

  useEffect(() => {
    getList();
  }, []);

  // ------------------- Sort Start ----------------------- //
  const defaultSort = {
    loginid: 0,
    username: 0,
    domain: 0, 
    ip: 0,
    login_date: 0,
    logout_date: 0,
  }
  const [sort, setSort] = useState({
    loginid: 0,
    username: 0,
    domain: 0, 
    ip: 0,
    login_date: 0,
    logout_date: 0,
  });

  const onSort = (value) => {
    if (!isEmpty(value)) {
      const sortParams = {
        sortBy: value,
        sortOrder: (sort[value] === 0) ? "asc"
          :
          ((sort[value] === 1) ? "desc" : "asc")
      }
      getList(1, pageInfo.perPage, searchParam, sortParams);
      setSort({
        ...defaultSort,
        [value]: (sort[value] === 0) ? 1
          :
          ((sort[value] === 1) ? -1 : 1)
      });
    }
  }
  // ------------------- Sort End ----------------------- //

  return (
    <Fragment>
      <Search
        pageInfo={pageInfo}
        dateInput={dateInput}
        setDateInput={setDateInput}
        getSearch={getList} />

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* <TableTitle title="회원목록" /> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                <div className="row justify-content-end padding-bottom-15">
                  
                </div>
                <div className="row">
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                    <table className="user-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                      role="grid" aria-describedby="DataTables_Table_0_info">
                      <Thead theads={theads} sort={sort} onSort={onSort} />
                      <tbody>
                        {
                          loginHistory.map((item, index) => {
                            return (
                              <tr role="row" className="odd" key={index} onClick={() => setActive(index)}>

                                <td className="sorting_1" style={{ textAlign: "center" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                <td style={{ textAlign: "center" }}>{item.loginid}</td>
                                <td style={{ textAlign: "center" }}>{item.username}</td>
                                <td style={{ textAlign: "center" }}>{item.domain}</td>
                                <td style={{ textAlign: "center" }}>{item.ip}</td>
                                <DateTd date={item.login_date} />
                                <DateTd date={item.logout_date} />
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(loginHistory) ? ""
                        :
                        <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>
                <Pagenation
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo}
                  getList={getList} />

              </div>
            </div>
          </div>
        </div>
      </div>


    </Fragment>
  );
}

export default LoginHistoryContent;
