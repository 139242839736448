import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { pageActions } from "../../../store";
import { isEmpty } from "../../../utils";

const Pagenation = (props) => {
    const dispatch = useDispatch();
    const totalPage = useSelector(state => state.page.totalPage);
    
    const { pageInfo, setPageInfo, getList } = props;
    
    // useEffect(() => {
    //     const params = {
    //         ...pageInfo,
    //         totalPage: totalPage
    //     }
    //     setPageInfo(params);
    //     console.log('totalPage: ', totalPage, params);
    // }, [totalPage]);

    useEffect(() => {
        dispatch(pageActions.setPerPage(pageInfo.perPage));
    }, [pageInfo.perPage]);

    const pageIndexCount = 5;

    let leftLimit = Number(pageInfo.curPage) - Math.floor(pageIndexCount / 2);
    let rightLimit = Number(pageInfo.curPage) + Math.floor(pageIndexCount / 2);
    if (leftLimit < 1) {
        rightLimit = rightLimit - leftLimit + 1;
        leftLimit = 1;
    }
    if (rightLimit > totalPage) {
        leftLimit -= (rightLimit - totalPage + 1);
        rightLimit = totalPage;
    }
    if (leftLimit < 1) leftLimit = 1;
    let pageIndexes = [];
    for (let i = leftLimit; i <= rightLimit; i++) pageIndexes.push(i);

    const onChange = (e) => {
        const { name, value } = e.target;
        getList(1, value);
        dispatch(pageActions.setCurPage(1));
    }


    const onGetPage = (page) => {
        if ((0 < page) && (page <= totalPage)) {
            getList(page, pageInfo.perPage);
            dispatch(pageActions.setCurPage(page));
        }
    }


    return (
        <div data-v-07abdf0e="" className="vgt-wrap__footer vgt-clearfix d-flex padding-top-15 justify-content-between">
            <div data-v-07abdf0e="" className="footer__row-count vgt-pull-left p-0">
                <select className="custom-select mr-sm-2" id="inlineFormCustomSelect"
                    name="perPage"
                    value={Number(pageInfo.perPage)}
                    onChange={onChange}>
                    <option data-v-07abdf0e="" value={10}> 10 </option>
                    <option data-v-07abdf0e="" value={50}> 50 </option>
                    <option data-v-07abdf0e="" value={100}>100 </option>
                    <option data-v-07abdf0e="" value={150}> 150 </option>
                    <option data-v-07abdf0e="" value={200}> 200 </option>
                    <option data-v-07abdf0e="" value={500}> 500 </option>
                    <option data-v-07abdf0e="" value={1000}>1,000</option>
                </select>
            </div>
            <div className="bootstrap-pagination col-sm-9 d-flex justify-end p-0">
                <nav>
                    <ul className="pagination">
                        <li className="page-item" onClick={() => onGetPage(1)}>
                            <a className="page-link" aria-label="Previous">
                                <span aria-hidden="true">시작</span>
                                <span className="sr-only">Start</span>
                            </a>
                        </li>
                        <li className="page-item" onClick={() => onGetPage(Number(pageInfo.curPage) - 1)}>
                            <a className="page-link Previous" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        {
                            pageIndexes.map((list, index) => {
                                return (
                                    <li className={`page-item  ${list === pageInfo.curPage && "active"}`} key={index}>
                                        <a className={`page-link`} onClick={() => onGetPage(Number(list))}>{list}</a>
                                    </li>
                                )
                            })
                        }
                        <li className="page-item">
                            <a className="page-link Next" aria-label="Next" onClick={() => onGetPage(Number(pageInfo.curPage) + 1)}>
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" aria-label="Next" onClick={() => onGetPage(Number(totalPage))}>
                                <span aria-hidden="true">마감</span>
                                <span className="sr-only">End</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Pagenation;