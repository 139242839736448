
function Thead(props) {
  const { theads, sort, onSort } = props;

  return (
    <thead>
      <tr role="row">
        {
          theads.map((head, index) => {
            return (
              <th
                style={head.style}
                className={`my-th-w cursor-pointer ${head.sort ?
                  (sort[head.value] === 0 ?
                    "sorting" :
                    (sort[head.value] === 1 ? "sorting_asc" : "sorting_desc")
                  )
                  :
                  ""}`}
                tabIndex="0"
                rowSpan="1"
                colSpan="1"
                key={index}
                onClick={() => onSort(head.value)}
              >{head.name}</th>
            )
          })
        }
      </tr>
    </thead>
  );
}

export default Thead;
