import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { moneyActions } from "../../store";
import { dateFormation, getLocalstringNumber, getMoneyModalType, getMoneyModalUrl, isEmpty } from "../../utils";
import MessageModal from "../common/modal/MessageModal";
import MoneyAccept from "../common/modal/MoneyAccept";

import Pagenation from "../common/pagenation/pagenation";
import Search from "../common/search/Search";
import DateTd from "../common/table/DateTd";
import TableEmpty from "../common/table/TableEmpty";
import Thead from "../common/table/Thead";
// import TableTitle from "../common/TableTitle";

function MoneyContent(props) {
  const { type } = props;

  const theads = [
    {
      name: "번호",
      value: "",
      sort: false,
      style: { textAlign: "center", whiteSpace: 'nowrap', width: "55px" },
    },
    {
      name: "아이디",
      value: "loginid",
      sort: true,
      style: { textAlign: "center", whiteSpace: 'nowrap' },
    },
    {
      name: "닉네임",
      value: "username",
      sort: true,
      style: { textAlign: "center", whiteSpace: 'nowrap' },
    },
    {
      name: "상위업체",
      value: "parent_name",
      sort: true,
      style: { textAlign: "center", whiteSpace: 'nowrap' },
    },
    {
      name: "예금주",
      value: "mastername",
      sort: true,
      style: { textAlign: "center", whiteSpace: 'nowrap' },
    },
    {
      name: "은행명",
      value: "bankname",
      sort: true,
      style: { textAlign: "center", whiteSpace: 'nowrap' },
    },
    {
      name: "계좌번호",
      value: "accountnumber",
      sort: true,
      style: { textAlign: "center", whiteSpace: 'nowrap' },
    },
    {
      name: type + "금액",
      value: "amount",
      sort: true,
      style: { textAlign: "center", whiteSpace: 'nowrap' },
    },
    {
      name: "신청일자",
      value: "created_at",
      sort: true,
      style: { textAlign: "center", whiteSpace: 'nowrap' },
    },
    {
      name: "처리일자",
      value: "updated_at",
      sort: true,
      style: { textAlign: "center", whiteSpace: 'nowrap' },
    },
    {
      name: "관리",
      value: "",
      sort: false,
      style: { textAlign: "center", whiteSpace: 'nowrap', minWidth: "150px" },
    },
    {
      name: "쪽지",
      value: "",
      sort: false,
      style: { textAlign: "center", whiteSpace: 'nowrap' },
    },
  ]

  const dispatch = useDispatch();
  const depositList = useSelector(state => state.money.depositList);
  const withdrawList = useSelector(state => state.money.withdrawList);
  const totalDeposit = useSelector(state => state.money.totalDeposit);
  const totalWithdraw = useSelector(state => state.money.totalWithdraw);
  const moneyHistory = (type === "충전") ? depositList : withdrawList;
  const totalMoney = (type === "충전") ? totalDeposit : totalWithdraw;

  const [active, setActive] = useState(-1);

  // -----------------------------------------------
  const [sortParam, setSortParam] = useState({});
  const [searchParam, setSearchParam] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  });
  // -----------------------------------------------

  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });
  const onPageInfo = (param) => {
    setPageInfo(param)
  }

  const [showModal, setShowModal] = useState({
    accept: false,
    rejection: false,
    waiting: false,
    message: false,
  });

  const onShowModal = (key) => {
    setShowModal({
      ...showModal,
      [key]: !showModal[key]
    })
  }
  // -------------------- 시간 선택 -------------------- //
  const [dateInput, setDateInput] = useState({
    starttime: dateFormation((new Date()).setMonth((new Date()).getMonth() - 3), 3),
    endtime: dateFormation(Date.now(), 3)
  })

  // ------------------- 시간 선택 끝 ------------------ //
  const getList = (page = 1, perPage = 10, search = searchParam, sortParams = sortParam ) => {
    setPageInfo({
      ...pageInfo,
      curPage: page,
      perPage: Number(perPage)
    });

    const params = {
      page: page,
      perPage: perPage,
      ...sortParams,
      ...search
    }
    setSearchParam(search);
    setSortParam(sortParams);

    if (type === "충전") {
      dispatch(moneyActions.getDepositList(params));
    }
    else {
      dispatch(moneyActions.getWithdrawList(params));
    }
    setActive(-1);
  }

  useEffect(() => {
    getList();
  }, []);


  // ------------------- Sort Start ----------------------- //
  const defaultSort = {
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    amount: 0,
    rolling: 0,
    created_at: 0,
    updated_at: 0,
  }
  const [sort, setSort] = useState({
    loginid: 0,
    parent_name: 0,
    username: 0,
    partner_code: 0,
    rolling_percent: 0,
    agent_type: 0,
    bankname: 0,
    accountnumber: 0,
    mastername: 0,
    status: 0,
    money: 0,
    amount: 0,
    rolling: 0,
    created_at: 0,
    updated_at: 0,
  });

  const onSort = (value) => {
    if (!isEmpty(value)) {
      const sortParams = {
        sortBy: value,
        sortOrder: (sort[value] === 0) ? "asc"
          :
          ((sort[value] === 1) ? "desc" : "asc")
      }
      getList(1, pageInfo.perPage, searchParam, sortParams);
      setSort({
        ...defaultSort,
        [value]: (sort[value] === 0) ? 1
          :
          ((sort[value] === 1) ? -1 : 1)
      });
    }
  }
  // ------------------- Sort End ----------------------- //

  return (
    <Fragment>
      <Search
        pageInfo={pageInfo}
        dateInput={dateInput}
        setDateInput={setDateInput}
        getSearch={getList} />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            {/* <TableTitle title={type + "관리"} /> */}
            <div className="table-responsive overflow-unset">
              <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                <div className="row justify-content-end padding-bottom-15">
                  <div className="">
                  </div>
                </div>
                <div className="row">
                  <div className="margin-left-15 margin-bottom-15 total-info-buttons">
                    <span className="btn btn-info search-btn d-flex align-items-center border-radius-0 ">
                      총 {type === "충전" ? "입금" : "출금"}&ensp;<span className="" style={{ color: "#FDC60A" }}>{getLocalstringNumber(totalMoney)}</span>
                    </span>
                  </div>
                  <div className="margin-left-15 margin-right-15 padding-bottom-15 w-100 overflow-auto">
                    <table className="money-table table table-striped table-bordered zero-configuration dataTable" id="DataTables_Table_0"
                      role="grid" aria-describedby="DataTables_Table_0_info">
                      <Thead theads={theads} sort={sort} onSort={onSort} />
                      <tbody>
                        {
                          moneyHistory.map((history, index) => {
                            return (
                              <tr role="row" className="odd" key={index} onClick={() => setActive(index)}>
                                <td className="sorting_1" style={{ textAlign: "center" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                <td style={{ textAlign: "center" }}>{history.loginid}</td>
                                <td style={{ textAlign: "center" }}>{history.username}</td>
                                <td style={{ textAlign: "center" }}>{history.parent_name}</td>
                                <td style={{ textAlign: "center" }}>{history.mastername}</td>
                                <td className="white-space-nowrap" style={{ textAlign: "center" }}>{history.bankname}</td>
                                <td style={{ textAlign: "center" }}>{history.accountnumber}</td>
                                <td style={{ textAlign: "end" }} className={type === "충전" ? "color-twitter" : "color-youtube"}>{isNaN(Number(history.amount)) ? 0 : Number(history.amount).toLocaleString()}</td>
                                <DateTd date={history.created_at} />
                                <DateTd date={history.updated_at} />
                                <td style={{ textAlign: "center" }}>
                                  {
                                    Number(history.status) !== 0 ?
                                      <span className={`${Number(history.status) === 1 ? "color-twitter" : "color-youtube"} font-weight-bold`}>
                                        {Number(history.status) === 1 ? '승인' : '거절'}
                                      </span>
                                      :
                                      <span>
                                        <button
                                          className="btn btn-info btn-sm mr-1"
                                          style={{ color: "#fff" }}
                                          title="승인"
                                          onClick={() => onShowModal("accept")}>
                                          <i className="fa   fa-check-square-o"></i>
                                        </button>
                                        {
                                          (history.alarm === 1) && <button
                                            className="btn btn-success btn-sm mr-1"
                                            style={{ color: "#fff" }}
                                            title="대기"
                                            onClick={() => onShowModal("waiting")}>
                                            <i className="fa fa-bell-slash-o"></i>
                                          </button>
                                        }
                                        <button
                                          className="btn btn-danger btn-sm mr-1"
                                          style={{ color: "#fff" }}
                                          title="거절"
                                          onClick={() => onShowModal("rejection")}>
                                          <i className="fa fa-ban"></i>
                                        </button>
                                      </span>
                                  }
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <button
                                    className="btn btn-primary btn-sm mr-1"
                                    style={{ color: "#fff" }}
                                    title="쪽지보내기"
                                    onClick={() => onShowModal("message")}>
                                    <i className=" fa fa-send"></i>
                                  </button>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    {
                      !isEmpty(moneyHistory) ? ""
                        :
                        <TableEmpty colSpan={theads.length} />
                    }
                  </div>
                </div>

                <Pagenation
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo}
                  onPageInfo={onPageInfo}
                  getList={getList} />


                {
                  (showModal.accept || showModal.rejection || showModal.waiting) &&
                  <MoneyAccept
                    pageInfo={pageInfo}
                    getList={getList}
                    data={moneyHistory[active]}
                    onClose={onShowModal}
                    type={getMoneyModalType(showModal)}
                    url={getMoneyModalUrl(showModal, type)}
                  />
                }

                {
                  showModal.message &&
                  <MessageModal
                    pageInfo={pageInfo}
                    getList={getList}
                    data={moneyHistory[active]}
                    onClose={onShowModal}
                    method="post"
                    url="/api/site/send_usermessage"
                    type="보내기"
                    sendId="user_id" />
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default MoneyContent;
