import React, { Fragment } from "react";
import AgentListContent from "../../../component/AgentMange/AgentList/AgentListContent";

const AgentList = () => {

  return (
    <Fragment>
      <AgentListContent />
    </Fragment >
  );
};

export default AgentList;
